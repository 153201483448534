<template>

    <v-content class="grey darken-4 fill-height py-4" app>
        <v-container fluid fill-height class="px-4 pt-0 ">
            <slot/>
        </v-container>
    </v-content>

</template>

<script>
  // import { fromInjected } from '@openzeppelin/network'; // fromConnection
  // const infuraProjectId = 'b7a9e147ffe74544bf96f393acccde03';

  // const contract = require("@truffle/contract");
  // import MainTokenJson from '@/contracts/MainToken.json'
  import { mapGetters, mapActions } from 'vuex' // mapActions
  export default {
    props: {
      source: String,
    },
    components: {

    },

    computed: {
      ...mapGetters({
        account: 'auth/account'
      })
    },
    data: () => ({
      search: ''
    }),
    methods: {
      ...mapActions({
        setAccount: 'auth/setAccount',
        updateBalance: 'token/updateBalance',
        initToken: 'token/init',
        initLottery: 'lottery/init',
        updateDepositorBalance: 'lottery/updateDepositorBalance',
        updateWithdrawalBalance: 'lottery/updateWithdrawalBalance',
      }),

      async  setAcc() {
        this.setAccount(this.search)
        await this.updateBalance(this.account)
        await this.initToken()
        await this.initLottery()
        await this.updateDepositorBalance(this.account)
        await this.updateWithdrawalBalance(this.account)
      }
      /*async  updateNetwork(networkId, networkName) {
        console.log('updateNetwork', networkId, networkName)
      },
      async updateAccounts(accounts) {
        console.log('updateAccounts', accounts)
        if (accounts.length) {
          this.account = accounts[0]
        }
      },
      async updateConnection(connected) {
        console.log('updateConnection', connected)
      },*/
    },
    created: async function() {

    }
  }
</script>

<style lang="scss">
    body {
        .v-list {
            .v-list-item--active {
                color: #637188;
            }
        }
    }
</style>
