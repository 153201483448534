// @vue/component
import LayoutDefault from '@/layouts/LayoutDefault.vue'
export default {
    name: 'Home',

    components: {
        LayoutDefault
    },

    mixins: [],

    props: {},

    data () {
        return {}
    },

    computed: {},

    watch: {},

    created () {},

    methods: {}
}
