<template>
    <layout-default :is-loaded="true">

        <v-dialog  light max-width="80%" width="350" v-model="dialogCode">
            <v-card class="px-4 px-md-7 py-7 py-12">
                <v-card-text class="px-0" style=" flex: 0 1 auto;">
                    <v-text-field
                            outlined
                            flat
                            label="Введите проверочный код"
                            name="code"
                            type="text"
                            v-model="user.code"
                    />
                    <div class="caption  mb-2">
                        Код отправлен на ваш Email
                    </div>
                </v-card-text>
                <v-card-actions class="px-0">
                    <v-spacer/>
                    <v-btn type="button" :disabled="!user.code" @click="signup" style="width: 100%;" large class="white--text" color="primary">
                        Отправить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-layout align-center justify-center fill-height>
            <v-flex class="" style="width: 640px; max-width: 100%; flex: 0 1 auto;">
                <!--<v-card tile class="elevation-12">-->
                <div class="">
                    <h2 class="text-center mb-2 primary--text" style="font-size: 28px; line-height: 1.35;">
                        <span class="font-weight-bold">Регистрация</span>
                    </h2>
                    <!--                    <v-img width="300" class="mx-auto mb-7" src="@/assets/logo.svg"></v-img>-->
                    <!--<v-toolbar
                            color="teal"
                            dark
                            flat
                    >
                        <v-toolbar-title class="text-left " style="width:100%;">Registration</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>-->
                    <v-form @submit.prevent="signup" class="marginForm" ref="registrationForm" :lazy-validation="true">
                        <v-card-text class="px-0 pb-0 " style="position: relative;">

                            <!-- <v-text-field
                                     label="First name"
                                     name="first_name"
                                     :rules="[rules.required]"
                                     prepend-icon="mdi-account"
                                     type="text"
                                     v-model="firstName"
                             />

                             <v-text-field
                                     label="Last name"
                                     name="last_name"
                                     :rules="[rules.required]"
                                     prepend-icon="mdi-account"
                                     type="text"
                                     v-model="lastName"
                             />-->

                            <v-row dense>
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                            outlined
                                            flat
                                            :dense="$vuetify.breakpoint.smAndDown"

                                            label="Фамилия *"
                                            name="last_name"
                                            :rules="[rules.required]"
                                            type="text"
                                            v-model="user.last_name"
                                    />
                                </v-col>
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                            outlined
                                            flat
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            label="Имя *"
                                            name="first_name"
                                            :rules="[rules.required]"
                                            type="text"
                                            v-model="user.first_name"
                                    />
                                </v-col>
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                            outlined
                                            flat
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            label="Отчество *"
                                            name="patronymic"
                                            :rules="[rules.required]"
                                            type="text"
                                            v-model="user.patronymic"
                                    />
                                </v-col>
                                <v-col sm="6" cols="12">
                                    <v-menu
                                            ref="menuDate"
                                            v-model="menuDate"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    flat
                                                    outlined
                                                    :dense="$vuetify.breakpoint.smAndDown"
                                                    :rules="[rules.required]"
                                                    v-model="user.birthday"
                                                    label="Дата рождения *"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker

                                                ref="pickerDate"
                                                v-model="user.birthday"
                                                :max="new Date().toISOString().substr(0, 10)"
                                                min="1920-01-01"
                                                @change="saveDate"
                                        ></v-date-picker>

                                    </v-menu>
                                </v-col>
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                            validate-on-blur
                                            outlined
                                            flat
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            v-cleave="{phone: true}"
                                            label="Телефон *"
                                            name="phone"
                                            :rules="[rules.required]"
                                            type="text"
                                            v-model="user.phone"
                                    />
                                </v-col>
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                            outlined
                                            flat
                                            validate-on-blur
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            label="Email *"
                                            name="login"
                                            :rules="[rules.required, rules.email]"
                                            type="text"
                                            v-model="user.email"
                                    />
                                </v-col>
                                <v-col sm="12" cols="12">
                                    <v-text-field
                                            outlined
                                            placeholder="https://vk.com/id123456790"
                                            flat
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            label="Страница в соц. сети"
                                            name="link"
                                            type="text"
                                            v-model="user.link"
                                    />
                                    <div class="caption mt-md-n2 mb-2 white--text">
                                        Ссылка на вашу персональную страницу в одной из социальных сетей
                                    </div>
                                </v-col>
                                <v-col sm="6" cols="12">
                                    <v-select
                                            outlined
                                            label="Страна *"
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            flat
                                            :items="getCountryList"
                                            v-model="country_id"
                                            :item-text="(item) => item.name"
                                            :item-value="(item) => item.id"
                                    >

                                    </v-select>
                                    <!--<v-text-field
                                            outlined
                                            flat
                                            solo
                                            label="Страна *"
                                            name="country_id"
                                            :rules="[rules.required]"
                                            type="text"
                                            v-model="user.country_id"
                                    />-->
                                </v-col>
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                            outlined
                                            flat
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            label="Город *"
                                            name="city"
                                            :rules="[rules.required]"
                                            type="text"
                                            v-model="user.city"
                                    />
                                </v-col>
                                <v-col sm="6" cols="12">
                                    <v-select
                                            outlined
                                            label="Пол *"
                                            flat
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            :items="[
                                              {value: 'male', text: 'Мужской'},
                                              {value: 'female', text: 'Женский'}
                                          ]"
                                            v-model="user.gender"
                                    >

                                    </v-select>
                                </v-col>
                                <v-col sm="6" cols="12">
                                    <v-select
                                            outlined
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            flat
                                            :rules="[rules.required]"
                                            v-model="user.role"
                                            :items="roles"
                                            label="Роль *"
                                    ></v-select>
                                </v-col>

                                <v-col v-if="user.role == 'profi' || user.role == 'profi_gallery'" sm="12" cols="12">
                                    <v-file-input
                                            accept=".jpg,.jpeg,.png,.gif"
                                            accept-later=".jpg,.jpeg,.doc,.docx,.pdf,.png,.xls,.xlsx,.gif"
                                            outlined
                                            :rules="[v => v && v.length || 'обязательное']"
                                            persistent-hint
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            clearable
                                            prepend-icon=""
                                            v-model="files"
                                            small-chips
                                            :show-size="1000"
                                            multiple
                                            label="Фотографии документов *"
                                    >
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip
                                                    v-if="index < 2"
                                                    color="deep-purple accent-4"
                                                    dark
                                                    label
                                                    :small="$vuetify.breakpoint.mdAndUp"
                                                    :x-small="$vuetify.breakpoint.smAndDown"
                                            >
                                                {{ text }}
                                            </v-chip>

                                            <span
                                                    v-else-if="index === 2"
                                                    class="body-2 grey--text mx-2"
                                            >
        + {{ files.length - 2 }} файл{{ files.length > 3 && files.length <= 6 ? 'а' : '' }}{{ files.length > 6 ? 'ов' : '' }}
      </span>
                                        </template>
                                    </v-file-input>

                                    <div class="caption mt-md-n2 mb-2 white--text">
                                        Для подтверждения профессионального статуса приложите фото документов (для студентов: студенческий билет, зачетная книжка или справка; диплом о высшем образовании по специальности художник; членский билет любого союза художников)
                                    </div>
                                </v-col>

                               <!-- <v-col sm="6" cols="12">
                                    <v-autocomplete
                                            outlined
                                            flat
                                            solo
                                            :rules="[v => !!v || 'Timezone is required']"
                                            v-model="user.timezone"
                                            :items="timezones"
                                            :item-text="(item) => item.name"
                                            :item-value="(item) => item.value"
                                            label="Timezone *"
                                    ></v-autocomplete>
                                </v-col>-->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                            flat
                                            outlined
                                            class="withIcon"
                                            validate-on-blur
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            v-model="user.password"
                                            :rules="[rules.required, rules.min]"
                                            :type="showPass ? 'text' : 'password'"
                                            id="password"
                                            label="Пароль *"
                                            name="password"
                                            :counter="false"

                                            hint="минимум 6 символов"
                                    >
                                        <template v-slot:append>
                                            <v-icon size="16" @click="showPass = !showPass">
                                                {{ showPass ? 'mdi-eye' : 'mdi-eye-off' }}
                                            </v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>

                                <v-col sm="6" cols="12">
                                    <v-text-field
                                            class="withIcon"
                                            flat
                                            :dense="$vuetify.breakpoint.smAndDown"
                                            validate-on-blur
                                            outlined
                                            v-model="passwordConfirm"
                                            :rules="[rules.required, rules.min, rules.passwordMatch]"
                                            :type="showPass2 ? 'text' : 'password'"
                                            id="password2"
                                            label="Пароль повторно *"
                                            name="password2"
                                            :counter="false"
                                            persistent-hint
                                            hint-old="подтвердите пароль"
                                    >
                                        <template v-slot:append>
                                            <v-icon size="16" @click="showPass2 = !showPass2">
                                                {{ showPass2 ? 'mdi-eye' : 'mdi-eye-off' }}
                                            </v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>

                            </v-row>


                            <!--<v-autocomplete
                                    outlined
                                    flat
                                    solo
                                    :rules="[v => !!v || 'Timezone is required']"
                                    v-model="timezone"
                                    :items="timezones"
                                    :item-text="(item) => item.name"
                                    :item-value="(item) => item.value"
                                    label="Timezone *"
                            ></v-autocomplete>
  -->




<div class="d-flex mt-2 mb-1 align-center">
    <v-checkbox hide-details class="mt-0 py-0 mb-0 " :rules="[rules.required]">

    </v-checkbox>
    <div class="caption my-0 white--text ml-2">
        Я прочитал и принимаю <br v-if="$vuetify.breakpoint.smAndDown">
        <a  download class="mx-0 mx-md-1 secondary--text" href="/files/Polzovatelskoe_soglashnie_pri_ispolzovanii_sai_774_ta_Starykh_Gallery.docx">Пользовательское соглашение</a>
        и <br v-if="$vuetify.breakpoint.smAndDown">
        <router-link class="mx-0 mx-md-1 secondary--text" to="/">Политику конфиденциальности</router-link>
    </div>
</div>


                        </v-card-text>


                        <v-alert
                                v-if="errorMessage"
                                dense
                                outlined
                                dismissible
                                type="error"
                                class="mx-0 pa-4"
                        >
                            {{ errorMessage }}
                        </v-alert>
                        <v-card-actions class="px-0">
                            <v-spacer/>
                            <v-btn type="submit" style="width: 100%;" large class="white--text" color="primary">
                                Зарегистрироваться
                            </v-btn>
                        </v-card-actions>

                        <div class="px-0 mt-4 pb-2 grey--text body-2 text-center">
                            Уже есть аккаунт?
                            <router-link class="secondary--text ml-1" to="/login">Войти</router-link>
                        </div>

                    </v-form>
                </div>
            </v-flex>
        </v-layout>
    </layout-default>
</template>

<style scoped>
    label {
        cursor: pointer;
    }
</style>

<script>
    // import {AUTH_REQUEST, AUTH_SUCCESS} from 'actions/auth'
    // import {USER_REQUEST} from 'actions/user'
    // import { ValidationProvider, ValidationObserver } from 'vee-validate'
    // import Cookies from 'js-cookie'
    import axios from 'axios'
    import Cleave from 'cleave.js'
    import { mapActions, mapGetters } from 'vuex'
    import LayoutDefault from '@/layouts/LayoutBase.vue'
    import jstz from 'jstz'

    export default {
        /*components: {
          ValidationProvider,
          ValidationObserver
        },*/
        components: {
            LayoutDefault
        },
        name: 'registration',
        directives: {
            cleave: {
                inserted: (el, binding) => {
                    el = el.querySelector('input')
                    el.cleave = new Cleave(el, binding.value || {})
                },
                update: (el) => {
                    el = el.querySelector('input')
                    const event = new Event('input', {bubbles: true})
                    setTimeout(function () {
                        el.value = el.cleave.properties.result
                        el.dispatchEvent(event)
                    }, 100)
                },
            }
        },
        data: function () {
            return {
                showPass: false,
                dialogCode: false,
                showPass2: false,
                menuDate: false,
                isSubmitButtonLoading: false,
                nickname: '',
                    roles: [
                        {value: 'user', text: 'Пользователь'},
                        {value: 'painter', text: 'Художник'},
                        {value: 'profi', text: 'Проф. художник'},
                        {value: 'profi_gallery', text: 'Художник Галереи Старых'},
                        {value: 'collector', text: 'Коллекционер'},
                    ],
                country_id: null,
                files: [],
                firstName: '',
                birthday: '',
                timezone: '',
                lastName: '',
                email: '',
                user: {},
                password: '',
                passwordConfirm: '',
                errorMessage: '',
                rememberMe: true,
                rules: {
                    required: v => !!v || 'обязательное',
                    min: v => v && v.length >= 6 || 'минимум 6 символов',
                    email: v => /.+@.+\..+/.test(v) || 'неверный E-mail',
                    passwordMatch: () => this.user.password === this.passwordConfirm || ('пароли не совпадают'),
                }
            }
        },
        computed: {
            ...mapGetters({
                getUserData: 'auth/getUserData',
                getCountryList: 'user/getCountryList',
                getErrorMessage: 'auth/getErrorMessage'
            }),
            timezones: function () {
                let keys = Object.keys(jstz.olson.timezones)
                let tzs = []
                for (let i = 0; i < keys.length; i++) {
                    let ch = keys[i].split(',')[0] / 60
                    ch = ch > 0 ? '+' + ch : ch
                    tzs.push({
                        value: jstz.olson.timezones[keys[i]],
                        name: jstz.olson.timezones[keys[i]] + ` (GMT ${ch})`,
                    })
                }
                return tzs
            }
        },
        methods: {
            ...mapActions({
                postRegistrationFormData: 'auth/postRegistrationFormData',
                fetchCountryList: 'user/fetchCountryList',
            }),
            saveDate (date) {
                this.$refs.menuDate.save(date)
            },
            signup: async function () {

                const isValid = this.$refs.registrationForm.validate()

                if (!isValid) {
                    return false
                }
                this.isSubmitButtonLoading = true
                try {
                    this.errorMessage = ''
                    // const registrationFieldNameInPayload = this.email.match(/[^@]+@[^\.]+\..+/g) ? 'email' : 'nickname'
                    // const registrationFieldNameInPayload = 'nickname'
                    /* const payload = {
                       email: this.email,
                       nick: this.nickname,
                       birthday: this.birthday,
                       timezone: this.timezone,
                       first_name: this.firstName,
                       last_name: this.lastName,
                       password: this.password
                     }*/
                    this.user.country_id = this.country_id


                    let formData = new FormData();
                    for (let file of this.files) {
                        formData.append("files[]", file, file.name);
                    }
                   /* if (this.file) {
                        // formData.append('files[]', this.file.blob, this.file.name + '.' + this.file.type);
                        formData.append('files[]', this.file);
                    }*/

                    for(let dataKey in this.user) {
                        formData.append(dataKey, this.user[dataKey]);
                    }

                    let r

                    try {
                        r = await this.postRegistrationFormData(formData)
                    } catch(e) {
                        alert ('post err: ' + e.message )
                    }

                    console.log('r', r)

                    if (this.getErrorMessage) {
                        this.errorMessage = this.getErrorMessage
                        return false
                    }

                    if (!r.data.data.is_verified) {
                        this.dialogCode = true
                        return false
                    }

                    if (this.getUserData.is_verified) {
                        this.$router.push('/profile')
                    }


                } catch (err) {
                    //console.log('onFormSubmit err', err)
                    this.formErrorMessagesFromApi = err.response.data.error
                }
                this.isSubmitButtonLoading = false
            }
        },
        mounted: async function () {
            await this.fetchCountryList()
            this.country_id = 1
            const timezone = jstz.determine()
            this.user.timezone = timezone.name()
            /*console.log(jstz.olson.timezones);
            console.log(timezone.name());*/
            /*this.$store.dispatch(AUTH_REQUEST, {rememberMe: true}).then(() => {
              this.$store.dispatch(USER_REQUEST).then(() => {
                return this.$router.push('/')
              })
            })*/
        },
        watch: {

            menuDate (val) {
                val && setTimeout(() => (this.$refs.pickerDate.activePicker = 'YEAR'))
            }
        },
    }
</script>

<style>





    /*.v-picker__body {
        width: 500px !important;
        max-width: 100% !important;
    }

    .v-card.v-picker--date{
        width: 100%;
    }*/

    .menuable__content__active.v-autocomplete__content {
        background: #33333d;
    }

</style>
