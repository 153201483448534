import { dnet, dashboard } from '@/config/api'
import ApiService from '@/services/api.service'

const state = () => ({
    dnetList: [],
    specialtiesList: [],
    dnetTotal: 0,
    stats: false,
    chartData: [],
    dnetLimitOnPage: 10,
    genders: [
        {
            value: 'male', text: 'Male'
        },
        {
            value: 'female', text: 'Female'
        },
        {
            value: 'other', text: 'Other'
        }
    ],
    content: null,
    schedule: null,
    errors: []

})

const getters = {
    getDnetList: state => state.dnetList,
    getGenders: state => state.genders,
    getStats: state => state.stats,
    getChartData: state => state.chartData,
    getDnetTotal: state => state.dnetTotal,
    getSpecialtiesList: state => state.specialtiesList,
    getDnetLimitOnPage: state => state.dnetLimitOnPage,
    getDnetContent: state => state.content,
    getProfile: state => state.content,
    getSchedule: state => state.schedule,
    getErrors: state => state.errors
}

const actions = {

    async fetchDnetList ({ commit, getters }, params) {
        const formattedParams = {
            ...params,
            limit: params.limit || getters.getDnetLimitOnPage,
            offset: (getters.getDnetLimitOnPage * (parseInt(params.page) || 1)) - getters.getDnetLimitOnPage
        }
        try {
            // commit('START_LOAD_DNET_LIST')
            const response = await ApiService.get(dnet.list, {
                params: {
                    language: 'en',
                    ...formattedParams
                },
                useCache: true
            })

            commit('SET_CONTENT_LIST', {
                payload: response.data.data,
                headers: response.headers
            })
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    async fetchSpecialtiesList ({ commit, getters }) {
        try {
            const response = await ApiService.get(dnet.specialties, {
                params: {
                    language: 'en'
                }
            })
            commit('SET_SPECIALTIES_LIST', {
                payload: response.data.data,
                headers: response.headers
            })
        } catch (err) {
            throw err
        }
    },

    async fetchStats ({ commit }, params) {
        try {
            commit('SET_STATS', false)

            const { data: { data } } = await ApiService.get(dashboard.stats, {
                params: params
            })
            commit('SET_STATS', data)
            return data
        } catch (err) {
            console.error(err)
        }
    },

    async fetchChartData ({ commit }, params) {
        try {
            commit('SET_CHART_DATA', [])
            const { data: { datasets } } = await ApiService.get(dashboard.chartData, {
                params: params
            })
            commit('SET_CHART_DATA', datasets)
        } catch (err) {
            console.error(err)
        }
    },


    async fetchDnetContent ({ commit }, alias) {
        try {
            const { data: { data } } = await ApiService.get(dnet.content(alias), {
                params: {
                    language: 'en'
                }
            })
            commit('SET_CONTENT', data)
            return data
        } catch (err) {
            throw err
        }
    },

    async checkRegCode ({ commit }, invite) {
        try {
            const response = await ApiService.post(dnet.invite, {
                invite: invite
            })

            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response.data.data
            }

        } catch (err) {
            commit('ADD_ERROR', err.message)
        }
    },

    async fetchProfile ({ commit }, id) {
        try {
            const response = await ApiService.post(dnet.profile, {
                id: id
            })

            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                commit('SET_CONTENT', response.data.data)
                return response.data.data
            }

        } catch (err) {
            commit('ADD_ERROR', err.message)
        }
    },

    async checkPatientCode ({ commit }, code) {
        try {
            const response = await ApiService.post(dnet.patientCode, {
                code: code
            })

            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response.data.data
            }

        } catch (err) {
            commit('ADD_ERROR', err.message)
        }
    },

    async checkSession ({ commit }, check) {
        try {
            const response = await ApiService.post(dnet.session, {
                check: check
            })

            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response.data.data
            }

        } catch (err) {
            commit('ADD_ERROR', err.message)
        }
    },

    async finishSession ({ commit }) {
        try {
            const response = await ApiService.post(dnet.finishSession, {

            })

            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response.data.data
            }

        } catch (err) {
            commit('ADD_ERROR', err.message)
        }
    },

    async fetchSchedule ({ commit }, alias) {
        try {
            const { data: { data } } = await ApiService.get(alias ? dnet.dnetSchedule(alias) : dnet.schedule, {
                params: {
                    language: 'en'
                }
            })
            commit('SET_SCHEDULE', data)
            return data
        } catch (err) {
            throw err
        }
    },



    async saveDnet ({commit}, data) {
        try {
            const response = await ApiService.post(dnet.save, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async saveSchedule ({commit}, data) {
        try {
            const response = await ApiService.post(dnet.saveSchedule, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async deleteDnet ({commit}, dnetId) {
        try {
            const response = await ApiService.post(dnet.delete, {dnet_id: dnetId})
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async clearErrors ({commit}) {
        commit('CLEAR_ERRORS')
    }
}

const mutations = {
    CLEAR_ERRORS (state) {
        state.errors = []
    },

    ADD_ERROR (state, payload) {
        state.errors.push(payload)
    },

    START_LOAD_DNET_LIST(state) {
        state.dnetTotal = 0
        state.dnetList = []
    },

    SET_SPECIALTIES_LIST (state, { payload, headers }) {
        state.specialtiesList = payload
    },

    SET_CHART_DATA(state, payload) {
        state.chartData = payload
    },

    SET_STATS(state, payload) {
        state.stats = payload
    },

    SET_CONTENT_LIST (state, { payload, headers }) {
        state.dnetTotal = parseInt(headers['x-items-total'])
        state.dnetList = payload

    },

    SET_CONTENT (state, payload) {
        state.content = payload
    },
    SET_SCHEDULE (state, payload) {
        state.schedule = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
