import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        current: 'ru',
    },

theme: {
    dark: true,

    themes: {
        light: {
            primary: '#d9968e', // #E53935
            secondary: '#333333', // #E53935
        },
        dark: {
            primary: '#d9968e', // #E53935
            //primary: '#2196F3', // #E53935
            secondary: '#ffffff', // #E53935
        },
    },
},

});
