import apiConfig from '../static/apiConfig'

export const auth = {
    login: `${apiConfig.url}/auth/login?app=admin`,
    logout: `${apiConfig.url}/auth/logout`,
    reset: `${apiConfig.url}/user/reset`,
    registration: `${apiConfig.url}/auth/register`,
    unread: `${apiConfig.url}/unread`,
    profile: `${apiConfig.url}/user/profile`,
    saveProfile: `${apiConfig.url}/user/profile/save`,
    registrationCheck: `${apiConfig.url}/auth/register/activation`,
    resetPassword: `${apiConfig.url}/auth/password/forgot`,
    resetPasswordCheck: `${apiConfig.url}/auth/password/restore`,
    regenerateTokens: `${apiConfig.url}/auth/refresh`,
    passwordChange: `${apiConfig.url}/auth/password/change`
}

export const dnet = {
    list: `${apiConfig.url}/dnet`,
    save: `${apiConfig.url}/dnt/save`,
    'delete': `${apiConfig.url}/dnt/delete`,
    specialties: `${apiConfig.url}/specialties`,
    invite: `${apiConfig.url}/dnt/invite`,
    profile: `${apiConfig.url}/dnt/profile`,
    session: `${apiConfig.url}/session/open`,
    finishSession: `${apiConfig.url}/session/close`,
    patientCode: `${apiConfig.url}/dnt/patient`,
    //content: alias => `${apiConfig.url}/dnt/${alias}`,
    dnetSchedule: alias => `${apiConfig.url}/dnt/schedule/${alias}`,
    schedule: `${apiConfig.url}/dnt/schedule`,
}

export const user = {
    list: `${apiConfig.url}/users`,
    doctorList: alias => `${apiConfig.url}/users/${alias}`,
    itemsList: alias => `${apiConfig.url}/user/items/${alias}`,
    citiesByCountry: alias => `${apiConfig.url}/cities/by-country/${alias}`,
    services: `${apiConfig.url}/services`,
    countries: `${apiConfig.url}/countries`,
    permissions: `${apiConfig.url}/permissions`,
    all: `${apiConfig.url}/all`,
    save: `${apiConfig.url}/user/save`,
    vote: `${apiConfig.url}/vote`,
    fav: `${apiConfig.url}/fav`,
    unfav: `${apiConfig.url}/fav`,
    saveVitalSigns: `${apiConfig.url}/vital_signs/save`,
    savePermissionRequest: `${apiConfig.url}/permission_request/save`,
    'delete': `${apiConfig.url}/user/delete`,
    deleteFile: `${apiConfig.url}/file/delete`,
    deletePermissionRequest: `${apiConfig.url}/permission_request/delete`,
    updateFile: `${apiConfig.url}/file/update`,
    content: alias => `${apiConfig.url}/user/${alias}`,
    deleteAvatar: alias => `${apiConfig.url}/user/avatar/delete/${alias}`,
    file: alias => `${apiConfig.url}/file/${alias}/view`
}

export const chats = {
    list: `${apiConfig.url}/chats`,
    save: `${apiConfig.url}/chat/save`,
    accept: `${apiConfig.url}/chat/accept`,
    close: `${apiConfig.url}/chat/close`,
    moderate: `${apiConfig.url}/chat/moderate`,
    topics: `${apiConfig.url}/topics`,
    refuse: `${apiConfig.url}/chat/refuse`,
    'delete': `${apiConfig.url}/chat/delete`,
    messages: alias => `${apiConfig.url}/chat/messages/${alias}`,
    sendMessage: `${apiConfig.url}/chat/message`,
    lastSeen: alias => `${apiConfig.url}/last-seen/${alias}`,
    deleteMessage: `${apiConfig.url}/message/delete`,
}

export const contests = {
    list: `${apiConfig.url}/contests`,
    participate: `${apiConfig.url}/contest/participate`,
    save: `${apiConfig.url}/contest/save`,
    content:  alias => `${apiConfig.url}/contests/${alias}`,
}

export const shop = {
    list: `${apiConfig.url}/shop`,
    createOrder: `${apiConfig.url}/order/create`,
    deleteOrder: `${apiConfig.url}/order/delete`,
    getOrder:  alias => `${apiConfig.url}/orders/${alias}`,
}

export const cart = {
    list: `${apiConfig.url}/cart`,
}

export const teams = {
    list: `${apiConfig.url}/teams`,
    accept: `${apiConfig.url}/team/accept`,
    save: `${apiConfig.url}/team/save`,
    'delete': `${apiConfig.url}/team/delete`,
    content:  alias => `${apiConfig.url}/teams/${alias}`,
}


export const articles = {
    list: `${apiConfig.url}/articles`,
    accept: `${apiConfig.url}/article/accept`,
    save: `${apiConfig.url}/article/save`,
    content:  alias => `${apiConfig.url}/articles/${alias}`,
}

export const exhibitions = {
    list: `${apiConfig.url}/exhibitions`,
    accept: `${apiConfig.url}/exhibition/accept`,
    save: `${apiConfig.url}/exhibition/save`,
    'delete': `${apiConfig.url}/exhibition/delete`,
    doTask: `${apiConfig.url}/task/do`,
    nextDay: `${apiConfig.url}/day/next`,
    dates: exhibitionId => `${apiConfig.url}/dates/exhibitions/${exhibitionId}`,
    content:  alias => `${apiConfig.url}/exhibitions/${alias}`,
}

export const dashboard = {
    stats: `${apiConfig.url}/dnt/stats`,
    chartData: `${apiConfig.url}/dnt/chart-data`,
}
