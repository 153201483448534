<template>
    <div style="width: 100%;" v-if="getUserProfile">
        <div class="mt-4 mb-0 " v-if="!hideBack">
            <v-btn
                    class="ml-3 mb-1"
                    text
                    @click="$emit('back')"

                    icon
            >
                <v-icon  color="grey">mdi-arrow-left</v-icon>
            </v-btn> <span v-if="exhibition" style="cursor: pointer" @click="$emit('back')">{{ exhibition.name }}</span>
        </div>
        <div v-if="exhibition && !hideBack" class="grey--text pl-14 pr-7 font-italic">
            {{ exhibition.description }}
        </div>
        <div :class="{'mt-12': !hideBack}" class="px-5 d-flex justify-center flex-wrap text-center align-center" v-if="exhibition">
            <v-avatar size="44" style="cursor: pointer;" @click="currentDay = day" class="mx-2 mb-4 white--text" :color="currentDay == day ? 'primary' : 'grey'" :key="'day-' + day" v-for="day in exhibition.duration">{{ day }}</v-avatar>
        </div>
        <div :class="{'mt-12': !hideBack}" class="px-5 d-flex flex-wrap justify-center text-center align-center" v-if="accepted && !exhibition">
            <v-avatar @click.stop="openCalendar" size="44" style="cursor: pointer" :class="getColorClass" light class="mx-2 mb-4 borders-all" :key="'day-' + day" >{{ day }}</v-avatar>
            <!--<v-avatar size="44"  class="mx-2 mb-4 white&#45;&#45;text" :color="currentDayReal == day ? 'primary' : 'grey'" :key="'day-' + day" v-for="day in maxDays">{{ day }}</v-avatar>-->
        </div>
        <v-list two-line class="tasksList mt-1" width="100%">
            <v-list-item-group
                    v-if="group"
                    v-model="currentTaskId"
                    color="indigo"
            >
                <template v-for="(task, i) in filteredTasks">
                    <c-list-item  :key="'task-' + task.id" :task="task">
                        <template v-if="accepted && getColorClass=='empty'">
                            <v-icon title="mark done" @click.stop="doTaskConfirm(task.id)"
                                    v-if="!task.done"
                                    class="hoverable-icon">mdi-check-circle-outline
                            </v-icon>
                            <v-icon title="done"
                                    v-if="task.done"
                                    color="primary">mdi-check-circle
                            </v-icon>
                        </template>
                    </c-list-item>
                    <v-divider class="qDivider" v-if="filteredTasks.length - 1 != i"
                               :key="'dt-' + task.id"></v-divider>
                </template>

            </v-list-item-group>
            <template v-else>
                <slot />
            </template>
        </v-list>
        <div class="px-7 text-center" v-if="!filteredTasks || !filteredTasks.length">
            No Tasks found
        </div>


        <v-dialog :fullscreen="isMobile()" :max-width="isMobile() ? '100%' : 593" v-model="dialogCalendar" content-class="white dialogPdf">
            <v-system-bar color="white">
                <v-spacer></v-spacer>
                <v-btn class="mt-8 mr-1" icon dark @click.stop="dialogCalendar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <div class="mt-8">
                <!--<vc-calendar v-if="dialogCalendar" title-position="left"  :disable-page-swipe="false"  :available-dates="availableDates" :first-day-of-week="2" :attributes="attributes" @dayclick="selectDay" v-model="filterDate" color="grey" :rows="1" is-expanded :cols="1" :locale="$i18n.locale" />-->


                <v-date-picker
                        color="primary"
                        light
                        flat
                        class="fullHeight"
                        full-width
                        no-title
                        @change="selectDay2"
                        v-if="dialogCalendar"
                        :first-day-of-week="1"
                        v-model="filterDate"
                        :events="formatDay"
                        event-color="blue"
                        :allowed-dates="val => this.getDates.dates.indexOf(val) !== -1"
                ></v-date-picker>

            </div>
        </v-dialog>

    </div>

</template>

<script>
    import CListItem from '@/components/common/tasks/c-list-item.vue'
    import { mapActions, mapGetters } from 'vuex'
    import cssMixin from '@/mixins/css'

    export default {
        name: 'q-list',
        components: {
            CListItem
        },
        mixins: [cssMixin],
        props: {
            group: {
                type: Boolean,
                default: true,
            },
            exhibition: {
                type: Object,
                default: {},
            },
            id: {
                default: false,
            },
            active: {
                type: Boolean,
                default: false,
            },
            accepted: {
                type: Boolean,
                default: false,
            },
            hideBack: {
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {
                currentDay: 1,
                day: 0,
                filterExhibitionDay: '',
                filterDate: false,
                availableDates: [],
                availableDates2: [],
                dialogCalendar: false,
                currentTaskId: false,
                attributes: [{

                    //highlight: 'green',  // Boolean, String, Object
                    bar: 'orange',   // Boolean, String, Object

                    dates: new Date(),
                    order: 10
                }],
            }
        },

        computed: {
            ...mapGetters({
                getTasks: 'exhibitions/getTasks',
                getUserTasks: 'exhibitions/getUserTasks',
                getExhibitionsList: 'exhibitions/getExhibitionsList',
                getErrors: 'exhibitions/getErrors',
                getDates: 'exhibitions/getDates',
                getUserData: 'auth/getUserData',
                getUserProfile: 'auth/getUserProfile',
            }),

            maxDays: function() {
                let days = 0
                if (this.getExhibitionsList && this.getExhibitionsList.length) {
                    let acceptExhibitions = this.getExhibitionsList.filter(item => item.accepted)
                    for (let i = 0; i < acceptExhibitions.length; i++) {
                        if (acceptExhibitions[i].duration > days) {
                            days = acceptExhibitions[i].duration
                        }
                    }
                }
                return days
            },

            currentProgramExhibition: function() {
                if (this.getExhibitionsList && this.getExhibitionsList.length) {
                    let programExhibition = this.getExhibitionsList.filter(item => item.accepted && item.category == 'program')

                    if (programExhibition.length) {
                        return programExhibition[0]
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            },

            currentDayReal: function() {
                if (this.currentProgramExhibition) {
                    return this.currentProgramExhibition.day
                } else {
                    return 0
                }
            },

            getColorClass () {
                if (!this.getDates.empty) {
                    return 'empty'
                }
                if (this.getDates.empty.includes(this.filterDate)) return 'grey white--text'
                if (this.getDates.full.includes(this.filterDate)) return 'primary white--text'
                return 'empty'
            },

            filteredTasks() {

                if (this.accepted) {
                    return this.getUserTasks
                } else {
                    return this.getTasks.filter(item => item.days && item.days.split(',').includes(this.currentDay.toString()))
                }

                /*return (this.accepted ? this.getUserTasks : this.getTasks).filter(item => item.days && item.days.split(',').includes(this.accepted ? (this.filterExhibitionDay.toString() /!*item.user_exhibition.day.toString()*!/) : this.currentDay.toString()))*/
            }
        },

        async created() {
            if (this.getUserProfile) {
                await this.updateUserTasks()
            }
        },

        watch: {
            async currentTaskId(newVal) {
                this.$emit('task-selected', newVal)
            },

            async getUserProfile(profile) {
                if (this.accepted && profile) {
                    await this.updateUserTasks()
                }
            },
            async id(newVal) {
                console.log('newVal', newVal)
                if (newVal) {
                    await this.fetchTasks({exhibitionId: this.id})
                } else if (this.accepted) {
                    await this.fetchUserTasks({})
                }
            }
        },

        methods: {
            ...mapActions({
                fetchTasks: 'exhibitions/fetchTasks',
                fetchUserTasks: 'exhibitions/fetchUserTasks',
                fetchExhibitionsList: 'exhibitions/fetchExhibitionsList',
                fetchDates: 'exhibitions/fetchDates',
                doTask: 'exhibitions/doTask',
                fetchUserProfile: 'auth/fetchUserProfile',
            }),

            async updateUserTasks(day){
                this.day = typeof day != 'undefined' ? day : this.getUserProfile.day
                await this.fetchUserTasks({user_day: this.day})
                this.filterExhibitionDay = this.currentProgramExhibition.day
                console.log('this.filterExhibitionDay', this.filterExhibitionDay)
            },

            formatDay (day) {
                if (this.getDates.empty.includes(day)) return ['grey']
                if (this.getDates.full.includes(day)) return ['primary']
                return false
            },




            async openCalendar(date) {
                if (!this.currentProgramExhibition) {
                    return false
                }
                await this.fetchDates({exhibitionId: this.currentProgramExhibition.id})
                let dates = this.getDates.dates.map(item => new Date(item))
                this.attributes.push({
                    key: 'today',
                    dot: {
                        color: 'blue'
                    },
                    /*highlight: {
                      color: 'teal',
                      fillMode: 'solid',
                    },*/
                    dates: dates,
                    'available-dates': this.getDates.dates.map(item => new Date(item)),
                })
                this.availableDates = dates
                this.availableDates2 = this.getDates
                this.dialogCalendar = true
            },

            async selectDay2(date) {
                if (this.getDates.days[date]) {
                    this.day = this.getDates.days[date].user_day
                }
                await this.updateUserTasks(this.day)
                this.dialogCalendar = false
            },


            doTaskConfirm: async function(taskId) {
                const res = await this.$confirm('Mark this task as "done"?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
                if (res) {
                    await this.doTask({task_id: taskId})
                    if (this.accepted) {
                        await this.fetchUserTasks({})
                    } else {
                        await this.fetchTasks({exhibitionId: this.id})
                    }

                    await this.fetchUserProfile()
                    if (this.getUserProfile.energy == 0) {
                        await this.fetchExhibitionsList({})
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
