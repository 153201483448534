import { articles } from '@/config/api'
import ApiService from '@/services/api.service'

const state = () => ({
    articlesList: [],
    topics: [],
    dates: [],
    specialtiesList: [],
    articlesTotal: 0,
    content: null,
    tasksTotal: 0,
    userTasksTotal: 0,
    articlesLimitOnPage: 10,
    tasksLimitOnPage: 10,
    tasks: [],
    userTasks: [],
    errors: []

})

const getters = {
    getArticlesList: state => state.articlesList,
    getArticle: state => state.content,
    getTasks: state => state.tasks,
    getDates: state => state.dates,
    getUserTasks: state => state.userTasks,
    getArticlesTotal: state => state.articlesTotal,
    getTasksTotal: state => state.tasksTotal,
    getUserTasksTotal: state => state.userTasksTotal,
    getArticlesLimitOnPage: state => state.articlesLimitOnPage,
    getTasksLimitOnPage: state => state.tasksLimitOnPage,
    getErrors: state => state.errors
}

const actions = {

    async fetchArticlesList ({ commit, getters }, params) {
        const formattedParams = {
            ...params,
            limit: params.limit || getters.getArticlesLimitOnPage,
            offset: (getters.getArticlesLimitOnPage * (parseInt(params.page) || 1)) - getters.getArticlesLimitOnPage
        }
        try {
            // commit('START_LOAD_DNT_LIST')
            const response = await ApiService.get(articles.list, {
                params: {
                    language: 'en',
                    ...formattedParams
                },
                useCache: true
            })

            commit('SET_ARTICLES_LIST', {
                payload: response.data.data,
                headers: response.headers
            })
        } catch (err) {
            commit('SET_ARTICLES_LIST', {
                payload: [],
                headers: {}
            })
            console.log(err)
            throw err
        }
    },

    async fetchArticle ({ commit,  }, alias) {

        try {
            commit('START_LOAD_ARTICLE')
            const response = await ApiService.get(articles.content(alias), {

            })

            commit('SET_ARTICLE_CONTENT', {
                payload: response.data.data
            })
        } catch (err) {
            commit('SET_ARTICLE_CONTENT', {
                payload: []
            })
            console.log(err)
            throw err
        }
    },




    async saveArticle ({commit}, data) {
        try {
            const response = await ApiService.post(articles.save, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async deleteArticle ({commit}, articleId) {
        try {
            const response = await ApiService.post(articles.delete, {article_id: articleId})
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async acceptArticle ({commit}, data) {
        try {
            const response = await ApiService.post(articles.accept, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },




    async closeArticle ({commit}, data) {
        try {
            const response = await ApiService.post(articles.close, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async clearErrors ({commit}) {
        commit('CLEAR_ERRORS')
    }
}

const mutations = {
    CLEAR_ERRORS (state) {
        state.errors = []
    },

    ADD_ERROR (state, payload) {
        state.errors.push(payload)
    },

    START_LOAD_ARTICLE(state) {
        state.content = null
    },

    SET_ARTICLES_LIST (state, { payload, headers }) {
        state.articlesList = payload
        state.articlesTotal = parseInt(headers['x-items-total'])
    },

    SET_TASKS_LIST (state, { payload, headers }) {
        state.tasks = payload
        state.tasksTotal = parseInt(headers['x-items-total'])
    },

    SET_ARTICLE_CONTENT (state, { payload }) {
        state.content = payload
    },

    SET_ARTICLE_DATES (state, { payload }) {
        state.dates = payload
    },

    SET_USER_TASKS_LIST (state, { payload, headers }) {
        state.userTasks = payload
        state.userTasksTotal = parseInt(headers['x-items-total'])
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
