<template>
    <div class="white client fill-height"  :class="{isLoaded: isLoaded}">
<!--        <c-header v-if="!hideMenu" @open-menu="dialogTop = true" @close-menu="closeDialog"></c-header>-->
<!--        <canvas style="width: 100%;  left: 0; top:0; height: 100%; position: fixed;" id="gradientAnimatedBg"></canvas>-->

        <v-container :style="fullSize ? 'width: 100%;' : ''" :class="{formatted: hideMenu, 'mx-0 pa-0': fullSize, 'px-4': !fullSize}" fluid fill-height class=" gradientAnimatedBg mainContent" style="background-color: #5d8aa6">
            <slot/>
        </v-container>


        <v-footer
                v-if="!hideFooter"
                fixed
                dark
                padless
        >
            <div  style="width: 100%; background-color: #272727" class="d-flex align-center flex-grow-1 py-3 justify-space-between">
<!--teal-->
                <div class="ml-4 caption">
                    2018-{{ new Date().getFullYear() }} — Галерея Старых
                </div>
                <div class="">
                    <span class="caption hidden-sm-and-down">Для обратной связи: </span>
                    <a class="mx-4 mx-md-4" :key="icon.name" v-for="icon in icons" :href="icon.to">
                        <v-img v-if="icon.svg" style=" display: inline-block; top: 5px; position: relative; height: 20px; width: 20px; " :src="require('@/assets/' + icon.svg)"></v-img>
                        <v-icon v-else size="20">
                            {{ 'mdi-instagram' }}
                        </v-icon>
                    </a>
                </div>
            </div>

        </v-footer>


        <div v-if="false && !hideFooter" class="footer primary darken-4 theme--dark white--text" v-click-outside="{
        handler: onClickOutsideStandard,
        include: include,
      }" @click.stop="isFooterOpened = !isFooterOpened" :class="{opened: isFooterOpened}">
            <div class="d-flex  align-center justify-space-between footer-main">
                <div class="hidden-sm-and-down">
                    <a href="#"><v-icon class="mr-2 animate__animated animate__bounce animate__slower" size="1.5rem" >mdi-star-outline</v-icon> <!--<span class="animate secondary&#45;&#45;text">Link 1</span>--></a>
                </div>
                <div class="text-center flex-grow-1">
                    <v-btn dark class="topBtn" color="deep-purple  lighten-3" fab><v-icon>mdi-star</v-icon></v-btn>
                    <span class="subHeader secondary--text">Lorem ipsum dolor sit amet</span>
                </div>
                <div class="hidden-sm-and-down">
                    <a href="#"> <!--<span class="animate secondary&#45;&#45;text">Link 2</span>--> <v-icon size="1.5rem" class="ml-2 animate__animated animate__bounce animate__slower ">mdi-star-outline</v-icon> </a>
                </div>
            </div>
            <div class="footer-second-row">
                <div class="d-flex flex-wrap align-center justify-center">
                    <v-btn depressed  color="amber darken-3" rounded class="footerBtn">Button 1</v-btn>
                    <v-btn depressed   rounded class="footerBtn">Button 2</v-btn>
                    <v-btn depressed  rounded class="footerBtn">Button 3</v-btn>
                    <v-btn depressed  color="amber darken-3"  rounded class="footerBtn">Button 4</v-btn>
                </div>

            </div>
        </div>

        <v-dialog persistent :content-class="'black' " transition="dialog-top-transition"  fullscreen dark  v-model="dialogTop">

            <div class="text-right" style="padding: 1.25rem 2.5rem">
<!--                <v-btn @click="closeDialog" fab  dark><v-icon>mdi-close</v-icon></v-btn>-->
<!--                <v-icon class="mr-2" @click="dialogTop=false">mdi-close</v-icon>-->
            </div>

        </v-dialog>


        <div v-if="!$vuetify.breakpoint.mobile" style="position: fixed; width: 120px; left: 80px;top: 50%; transform: translate(0, -50%);  z-index:11; text-align: center;">
            <slot name="middle-btn"></slot>
        </div>

        <div v-else style="position: fixed; right: 16px; top: 50%; transform: translate(0, -50%); z-index:11">
            <slot name="middle-btn"></slot>
        </div>

        <v-overlay :value="!isLoaded">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-overlay opacity="0.5" color="white" :value="isFooterOpened">

        </v-overlay>
    </div>
</template>

<script>
    // import { fromInjected } from '@openzeppelin/network'; // fromConnection
    // const infuraProjectId = 'b7a9e147ffe74544bf96f393acccde03';

    // const contract = require("@truffle/contract");
    // import MainTokenJson from '@/contracts/MainToken.json'
    // const Granim = require('granim')
    import { mapGetters, mapActions } from 'vuex' // mapActions
    import Header from '@/components/common/header'
    import 'animate.css';
    export default {
        props: {
            source: String,
            isLoaded: Boolean,
            fullSize: {type: Boolean, default: false},
            hideFooter: {type: Boolean, default: false},
            hideMenu: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            CHeader: Header
        },

        computed: {
            ...mapGetters({
                account: 'auth/account'
            })
        },
        data: () => ({
            icons: [{
                name: 'vk',
                svg: 'vk.svg',
                to: 'https://vk.com/starykh.gallery',
            },/*{
                name: 'twitter',
                to: 'https://instagram.com',
            },*/
            /*{
                name: 'facebook',
                to: 'https://www.facebook.com/starykhGallery/'
            },
            {
                name: 'vk',
                to: 'https://vk.com/starykh.gallery'
            }*/],
            search: '',
            hideDialog: false,
            isFooterOpened: false,
            dialogTop: false,
        }),
        methods: {
            ...mapActions({
                setAccount: 'auth/setAccount',
                updateBalance: 'token/updateBalance',
                initToken: 'token/init',
                initLottery: 'lottery/init',
                updateDepositorBalance: 'lottery/updateDepositorBalance',
                updateWithdrawalBalance: 'lottery/updateWithdrawalBalance',
            }),

            onClickOutsideStandard () {
                this.isFooterOpened = false
            },
            closeDialog () {
                this.dialogTop = false
                /*this.hideDialog = true;
                setTimeout(() => {
                    this.dialogTop = false
                    this.hideDialog = false
                }, 1000)*/


                /*const element = document.querySelector('.v-dialog--active');
                this.hideDialog = true;
                let _self = this
                element.addEventListener('animationend', function handler() {
                    // do something
                    console.log('animationend')
                    _self.dialogTop = false
                    _self.hideDialog = false;
                }, {once: true});*/

                /*setTimeout(() => {
                    this.dialogTop = false
                    this.hideDialog = false;
                }, 1000)*/
            },

            include () {
                return [document.querySelector('.footer-main')]
            },

            async  setAcc() {
                this.setAccount(this.search)
                await this.updateBalance(this.account)
                await this.initToken()
                await this.initLottery()
                await this.updateDepositorBalance(this.account)
                await this.updateWithdrawalBalance(this.account)
            }
            /*async  updateNetwork(networkId, networkName) {
              console.log('updateNetwork', networkId, networkName)
            },
            async updateAccounts(accounts) {
              console.log('updateAccounts', accounts)
              if (accounts.length) {
                this.account = accounts[0]
              }
            },
            async updateConnection(connected) {
              console.log('updateConnection', connected)
            },*/
        },
        mounted: async function() {
            //#f0dea3
            // #d1e4fe
            /*var granimInstance = new Granim({
                element: '#gradientAnimatedBg',
                name: 'granim',
                direction: 'radial',
                opacity: [1, 1],

                states : {
                    "default-state": {
                        /!*gradients: [
                            [{ color: '#f0dea3' }, '#d1e4fe'],
                            ['#d2dcfe', '#b39ddb']
                        ],*!/
                        gradients: [
                            [{ color: '#fbc0ba' }, '#fbead0'],
                            ['#fbead0', '#fbc0ba']
                            //['#f99e4c', '#ee6647']
                        ],
                        transitionSpeed: 5000,
                    }
                }
            });*/

        }
    }
</script>

<style lang="scss">
    $color: #fff;

    .mainContent {
        /*width: 1140px;*/
        max-width: 100%;
    }

    .mainContent.formatted {
        max-width: 1000px;
        text-align: center;
        padding-top: 0px;
    }

    .hidden {
        visibility: hidden;
    }

    .v-btn.animated {
        display: inline-block;
        padding: .75rem 1.25rem;
        /*border-radius: 10rem;*/
        /*color: #fff;*/
        /*text-transform: uppercase;*/
        font-size: 1rem;
        /*letter-spacing: .15rem;*/
        transition: all .3s;
        position: relative;
        overflow: hidden;
        background: transparent;
        position: relative;
        /*box-shadow: none;*/
        outline: none;
        z-index: 1;
        /*&:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            !*background-color: $color;*!
            border-radius: 10rem;
            z-index: -2;
        }*/
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            /*background-color: #b39ddb;*/
            opacity: 1 !important;
            background-image: radial-gradient(circle,#69b3fc 0,#a87df3 100%);
            transition: all .3s;
            /*border-radius: 10rem;*/
            z-index: -1;
        }
        &:hover {
            color: #fff;
            box-shadow: none;
            /*background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);*/
            &:before {
                width: 100%;
            }
        }
    }
</style>
<style lang="css">
    .footer {
        position: fixed;
        bottom: 0px;
        z-index: 15;
        width: 100%;
        background: white;
        box-shadow: 0 3px 15px 0 rgba(0,0,0,.15);
        transition: transform .6s cubic-bezier(.35,.755,.42,.95);
        transform: translate3d(0,120%,0);
        pointer-events: auto;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .isLoaded .footer {
        cursor: pointer;
    }

    @media (min-width:700px) {
        .isLoaded .footer {
            transform:translate3d(0,100%,0) translate3d(0,-6.25rem,0)
        }
    }
    @media (max-width:699px) {
        .isLoaded .footer {
            transform:translate3d(0,100%,0) translate3d(0,-4.375rem,0)
        }
    }
    html {
        overflow: auto;
    }

    .mainContent {
        min-height: calc(100vh - 64px);
        display:flex;
        align-items:center;
        text-align:center;
        box-sizing: border-box;
        /*padding-bottom:11.25rem*/
        padding-bottom: 128px;
        overflow: hidden;
    }



    .isLoaded .footer:hover {
        transform: translate3d(0,100%,0) translate3d(0,-6.875rem,0);
    }
    .isLoaded .footer.opened, .isLoaded .footer.opened:hover {
        transform: translateZ(0) !important;
    }

    .footer-second-row {
        padding-bottom: 2.5rem;
    }
    @media (min-width:700px) {
        .footer-second-row {
            padding-top: 2.5rem;
        }
    }

    .footerBtn {
        margin: 0 .625rem
    }

    @media (max-width:699px) {
        .footerBtn {
            margin: .625rem 0;
            width: 100%;
        }
    }



   .subHeader {
       /*font-weight: 600;*/
        font-size: 1.125rem;
       color: rgb(34,34,34)
    }

    @media (max-width:1199px) {
        .subHeader {
            font-size:1rem
        }
    }
    @media (max-width:699px) {
        .subHeader {
            font-size: .875rem
        }
    }




    #app .footer a {
        color: rgb(34,34,34);
        text-decoration: none;
        position: relative;
        font-size:  .875rem
    }

    #app .footer a .animate {
        position: relative;
    }

    .footer a .animate:after {
        /*background: none repeat scroll 0 0 transparent;*/
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background-color: rgb(34,34,34) !important;
        transition: width 0.2s ease 0s, left 0.2s ease 0s;
        width: 0;
    }
    .footer a:hover .animate:after {
        width: 100%;
        left: 0;
    }

    .footer .topBtn {
        position:absolute;
        top:0;
        left:50%;
        transform:translate3d(-50%,-50%,0);
    }

    @media (min-width:700px) {
        .footer-main {
            height:6.25rem
        }

        .topBtn.v-btn {
            width:4.0225rem;
            height:4.025rem
        }
        .topBtn.v-btn svg {
            width:1.75rem;
            height:1.75rem
        }
    }
    @media (max-width:699px) {
        .footer-main {
            height: 4.375rem;
        }

        .topBtn.v-btn {
            width:2.925rem;
            height:2.925rem
        }
        .topBtn.v-btn svg {
            width:1.25rem;
            height:1.25rem
        }
    }


    .gradientAnimated {
        /*background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);*/
        background: linear-gradient(-45deg, #fcbfba, #faead0/*, #23a6d5, #23d5ab*/);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    /*#app .v-btn.animated{
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
    }

    #app .v-btn.animated:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0cf;
        border-radius: 28px;
        z-index: -2;
    }
    #app .v-btn.animated:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #5be;;
        transition: all .3s;
        border-radius: 28px;
        z-index: -1;
    }

    #app .v-btn.animated:hover{
        color: #fff;
    }

    #app .v-btn.animated:hover:before {
        width: 100%;
    }*/

    #app .client * {
        /*font-family: Mukta,sans-serif;*/
        /*font-family: Source Sans Pro,sans-serif;*/
        font-family: Work Sans,sans-serif;
    }

    #app .pageTitle {
        font-size: 3.75rem;
        line-height: 1.13333;
        margin-bottom: 1.5rem;
        text-transform: none;
        letter-spacing: normal;
        font-weight: 500;
        /*font-family: "SofiaProWeb", Helvetica, Arial, sans-serif;*/
        /*font-family: Mukta,sans-serif !important;*/
        /*font-family: Source Sans Pro,sans-serif !important;*/
        font-family: Work Sans,sans-serif !important;
    }

    .lead {
        font-size: 1.125rem;
        line-height: 1.55556;
        font-weight: 400;
    }

    #app .v-btn__content {
        text-transform: none;
    }

    .o-container.-small {
        width:87.5rem;
        max-width: 100%;
    }
    @media (min-width:1400px) {
        .o-container.-small {
            padding-right:11.25rem;
            padding-left:11.25rem
        }
        [data-container-size=small] .o-container.-small {
            padding-right:7.5rem;
            padding-left:7.5rem
        }
    }
    @media (max-width:1399px) and (min-width:700px) {
        .o-container.-small {
            padding-right:7.5rem;
            padding-left:7.5rem
        }
        [data-container-size=small] .o-container.-small {
            padding-right:6.25rem;
            padding-left:6.25rem
        }
    }
    @media (max-width:699px) {
        .o-container.-small {
            padding-right:1.875rem;
            padding-left:1.875rem
        }
    }
    @media (min-width:1400px) {
        .o-container:not(.-small) {
            padding-right:11.25rem;
            padding-left:11.25rem
        }
        [data-container-size=small] .o-container:not(.-small) {
            padding-right:7.5rem;
            padding-left:7.5rem
        }
    }
    @media (max-width:1399px) and (min-width:700px) {
        .o-container:not(.-small) {
            padding-right:7.5rem;
            padding-left:7.5rem
        }
        [data-container-size=small] .o-container:not(.-small) {
            padding-right:6.25rem;
            padding-left:6.25rem
        }
    }
    @media (max-width:699px) {
        .o-container:not(.-small) {
            padding-right:1.875rem;
            padding-left:1.875rem
        }
    }



</style>
