import { teams } from '@/config/api'
import ApiService from '@/services/api.service'

const state = () => ({
    teamsList: [],
    topics: [],
    dates: [],
    specialtiesList: [],
    teamsTotal: 0,
    content: null,
    tasksTotal: 0,
    userTasksTotal: 0,
    teamsLimitOnPage: 10,
    tasksLimitOnPage: 10,
    tasks: [],
    userTasks: [],
    errors: []

})

const getters = {
    getTeamsList: state => state.teamsList,
    getTeam: state => state.content,
    getTasks: state => state.tasks,
    getDates: state => state.dates,
    getUserTasks: state => state.userTasks,
    getTeamsTotal: state => state.teamsTotal,
    getTasksTotal: state => state.tasksTotal,
    getUserTasksTotal: state => state.userTasksTotal,
    getTeamsLimitOnPage: state => state.teamsLimitOnPage,
    getTasksLimitOnPage: state => state.tasksLimitOnPage,
    getErrors: state => state.errors
}

const actions = {

    async fetchTeamsList ({ commit, getters }, params) {
        const formattedParams = {
            ...params,
            limit: params.limit || getters.getTeamsLimitOnPage,
            offset: (getters.getTeamsLimitOnPage * (parseInt(params.page) || 1)) - getters.getTeamsLimitOnPage
        }
        try {
            // commit('START_LOAD_DNT_LIST')
            const response = await ApiService.get(teams.list, {
                params: {
                    language: 'en',
                    ...formattedParams
                },
                useCache: true
            })

            commit('SET_TEAMS_LIST', {
                payload: response.data.data,
                headers: response.headers
            })
        } catch (err) {
            commit('SET_TEAMS_LIST', {
                payload: [],
                headers: {}
            })
            console.log(err)
            throw err
        }
    },

    async fetchTeam ({ commit,  }, alias) {

        try {
            commit('START_LOAD_TEAM')
            const response = await ApiService.get(teams.content(alias), {

            })

            commit('SET_TEAM_CONTENT', {
                payload: response.data.data
            })
        } catch (err) {
            commit('SET_TEAM_CONTENT', {
                payload: []
            })
            console.log(err)
            throw err
        }
    },




    async saveTeam ({commit}, data) {
        try {
            const response = await ApiService.post(teams.save, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async deleteTeam ({commit}, teamId) {
        try {
            const response = await ApiService.post(teams.delete, {team_id: teamId})
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async acceptTeam ({commit}, data) {
        try {
            const response = await ApiService.post(teams.accept, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },




    async closeTeam ({commit}, data) {
        try {
            const response = await ApiService.post(teams.close, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async clearErrors ({commit}) {
        commit('CLEAR_ERRORS')
    }
}

const mutations = {
    CLEAR_ERRORS (state) {
        state.errors = []
    },

    ADD_ERROR (state, payload) {
        state.errors.push(payload)
    },

    START_LOAD_TEAM(state) {
        state.content = null
    },

    SET_TEAMS_LIST (state, { payload, headers }) {
        state.teamsList = payload
        state.teamsTotal = parseInt(headers['x-items-total'])
    },

    SET_TASKS_LIST (state, { payload, headers }) {
        state.tasks = payload
        state.tasksTotal = parseInt(headers['x-items-total'])
    },

    SET_TEAM_CONTENT (state, { payload }) {
        state.content = payload
    },

    SET_TEAM_DATES (state, { payload }) {
        state.dates = payload
    },

    SET_USER_TASKS_LIST (state, { payload, headers }) {
        state.userTasks = payload
        state.userTasksTotal = parseInt(headers['x-items-total'])
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
