export default {
    computed: {
        getStatusColor() {
            return (status) => {
                let statuses = {
                    "new": 'grey',
                    "accepted": 'amber',
                    "preparing": 'amber',
                    "answered": 'blue',
                    "done": 'green',
                    "closed": 'green'
                }
                return statuses[status]
            }
        },
    }
}

