<template>
    <div>


        <navigation :hideTopBar="hideTopBar" app>
            <template v-slot:filters>
                <slot name="filters"></slot>
            </template>
        </navigation>
        <v-container class="pa-0 " style="position: relative">
            <v-row style="min-height: calc(100vh - 64px)" no-gutters class="justify-center">
                <v-col
                        style="max-width: 275px; "
                        v-if="!hideSides"
                        class="d-none d-md-block transparent px-7"
                        cols="12"
                        md="2"
                >
                    <slot name="left-column"></slot><!--  -->
                </v-col>

                <v-col
                        style="max-width: 596px; border-left: 1px solid rgb(235, 238, 240); border-right: 1px solid rgb(235, 238, 240); position: relative;"
                        class="px-0 white d-flex align-stretch"
                        cols="12"
                        :md="hideSides ? 11 : 8"
                        :lg="hideSides ? 10 : 8"
                        :xl="hideSides ? 8 : 8"
                >
                    <slot/>
                </v-col>

                <v-col

                        style="max-width: 275px; "
                        v-if="!hideSides"
                        class="transparent"
                        cols="12"
                        md="2"
                >
                    <slot name="right-column"></slot>
                </v-col>
            </v-row>

        </v-container>
    </div>

    <!--<loading class="justify-center uk-text-center mt-5" v-if="profileStatus === 'loading' || authStatus === 'loading'"/>-->
    <!--<v-footer app
              class=" py-4"
              padless
              color="white"
    >
        <v-row
                justify="center"
                no-gutters

        >
            <v-col
                    class="text-center"
                    cols="12"
            >
                <span>&copy; {{ new Date().getFullYear() }}</span> — <strong>DCH</strong>
            </v-col>
        </v-row>
    </v-footer>-->
</template>

<script>

    import Navigation from '@/components/navigation'
    import { mapGetters, mapActions } from 'vuex' // mapActions
    export default {
        name: 'LayoutDefault',
        props: {
            source: String,
            hideTopBar: {type: Boolean, default: false},
            hideFooter: {type: Boolean, default: false},
            dense: {type: Boolean, default: false},
            'hide-sides': Boolean
        },
        components: {
            Navigation
        },

        computed: {
            ...mapGetters({})
        },
        data: () => ({
            search: ''
        }),
        methods: {
            ...mapActions({}),

        },
        created: async function () {

        }
    }
</script>

<style lang="scss">


    body {
        .v-list {
            .v-list-item--active {
                color: #637188;
            }
        }
    }
</style>
