import moment from 'moment'

export default {

    computed: {
        timeFormattedMomentjs () {
            return (i) => {
                //i = i.replace("","T")
                //console.log('iii', i,  moment.tz(i, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin'))
                if (moment.tz(i, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin').add(2, 'hours').isSame(new Date(), 'date')) {
                    return (moment.duration( moment().diff(moment.tz(i, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin')) ).hours() + 1) + 'h ago'
                    // return (moment.duration(moment().diff(moment.tz(i, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin').add(2, 'hours'))).hours + 1) + 'h'
                } else {
                    return moment.tz(i, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin').add(2, 'hours').format('DD MMM')
                    //return moment.tz(i, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin').add(2, 'hours').format('HH:mm')
                }

            }
        },
        dateFormattedMomentjs () {
            return (i) => {
                if (moment.tz(i, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin').add(2, 'hours').isSame(new Date(), 'date')) {
                    return ''
                } else {
                    return ''
                    return moment.tz(i, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin').add(2, 'hours').format('DD MMM')
                }

            }
        },
        dateShort() {
            return (i) => {
                return moment.tz(i, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin').add(2, 'hours').format('DD MMM')

            }
        },
        formatDate () {
            return (i) => {
                return moment(i, 'YYYY-MM-DD').format('D MMM, Y')
            }
        },

        formatDateTime () {
            return (i) => {
                return moment(i, 'YYYY-MM-DD H:m:s').format('MMM D, Y H:m')
            }
        },
        /*dayFormattedMomentjs () {
            return (i) => {
                return moment(i, 'YYYY-MM-DD').format('DD')
            }
        },
        dateShortFormattedMomentjs () {
            return (i) => {
                return moment(i, 'YYYY-MM-DD').format('MMM\'YY')
            }
        },*/
    },
    methods: {
        getLastDate() {
            return  moment.tz('Europe/Berlin').add(-1, 'days').format('YYYY-MM-DD')
        }
    },
}
