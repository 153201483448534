import Vue from 'vue'
import Vuex from 'vuex'
import dnet from './dnet'
import user from './user'
import chats from './chats'
import teams from './teams'
import contests from './contests'
import shop from './shop'
import cart from './cart'
import articles from './articles'
import exhibitions from './exhibitions'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    dnet,
    user,
    chats,
    cart,
    teams,
    shop,
    contests,
    articles,
    exhibitions,
    auth,
  }
})
