import { chats } from '@/config/api'
import ApiService from '@/services/api.service'

const state = () => ({
    chatsList: [],
    topics: [],
    specialtiesList: [],
    chatsTotal: 0,
    chatsLimitOnPage: 10,
    messages: [],
    errors: []

})

const getters = {
    getChatsList: state => state.chatsList,
    getTopicsList: state => state.topics,
    getChatsTotal: state => state.chatsTotal,
    getChatsLimitOnPage: state => state.chatsLimitOnPage,
    getMessages: state => state.messages,
    getErrors: state => state.errors
}

const actions = {

    async fetchChatsList ({ commit, getters }, params) {
        const formattedParams = {
            ...params,
            limit: params.limit || getters.getChatsLimitOnPage,
            offset: (getters.getChatsLimitOnPage * (parseInt(params.page) || 1)) - getters.getChatsLimitOnPage
        }
        try {
            // commit('START_LOAD_DNT_LIST')
            const response = await ApiService.get(chats.list, {
                params: {
                    language: 'en',
                    ...formattedParams
                },
                useCache: true
            })

            commit('SET_CHATS_LIST', {
                payload: response.data.data,
                headers: response.headers
            })
        } catch (err) {
            commit('SET_CHATS_LIST', {
                payload: [],
                headers: {}
            })
            console.log(err)
            throw err
        }
    },

    async fetchMessages ({ commit }, params) {
        try {
            const { data: { messages } } = await ApiService.get(chats.messages(params.chatId), {
                params: {
                    language: 'en'
                }
            })
            console.log('messages', messages)
            commit('SET_DNT_MESSAGES', messages)
            return messages
        } catch (err) {
            console.log('err', err)
        }
    },

    async fetchTopics ({ commit }) {
        try {
            const { data: { data } } = await ApiService.get(chats.topics)
            commit('SET_DNT_TOPICS', data)
            return data
        } catch (err) {
            console.log('err', err)
        }
    },



    async saveChat ({commit}, data) {
        try {
            const response = await ApiService.post(chats.save, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async deleteChat ({commit}, chatId) {
        try {
            const response = await ApiService.post(chats.delete, {chat_id: chatId})
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async acceptChat ({commit}, data) {
        try {
            const response = await ApiService.post(chats.accept, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async closeChat ({commit}, data) {
        try {
            const response = await ApiService.post(chats.close, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async moderateChat ({commit}, data) {
        try {
            const response = await ApiService.post(chats.moderate, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async refuseChat ({commit}, chatId) {
        try {
            const response = await ApiService.post(chats.refuse, {chat_id: chatId})
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async postMessage ({commit}, data) {
        try {
            const response = await ApiService.post(chats.sendMessage, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async deleteMessage ({commit}, data) {
        try {
            const response = await ApiService.post(chats.deleteMessage, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async clearErrors ({commit}) {
        commit('CLEAR_ERRORS')
    }
}

const mutations = {
    CLEAR_ERRORS (state) {
        state.errors = []
    },

    ADD_ERROR (state, payload) {
        state.errors.push(payload)
    },

    START_LOAD_CHATS_LIST(state) {
        state.chatsTotal = 0
        state.chatsList = []
    },

    SET_CHATS_LIST (state, { payload, headers }) {
        state.chatsList = payload
        state.chatsTotal = parseInt(headers['x-items-total'])
    },

    SET_DNT_MESSAGES (state, payload) {
        state.messages = payload
    },

    SET_DNT_TOPICS (state, payload) {
        state.topics = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
