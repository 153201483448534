<template>
  <layout-default :full-size="true" :hide-footer="true" :is-loaded="true">



        <div class=" mx-auto d-flex justify-center" style=" position: relative; width: 100%; height: 100%;">
          <iframe style="width: 100%; height: 100%;" src="https://api.starykhgallery.ru/tour_export1/" frameborder="0"></iframe>
        </div>

  </layout-default>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import LayoutDefault from '@/layouts/LayoutClient.vue'
  import AvatarUploader from '@/components/users/AvatarUploader'
  import Avatar from '@/components/avatar'
  export default {
    name: 'VR',
    components: {
      LayoutDefault,
      AvatarUploader,
      Avatar
    },
    data: () => ({
      dialog: false,
      menu: false,
      editedProfile: null,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ]
    }),
    computed: {
      ...mapGetters({
        getIsUserLoggedIn: 'auth/getIsUserLoggedIn',
        getUserData: 'auth/getUserData',
        getErrors: 'auth/getErrors',
        getUserProfile: 'auth/getUserProfile'
      }),
      currentRouteName () {
        return this.$route.name
      },
      role() {
        if (this.getUserProfile) {
          switch (this.getUserProfile.role) {
            case 'user':
              return 'Участник'
              break;
            case 'painter':
              return 'Художник'
              break;
            case 'profi':
              return 'Профи'
              break;
            case 'collector':
              return 'Коллекционер'
              break;
          }
        } else {
          return '—'
        }
      }
    },
    methods: {
      ...mapActions({
        fetchUserProfile: 'auth/fetchUserProfile',
        saveProfile: 'auth/saveProfile',
        clearErrors : 'auth/clearErrors'
      }),
      postProfile: async function() {
        try {
          this.clearErrors()

          if (!this.$refs.formProfile.validate()) {
            return false
          }

          await this.saveProfile(
            this.editedProfile
          )
          if (this.getErrors.length > 0) {
            this.alertError = true
          } else {
            this.alertError = false
            this.dialog = false
            await this.fetchUserProfile()
          }

        } catch (e) {
          console.log('error', e)
        }
      },
      save (date) {
        this.$refs.menu.save(date)
      }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    async created () {
      await this.fetchUserProfile()
      this.editedProfile = this.getUserProfile
    }
  }
</script>
