<template>
    <v-list-item
            :disabled="readonly"
            class="px-7"
            :value="task.id"
    >
        <!--<v-list-item-avatar size="8" class="mr-2"  :color="getStatusColor(task.status)">
        </v-list-item-avatar>-->
        <v-list-item-content class="d-block py-4" style="overflow:visible;">
            <v-list-item-subtitle class="author mb-0 d-flex justify-space-between font-weight-medium black--text"
            >
                <div class="grey--text" >
                    <template v-if="task.exhibition">
                        <span >
                        {{ task.exhibition.name }}</span>
                    </template>


                    <span v-if="task.exhibition && task.time_of_day" class="px-1">·</span>
                    {{ task.time_of_day }}
                </div>
                <v-spacer></v-spacer>
                <div class="grey--text text-right">{{ task.points }} points <!--<span class="px-1">·</span>-->
                    <span class="grey--text"><!--days: {{ task.days }}--> <!--x {{ task.repeats }}--></span>
                    <span class=" ml-1 grey--text text--darken-1">{{ dateFormattedMomentjs(task.created_at) }}</span>
                </div>
                <!--<div>

                </div>-->

            </v-list-item-subtitle>
            <v-list-item-title class="mt-2 mb-3 text-wrap">{{ task.name }}</v-list-item-title>
            <v-list-item-subtitle style="overflow: visible" class="text-right align-end d-flex justify-space-between actions" >
                <div>
                    <span class="float-left">x {{ task.repeats }}</span>
                    <template v-if="task.exhibition">
                        <span class="px-1" >·</span>
                        day <span class="red--text font-weight-bold">{{ task.user_exhibition.day }}</span>
                    </template>

                </div>

                <v-spacer></v-spacer>
                <slot></slot>
            </v-list-item-subtitle>
        </v-list-item-content>
        <!--<v-list-item-action class=" body-2">
            <span class="font-weight-medium">{{ timeFormattedMomentjs(task.created_at) }}</span>
            <div class="caption grey&#45;&#45;text text&#45;&#45;darken-1">{{ dateFormattedMomentjs(task.created_at) }}</div>
        </v-list-item-action>-->
        <!--<v-list-item-icon class="date align-self-center-old mt-3 ">
            <div>
                <div>
                    <span class="grey&#45;&#45;text">{{ timeFormattedMomentjs(task.created_at) }}</span>
                    <span class="caption ml-1 grey&#45;&#45;text text&#45;&#45;darken-1">{{ dateFormattedMomentjs(task.created_at) }}</span>
                </div>
            </div>

        </v-list-item-icon>-->
    </v-list-item>

</template>

<script>
    import dateTimeMixin from '@/mixins/datetime'
    import colorsMixin from '@/mixins/colors'
    import { mapActions } from 'vuex'

    export default {
        name: 'q-list-item',
        mixins: [dateTimeMixin, colorsMixin],
        props: {
            task: {
                type: Object,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {

            }
        },

        created() {

        }
    }
</script>

<style scoped>

</style>
