<template>
    <v-list two-line class="articlesList mt-7">
        <v-list-item-group
                v-if="group"
                v-model="currentArticleId"
                color="indigo"
        >
            <slot />
        </v-list-item-group>
        <template v-else>
            <slot />
        </template>
    </v-list>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'q-list',
        props: {
            group: {
                type: Boolean,
                default: true,
            },
            id: {
                default: false,
            },
        },

        data () {
            return {
                currentArticleId: false
            }
        },

        created() {

        },

        computed: {
            ...mapGetters({
                getArticlesList: 'articles/getArticlesList',
            }),
        },

        watch: {
            currentArticleId(newVal) {
                const article = this.getArticlesList.find(item => item.id == newVal)

                if (article) {
                    this.$emit('article-selected', article)
                }
            },
            id () {
                this.currentArticleId = this.id
            }
        }
    }
</script>

<style scoped>

</style>
