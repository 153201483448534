import { exhibitions } from '@/config/api'
import ApiService from '@/services/api.service'

const state = () => ({
    exhibitionsList: [],
    topics: [],
    dates: [],
    specialtiesList: [],
    exhibitionsTotal: 0,
    content: null,
    tasksTotal: 0,
    userTasksTotal: 0,
    exhibitionsLimitOnPage: 10,
    tasksLimitOnPage: 10,
    tasks: [],
    userTasks: [],
    errors: []

})

const getters = {
    getExhibitionsList: state => state.exhibitionsList,
    getExhibition: state => state.content,
    getTasks: state => state.tasks,
    getDates: state => state.dates,
    getUserTasks: state => state.userTasks,
    getExhibitionsTotal: state => state.exhibitionsTotal,
    getTasksTotal: state => state.tasksTotal,
    getUserTasksTotal: state => state.userTasksTotal,
    getExhibitionsLimitOnPage: state => state.exhibitionsLimitOnPage,
    getTasksLimitOnPage: state => state.tasksLimitOnPage,
    getErrors: state => state.errors
}

const actions = {

    async fetchExhibitionsList ({ commit, getters }, params) {
        const formattedParams = {
            ...params,
            limit: params.limit || getters.getExhibitionsLimitOnPage,
            offset: (getters.getExhibitionsLimitOnPage * (parseInt(params.page) || 1)) - getters.getExhibitionsLimitOnPage
        }
        try {
            // commit('START_LOAD_DNT_LIST')
            const response = await ApiService.get(exhibitions.list, {
                params: {
                    language: 'en',
                    ...formattedParams
                },
                useCache: true
            })

            commit('SET_EXHIBITIONS_LIST', {
                payload: response.data.data,
                headers: response.headers
            })
        } catch (err) {
            commit('SET_EXHIBITIONS_LIST', {
                payload: [],
                headers: {}
            })
            console.log(err)
            throw err
        }
    },

    async fetchExhibition ({ commit,  }, alias) {

        try {
            commit('START_LOAD_EXHIBITION')
            const response = await ApiService.get(exhibitions.content(alias), {

            })

            commit('SET_EXHIBITION_CONTENT', {
                payload: response.data.data
            })
        } catch (err) {
            commit('SET_EXHIBITION_CONTENT', {
                payload: []
            })
            console.log(err)
            throw err
        }
    },

    async fetchTasks ({ commit, getters }, params) {
        const formattedParams = {
            ...params,
            limit: params.limit || getters.getTasksLimitOnPage,
            offset: (getters.getTasksLimitOnPage * (parseInt(params.page) || 1)) - getters.getTasksLimitOnPage
        }
        try {

            const response = await ApiService.get(exhibitions.tasks(params.exhibitionId), {
                params: {
                    language: 'en',
                    ...formattedParams
                },
                useCache: true
            })

            commit('SET_TASKS_LIST', {
                payload: response.data.data,
                headers: response.headers
            })
        } catch (err) {
            commit('SET_TASKS_LIST', {
                payload: [],
                headers: {}
            })
            console.log(err)
            throw err
        }
    },

    async fetchDates ({ commit, getters }, params) {
        const limit = params.limit || 50
        const offset = isNaN(params.offset) ? (limit * (parseInt(params.page) || 1)) - limit  : params.offset
        const formattedParams = {
            ...params,
            limit: limit,
            offset: offset
        }
        try {
            //commit('START_LOAD_CHALLENGE_LIST')
            const response = await ApiService.get(exhibitions.dates(params.exhibitionId), {
                params: {
                    dev: true,
                    language: 'en',
                    ...formattedParams
                },
                useCache: true
            })


            commit('SET_EXHIBITION_DATES', {
                payload: response.data.data
            })
        } catch (err) {
            console.log(err)
            throw err
        }
    },

    async fetchUserTasks ({ commit, getters }, params) {
        const formattedParams = {
            ...params,
            limit: params.limit || getters.getTasksLimitOnPage,
            offset: (getters.getTasksLimitOnPage * (parseInt(params.page) || 1)) - getters.getTasksLimitOnPage
        }
        try {
            // commit('START_LOAD_DNT_LIST')
            const response = await ApiService.get(exhibitions.tasks(0), {
                params: {
                    accepted: true,
                    language: 'en',
                    ...formattedParams
                },
                useCache: true
            })

            commit('SET_USER_TASKS_LIST', {
                payload: response.data.data,
                headers: response.headers
            })
        } catch (err) {
            commit('SET_USER_TASKS_LIST', {
                payload: [],
                headers: {}
            })
            console.log(err)
            throw err
        }
    },


    async saveExhibition ({commit}, data) {
        try {
            const response = await ApiService.post(exhibitions.save, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async deleteExhibition ({commit}, exhibitionId) {
        try {
            const response = await ApiService.post(exhibitions.delete, {exhibition_id: exhibitionId})
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async acceptExhibition ({commit}, data) {
        try {
            const response = await ApiService.post(exhibitions.accept, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async doTask ({commit}, data) {
        try {
            const response = await ApiService.post(exhibitions.doTask, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async nextDay ({commit}, data) {
        try {
            const response = await ApiService.post(exhibitions.nextDay, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async closeExhibition ({commit}, data) {
        try {
            const response = await ApiService.post(exhibitions.close, data)
            if (typeof response.data.error != 'undefined') {
                commit('ADD_ERROR', response.data.error)
                return false
            } else {
                return response
            }
        } catch (err) {
            console.log('err', err)
            throw err
        }
    },

    async clearErrors ({commit}) {
        commit('CLEAR_ERRORS')
    }
}

const mutations = {
    CLEAR_ERRORS (state) {
        state.errors = []
    },

    ADD_ERROR (state, payload) {
        state.errors.push(payload)
    },

    START_LOAD_EXHIBITION(state) {
        state.content = null
    },

    SET_EXHIBITIONS_LIST (state, { payload, headers }) {
        state.exhibitionsList = payload
        state.exhibitionsTotal = parseInt(headers['x-items-total'])
    },

    SET_TASKS_LIST (state, { payload, headers }) {
        state.tasks = payload
        state.tasksTotal = parseInt(headers['x-items-total'])
    },

    SET_EXHIBITION_CONTENT (state, { payload }) {
        state.content = payload
    },

    SET_EXHIBITION_DATES (state, { payload }) {
        state.dates = payload
    },

    SET_USER_TASKS_LIST (state, { payload, headers }) {
        state.userTasks = payload
        state.userTasksTotal = parseInt(headers['x-items-total'])
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
