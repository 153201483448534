<!--<i18n>
    {
        "en": {
            "creatives": "creatives",
            "permissions": "permissions",
            "sites": "sites",
            "profile": "profile",
            "adCampaigns": "Ad Campaigns",
            "agencies": "agencies",
            "profiling": "prf",
            "holdings": "holdings",
            "login": "login",
            "users": "users",
            "booking": "booking",
            "logout": "logout"
        },
        "jp": {
            "creatives": "クリエイティブ",
            "permissions": "権限",
            "sites": "サイト",
            "profile": "概要",
            "adCampaigns": "広告キャンペーン",
            "agencies": "機関",
            "profiling": "プロファイリング",
            "holdings": "保有",
            "login": "ログイン",
            "users": "ユーザー",
            "booking": "予約",
            "logout": "ログアウト"
        }
    }
</i18n>-->


<template>
    <div>

        <v-bottom-navigation
                v-if="getIsUserLoggedIn &&  this.$router.history.current.name != 'Appointment'"
                bottom
                fixed
                class="grey lighten-4"
                grow
                color="primary"
        >


         <!--   <v-list-item to="/patients" link v-if="!isPatient">
                <v-list-item-action>
                    <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('menu.patients') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/appointments" link>
                <v-list-item-action>
                    <v-icon>mdi-checkbox-marked-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $tc('objects.appointment._', 2) }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>


            <v-list-item to="/profile" v-if="getIsUserLoggedIn" link>
                <v-list-item-action>
                    <v-icon>mdi-account-circle</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ getUserData ? getUserData.name : '' }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>


            <v-list-item v-if="getIsUserLoggedIn" link>
                <v-list-item-action>
                    <v-icon color="" class="" @click.stop="logout">mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <div class="text-left">
                        <v-btn depressed small class="" :class="{primary: $root.$i18n.locale == 'de'}"
                               @click="$root.$i18n.locale = 'de'">de
                        </v-btn>

                        <v-btn depressed small class="ml-3" :class="{primary: $root.$i18n.locale == 'en'}"
                               @click="$root.$i18n.locale = 'en'">en
                        </v-btn>

                    </div>
                </v-list-item-content>
            </v-list-item>-->





            <v-btn  :to="{name: 'Exhibitions'}" exact>
                <span>Exhibitions</span>
                <v-icon>mdi-star</v-icon>
            </v-btn>


            <v-btn  :to="{name: 'Days'}">
                <span>Days</span>
                <v-icon>mdi-calendar</v-icon>
            </v-btn>

           <!-- <v-btn v-if="isPatient" :to="{name: 'Doctors'}">
                &lt;!&ndash;                <span>{{ $t('menu.patients') }}</span>&ndash;&gt;
                <v-icon>mdi-account-multiple</v-icon>
            </v-btn>

            <v-btn  :to="{name: 'Appointments'}">
&lt;!&ndash;                <span>{{ $tc('objects.appointment._', 2) }}</span>&ndash;&gt;
                <v-icon>mdi-checkbox-marked-outline</v-icon>
            </v-btn>

            <v-btn  :to="{name: 'Messages'}">
&lt;!&ndash;                <span>{{ $tc('objects.appointment._', 2) }}</span>&ndash;&gt;

                <v-badge
                        color="blue"
                        v-if="getUnreadCount > 0"
                        top
                        overlap
                ><v-icon>mdi-forum</v-icon><template  v-slot:badge><span class="white&#45;&#45;text ">{{ getUnreadCount > 99 ? ':D' : getUnreadCount }}</span></template></v-badge>
                <v-icon v-else>mdi-forum</v-icon>
            </v-btn>-->

            <v-btn  v-if="getIsUserLoggedIn" :tos="{name: 'Profile'}">
                <span>Profile</span>
                <v-icon>mdi-account-circle</v-icon>
            </v-btn>
            <!--<v-btn v-if="getIsUserLoggedIn">
                <v-icon color="" class="" @click.stop="logout">mdi-logout</v-icon>
            </v-btn>-->



        </v-bottom-navigation>


    </div>


</template>

<style lang="scss" scoped>

</style>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex'
    // import Avatar from '@/components/avatar'
    import { SnotifyPosition } from 'vue-snotify'
    export default {
        name: 'navigation',
        components: {
            // Avatar
        },

        props: {
            hideTopBar: {type: Boolean, default: false},
        },

        data: function () {
            return {
                patientDetailsId: 0,
                currentTopFilter: 'patient',
                searchedPatient: null,
                searchedDoctor: null,
                dialogDoctorFilter: false,
                loading: false,
                langs: ['de', 'en'],
                cp: this.$router.history.current.path,
                locale: localStorage.getItem('user-locale') || 'de',
                dialogPatientDetails: false,
                drawer: false
            }
        },
        async created () {
            console.log('created nav')
            if (this.$router.history.current.name == 'Appointment') {
                await this.fetchUserProfile({byLink: this.$route.params.id  })
            }
            if (this.getIsUserLoggedIn) {
                if (this.isMultiDoctor && this.getFilterDoctorId) {
                    this.searchedDoctor = this.getFilterDoctorId
                }
                if (this.isMultiDoctor && this.getCurrentTopFilter) {
                    this.currentTopFilter = this.getCurrentTopFilter
                }
                if (this.$router.history.current.name != 'Appointment') {
                    await this.fetchUserProfile()
                }


                await this.fetchUnreadCount()
                if(this.getUnreadInfoRequestsCount > 0) {
                    if (this.$router.history.current.name != 'Messages') {
                        this.$snotify.html('<div class="mt-1 white--text"><div class="mb-1">' + this.$t('labels.warning') + '</div> You have <a class="white--text font-weight-medium" href="./messages?filter=info_request" target="_self">' + this.getUnreadInfoRequestsCount + ' Info Request' + (this.getUnreadInfoRequestsCount > 1 ? 's' : '') + '</a></div>',
                            {
                                toast: {
                                    position: SnotifyPosition.centerTop,
                                },
                                position: SnotifyPosition.centerTop,
                                timeout: 0,
                                showProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false
                            })
                    }
                }

            }


        },
        beforeMount () {
            //this.$vuetify.lang.current = this.$i18n.locale = this.locale
        },
        methods: {
            ...mapActions({
                postLogOut: 'auth/postLogOut',
                fetchUnreadCount: 'auth/fetchUnreadCount',
                fetchUserProfile: 'auth/fetchUserProfile'
            }),

            filterSearch(object, query) {
                if (!query || query.length < 2) {
                    return false
                }
                return ((object.last_name + ' ' + object.first_name)  || '').toLowerCase().indexOf((query || '').toLowerCase()) > -1
            },
            async logout () {
                await this.postLogOut()
                this.$router.push('/login')
            }
        },
        watch: {
            '$route': {
                handler () {
                    this.cp = this.$router.history.current.path
                }
            },
            '$i18n.locale' (val) {
                localStorage.setItem('user-locale', val)
                this.$vuetify.lang.current = val
            },
        },
        computed: {
            ...mapGetters({
                getIsUserLoggedIn: 'auth/getIsUserLoggedIn',
                getUnreadCount: 'auth/getUnreadCount',
                getUserProfile: 'auth/getUserProfile',
                getUserData: 'auth/getUserData'
            }),
            currentRouteName () {
                return this.$route.name
            }
        },
    }
</script>

<style>
    /*body .v-toolbar__content, .v-toolbar__extension {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: end;
        justify-content: flex-end !important;
        padding: 0px;
    }*/
</style>
