<template>
    <div class="example-avatar">
        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
            <h3>Drop files to upload</h3>
        </div>
        <div class="avatar-upload" style="position: relative"  v-show="!edit">
            <div class="">

                    <div class="avatarContainer">
                        <label style="cursor: pointer;" for="avatar">
                            <img v-if="files.length" :src="files[0].url"  class="rounded-circle mx-auto" />
                            <avatar v-else size="large" color="primary" class="primary"
                                    :name="user_name"></avatar>
                            <v-icon color="primary" v-if="!files.length" class="white pa-0" style="position: absolute; border-radius: 50%; right: 5px; bottom: 10px;">mdi-plus-circle</v-icon>
                        </label>
                    </div>
                <div style="width: 120px;" class="text-center mt-2">
                    <v-icon style="position: absolute; border-radius: 50%; right: 5px; bottom: 10px;" @click="deleteAvatarConfirm" :title="$t('labels.remove')" color="red" class="white pa-0" size="24" v-if="files.length">mdi-close-circle-outline</v-icon>
                    <!--<v-icon v-else style="visibility: hidden" size="24"></v-icon>-->
                </div>
            </div>
            <div class="text-center" style="height: 0px;">  <!--post-action="/upload/post" --> <!--:drop="!edit"-->
                <file-upload
                        extensions="gif,jpg,jpeg,png"
                        accept="image/png,image/gif,image/jpeg"
                        name="avatar"
                        class="btn btn-primary"
                        :drop="false"
                        v-model="files"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="upload">
                </file-upload>
            </div>

        </div>

        <v-dialog :value="files.length && edit" content-class="white dialogAvatar" max-width="600">
            <div class="avatar-edit"  v-show="files.length && edit">
                <div class="avatar-edit-image" style="max-height: 400px;" v-if="files.length">
                    <img ref="editImage" :src="files[0].url" />
                </div>
                <div class="text-center py-4" style="height: 68px;">
                    <v-icon @click.prevent="cancelEdit" color="grey" size="28" class="mr-6" >mdi-close</v-icon>
                    <v-icon color="green"  @click.prevent="editSave" size="28" >mdi-check</v-icon>
                </div>
            </div>
        </v-dialog>

    </div>
</template>
<style>
    .avatarContainer {
        width: 120px;
        height: 120px;
        font-size: 32px;
    }
    .example-avatar .avatar-upload .rounded-circle {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }




    .example-avatar .avatar-edit-image {
        max-width: 100%;
        overflow: scroll;
    }
    .example-avatar .drop-active {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: 9999;
        opacity: .6;
        text-align: center;
        background: #000;
    }
    .example-avatar .drop-active h3 {
        margin: -.5em 0 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 40px;
        color: #fff;
        padding: 0;
    }
</style>


<script>
    import 'cropperjs/dist/cropper.css';
    import { mapActions, mapGetters } from 'vuex'
    import Cropper from 'cropperjs'
    import FileUpload from 'vue-upload-component'
    import apiConfig from '../../static/apiConfig'
    import ApiService from '@/services/api.service'
    import Avatar from '@/components/avatar'
    export default {
        components: {
            FileUpload,
            Avatar
        },
        props: ['file', 'user_id', 'user_name'] ,
        data() {
            return {
                files: [],
                edit: false,
                cropper: false,
                file: null
            }
        },
        computed: {
            ...mapGetters({
                getErrors: 'user/getErrors',
            }),

        },
            watch: {
            edit(value) {
                if (value) {
                    this.$nextTick(function () {
                        if (!this.$refs.editImage) {
                            return
                        }
                        let cropper = new Cropper(this.$refs.editImage, {
                            aspectRatio: 1 / 1,
                            responsive: true,
                            viewMode: 1,
                        })
                        this.cropper = cropper
                    })
                } else {
                    if (this.cropper) {
                        this.cropper.destroy()
                        this.cropper = false
                    }
                }
            }
        },
        async mounted () {
            if (this.file) {
                this.files.push({
                    url: this.file
                })
            }
        },
        methods: {
            ...mapActions({
                deleteAvatar: 'user/deleteAvatar',
                clearErrors : 'user/clearErrors'
            }),
            cancelEdit() {
                this.$refs.upload.clear()
                if (this.file) {
                    this.files.push({
                        url: this.file
                    })
                }
            },
            async deleteAvatarConfirm() {

                const res = await this.$confirm(this.$t('messages.deleteObject', {object: 'аватар'}), { title: this.$t('labels.warning'), icon: '', color: '#f8495a', buttonTrueText: this.$t('labels.yes'), buttonFalseText: this.$t('labels.no') })
                if (!res) {
                    return false;
                }

                this.clearErrors()
                this.loading = true
                await this.deleteAvatar(
                    this.user_id
                )
                if (this.getErrors.length > 0) {
                    this.$root.$emit('error:show', this.getErrors[0])
                } else {
                    this.files = []
                    this.$emit('user:updated', this.user_id)
                }
            },
            async editSave() {
                this.edit = false
                let oldFile = this.files[0]
                let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
                let arr = new Uint8Array(binStr.length)
                for (let i = 0; i < binStr.length; i++) {
                    arr[i] = binStr.charCodeAt(i)
                }
                let file = new File([arr], oldFile.name, { type: oldFile.type })
                this.file = file


                if (file) {
                    let formData = new FormData();
                    /*
                        Add the form data we need to submit
                    */
                    console.log('file', file)
                    formData.append('files[]', file, file.name);
                    formData.append('category', 'avatar');

                    /*
                      Make the request to the POST /single-file URL
                    */
                    let r = await ApiService.post( apiConfig.url + '/file/save/user/' + this.user_id,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    );

                    if (r.data && r.data.error) {
                        this.$root.$emit('error:show', r.data.error)
                        this.clearChatErrors()
                        await this.deleteMessageServer({message_id: message._id})
                        if (this.getChatErrors.length > 0) {
                            this.$root.$emit('error:show', this.getChatErrors[0])
                        } else {
                            message.deleted =  new Date()
                        }


                    } else if (r.data && r.data.success && r.data.url) {
                        this.$refs.upload.update(oldFile.id, {
                            file,
                            type: file.type,
                            size: file.size,
                            active: true,
                        })
                        this.$emit('user:updated', this.user_id)
                    }
                }




            },
            alert(message) {
                alert(message)
            },
            inputFile(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    this.$nextTick(function () {
                        this.edit = true
                    })
                }
                if (!newFile && oldFile) {
                    this.edit = false
                }
            },
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
                        this.alert('Your choice is not a picture')
                        return prevent()
                    }
                }
                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                    newFile.url = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.url = URL.createObjectURL(newFile.file)
                    }
                }
            }
        }
    }
</script>
