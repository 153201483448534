// @vue/component
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import { mapActions, mapGetters } from 'vuex'
import QList from '@/components/common/exhibitions/c-list.vue'
import TasksList from '@/components/common/tasks/c-list.vue'
import QListItem from '@/components/common/exhibitions/c-list-item.vue'
import dateTimeMixin from '@/mixins/datetime'
import colorsMixin from '@/mixins/colors'
import cssMixin from '@/mixins/css'
import DoctorProfile from '@/components/doctor/profile.vue'
import { Timer } from 'easytimer.js';


export default {
    name: 'Tasks',

    components: {
        LayoutDefault,
        DoctorProfile,
        TasksList,
        QList,
        QListItem
    },

    mixins: [dateTimeMixin, colorsMixin, cssMixin],

    props: {},

    data () {
        return {
            rooms: [],
            filter: 'all',
            currentDay: 1,
            sessionClock: '',
            filterFlow: 'noPinned',
            filterSection: 'days',
            filterTabs: 0,
            errorMessage: '',
            waiting: false,
            loading: true,
            isCurrentExhibitionAccepted: false,
            editingProfileId: false,
            sessionTimer: false,
            roomsLoaded: false,
            sessionActive: true,
            currentExhibitionId: null,
            currentExhibition: null,
            timerStopwatch: null,
        }
    },

    computed: {
        ...mapGetters({
            getChatsList: 'chats/getChatsList',
            getExhibitionsList: 'exhibitions/getExhibitionsList',
            getUserTasks: 'exhibitions/getUserTasks',
            getErrors: 'exhibitions/getErrors',
            getStats: 'dnet/getStats',
            getUserData: 'auth/getUserData',
            getUserProfile: 'auth/getUserProfile',
        }),
        maxDays: function() {
            let days = 0
            if (this.getExhibitionsList && this.getExhibitionsList.length) {
                let acceptExhibitions = this.getExhibitionsList.filter(item => item.accepted)
                for (let i = 0; i < acceptExhibitions.length; i++) {
                    if (acceptExhibitions[i].duration > days) {
                        days = acceptExhibitions[i].duration
                    }
                }
            }
            return days
        },
        filteredExhibitions: function() {
          return this.rooms.filter(item => this.currentUserId == item.dnet_id && (this.filter == 'noreply' ? item.status == 'preparing' : true))

            /*return this.rooms.filter(item => this.currentUserId == item.dnet_id && (this.filter == 'closed' ? item.status == 'closed' : item.status != 'closed'))*/
        },


        openExhibitions: function() {
          return this.getExhibitionsList.filter(item => true)
        },
        currentUserId: function() {
            return this.patientId ? this.patientId : (this.getUserData ? this.getUserData.id + '' : '')
        },
        initials() {
            if (this.getUserData) {
                let names = this.getUserData.name.split(' ')
                return names[0].substring(0, 1) + (names.length > 1 ? names[1].substring(0, 1) : '')
            } else {
                return '—'
            }
        },

    },

    destroyed() {
        socket.off("connect_error");
        socket.off("private message");
    },

    watch: {},

    beforeDestroy() {
        this.clearTimeout()
    },


    async created () {
        //this.timerStopwatch = new Timer();
        await this.fetchExhibitionsList({})
        this.loading = false
        //await this.startSession(true)

        /*this.timerStopwatch.addEventListener('secondsUpdated',  (e) => {
            this.sessionClock = this.timerStopwatch.getTimeValues().toString()
            // $('#basicUsage').html(timer.getTimeValues().toString());
        });*/

        /*socket.auth = { username: this.getUserData.id };
        socket.connect();

        socket.on("private message", ({ content, from }) => {
            console.log('content on PM', content)
            this.$refs.chat.addMessageToChat(content.chat_id, content)
        });*/

        /*await this.fetchStats({
            own: true,
            period: 'all',
            begin: null,
            end: null,
        })*/
    },

    methods: {
        ...mapActions({
            fetchChatsList: 'chats/fetchChatsList',
            fetchExhibitionsList: 'exhibitions/fetchExhibitionsList',
            deleteChat: 'chats/deleteChat',
            clearErrors: 'exhibitions/clearErrors',
            nextDay: 'exhibitions/nextDay',
            acceptExhibition: 'exhibitions/acceptExhibition',
            checkSession: 'dnet/checkSession',
            finishSession: 'dnet/finishSession',
            fetchStats: 'dnet/fetchStats',
            closeChat: 'chats/closeChat',
            refuseChat: 'chats/refuseChat',
        }),

        async messageBroadcast(content) {
            console.log('messageBroadcast', content)
            socket.emit("private message", {
                content,
                to: content.recipientId,
            });
        },

        async nextDayAndUpdate() {
            await this.nextDay()
            this.$router.go()
        },

        async onAnswered() {
            let room = this.rooms.find(r => r.id == this.currentChatId)
            if (room && room.status == 'preparing' || room.status == 'closed') {
                await this.loadRooms()
            }
        },

        async onExhibitionSelected(data) {
            console.log('datadata', data)
            this.currentExhibitionId = data.id;
            this.currentExhibition = data;
            this.isCurrentExhibitionAccepted = data.accepted
        },

        async startSession(check) {
            let session = await this.checkSession(check)
            if (session.found) {
                this.sessionActive = session
                this.clearTimeout()
                this.timerStopwatch.start({
                    startValues: {
                        seconds: session.duration
                    }
                })
                this.sessionTimeout = setTimeout(() => {
                    this.startSession(true)
                }, session.left * 1000 + 1000)
            } else {
                this.clearTimeout()
                this.sessionActive = false
                this.sessionClock = ''
                this.timerStopwatch.reset()
                this.timerStopwatch.stop()
            }
        },

        async finishSessionConfirm() {
            await this.finishSession()
            this.clearTimeout()
            this.sessionActive = false
            this.sessionClock = ''
            this.timerStopwatch.reset()
            this.timerStopwatch.stop()
            await this.fetchStats({
                own: true,
                period: 'all',
                begin: null,
                end: null,
            })
        },

        clearTimeout() {
            if (this.sessionTimer) {
                clearTimeout(this.sessionTimer)
                this.sessionTimer = null
            }
        },

        async loadRooms() {
            await this.fetchChatsList({code: this.$route.params.code})
            const rooms = []
            for (let i = 0; i < this.getChatsList.length; i++) {
                rooms.push(this.getChatsList[i])
            }
            this.rooms = rooms
            this.roomsLoaded = true
        },

        deleteChatConfirm: async function(chatId) {
            const res = await this.$confirm('Delete this exhibition?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
            if (res) {
                await this.deleteChat(chatId)
                await this.loadRooms()

            }
        },

        closeChatConfirm: async function(chatId) {
            const res = await this.$confirm('Close this exhibition?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
            if (res) {
                await this.closeChat({chat_id: chatId})
                await this.loadRooms()

            }
        },

        acceptExhibitionConfirm: async function(exhibitionId) {
            const res = await this.$confirm('Accept this exhibition?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
            if (res) {
                await this.clearErrors()
                await this.acceptExhibition({exhibition_id: exhibitionId})
                if (this.getErrors.length > 0) {
                    this.$root.$emit('error:show', this.getErrors[0])
                } else {
                    await this.fetchExhibitionsList({})
                }

            }
        },

        refuseChatConfirm: async function(chatId) {
            const res = await this.$confirm('Refuse this exhibition?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
            if (res) {
                await this.refuseChat(chatId)

                if (this.getErrors.length > 0) {
                    this.$root.$emit('error:show', this.getErrors[0])
                } else {
                    await this.loadRooms()
                }





            }
        },

    }
}
