<template>
    <v-avatar :color="img ? null : color"  :size="size == 'small' ? 40 : (size == 'large' ? 120 : 60)">
        <v-img
                v-if="img && img.substr(0,2) != '//' && img.substr(0,4) != 'http'"
                :src="require('@/assets/' + img)"
        ></v-img>
        <v-img
                v-else-if="img"
                :src="img"
        ></v-img>
        <span v-else :class="textColor + '--text'" class=" header text-uppercase">{{ name.substr(0, 1) }}</span>
    </v-avatar>
</template>
<script>
  export default {
    name: 'Home',
    props: {
      img: {
        type: String,
        required: false,
        default: null
      },
      size: {
        type: String,
        required: false,
        default: 'small'
      },
       color: {
        type: String,
        required: false,
        default: 'primary'
      },
        textColor: {
        type: String,
        required: false,
        default: 'white'
      },
      name: {
        type: String,
        required: false,
        default: null
      }
    }
  }
</script>
