const messages = {
  en: {

    $vuetify: {
      dataTable: {
        rowsPerPageText: 'Rows per page:',
        itemsPerPageText: 'Rows per page:',
        pageText: '{0}-{1} of {2}',
      },
      dataFooter: {
        pageText: '{0}-{1} of {2}',
      },
      dataIterator: {
        rowsPerPageText: 'Rows per page:',
        pageText: '{0}-{1} of {2}',
      },
    },

    menu: {
      calendar: 'Calendar',
      patients: 'Patients',
      specialties: 'Specialties',
      mPoints: 'M Points',
      medicalPoints: 'Medical Points',
      administrators: 'Administrators',
      doctors: 'Doctors',
      physicians: 'Physicians',
      assistants: 'Assistants',
      dictionaries: 'Dictionaries',
      services: 'Services',
    },

    objects: {
      appointment:  { // object and his properties
        _: 'no appointments | appointment | Appointments', // Pluralization. You must define the locale that have a pipe | separator, and define plurals in pipe separator (0 things | 1 thing | multiple things).  The number can be accessed within locale messages via pre-defined named arguments {count} and/or {n}.
        patient: 'Patient',
        note: 'Short Note',
        reason: 'Reason',
        newPatient: 'New Patient',
        date: 'Date',
        phone: 'Phone',
        email: 'Email',
        duration: 'Duration',
        doctor: 'Doctor',
        service: 'Treatment',
        time: 'Time',
        confirmed: 'confirmed',
        unconfirmed: 'unconfirmed',
        chat: 'Chat',
        video: 'Video',
        lastAppointment: 'Last Appointment',
        upcomingAppointment: 'Upcoming Appointment',
      },

      doctor:  { // object and his properties
        _: 'doctor | Doctors', // Pluralization. You must define the locale that have a pipe | separator, and define plurals in pipe separator (0 things | 1 thing | multiple things).  The number can be accessed within locale messages via pre-defined named arguments {count} and/or {n}.
        firstName: 'First name',
        lastName: 'Last name',
        assignTreatments: 'Assign Treatments',
        setSchedule: 'Set Schedule',
        mPoint: 'M point',
        specialty: 'Specialty',
        birthday: 'Date of birth',
        phone: 'Phone',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm password',
      },

      assistant:  { // object and his properties
        _: 'assistant | Assistants', // Pluralization. You must define the locale that have a pipe | separator, and define plurals in pipe separator (0 things | 1 thing | multiple things).  The number can be accessed within locale messages via pre-defined named arguments {count} and/or {n}.
        firstName: 'First name',
        lastName: 'Last name',
        mPoint: 'M point',
        birthday: 'Date of birth',
        phone: 'Phone',
        doctors: 'Doctors',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm password',
      },

      patient:  { // object and his properties
        _: 'patient | Patients', // Pluralization. You must define the locale that have a pipe | separator, and define plurals in pipe separator (0 things | 1 thing | multiple things).  The number can be accessed within locale messages via pre-defined named arguments {count} and/or {n}.
        firstName: 'First name',
        lastName: 'Last name',
        relationship: 'Relationship',
        mPoint: 'M point',
        birthday: 'Date of birth',
        phone: 'Phone',
        country: 'Country',
        city: 'City',
        address: 'Address',
        zip: 'Zip code',
        weight: 'Weight',
        height: 'Height',
        bloodGroup: 'Blood Group',
        doctors: 'Attending physician',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm password',
      },

      administrator:  { // object and his properties
        _: 'administrator | administrators', // Pluralization. You must define the locale that have a pipe | separator, and define plurals in pipe separator (0 things | 1 thing | multiple things).  The number can be accessed within locale messages via pre-defined named arguments {count} and/or {n}.
        firstName: 'First name',
        lastName: 'Last name',
        mPoint: 'M point',
        birthday: 'Date of birth',
        phone: 'Phone',
        email: 'Email',
        password: 'Password',
        confirmPassword: 'Confirm password',
      },

      mPoint: {
        _: 'Medical Point | Medical Points', // _ for full object's name
        _short: 'M point | M points', // // _short for short object's name
        name: 'Name',
        shortName: 'Short Name',
        type: 'Typ',
      },
      vitalSigns: {
        _: 'vital sign | vital signs',
        temperature: 'Temperature',
        weight: 'Weight',
        bloodPressure: 'Blood pressure',
        pulse: 'Pulse',
        respRate: 'Resp. rate',
      },
      complaint: {
        _: 'complaint | complaints',
      },

      medication: {
        _: 'medication | medication',
      },

      diagnosis: {
        _: 'diagnosis | Diagnoses',
        summary: 'Summary',
        description: 'Description'
      },

      note: { // new
        _: 'Note | Notes',
      },

      file: {
        _: 'File | Files',
        name: 'Name',
        type: 'Type',
        size: 'Size',
        status: 'Status',
        width: 'Width',
        height: 'Height',
      },

      service: {
        _: 'Service | Services',
        service: 'Treatment',
        online: 'Online',
        name: 'Name',
        price: 'Price',
        defaultDuration: 'Default Appointment Duration',
        duration: 'Duration'
      },
      specialty: {
        _: 'Specialty | Specialties',
        name: 'Name',
      },
      session: {
        _: 'session | sessions',
        startTime: 'Start Time',
        endTime: 'End Time'
      }
    },

    values: {
      mPointTypes: {
        practice: 'practice',
        clinic: 'clinic'
      },

      reasons: {
        break: 'break',
        meeting: 'meeting',
        conference: 'conference',
        other: 'other'
      },

      userRelations: {
        husband_wife: 'Husband/Wife',
        son_daughter: 'Son/Daughter',
        brother_sister: 'Brother/Sister',
        grandpa_grandma: 'Grandpa/Grandma',
        uncle_aunt: 'Uncle/Aunt',
      },

      fileTypes: {
        common: 'common',
        personal: 'personal',
        results: 'results',
      },

      weekdays: {
        'Monday': 'Monday',
        'Tuesday': 'Tuesday',
        'Wednesday': 'Wednesday',
        'Thursday': 'Thursday',
        'Friday': 'Friday',
        'Saturday': 'Saturday',
        'Sunday': 'Sunday'
      },

      appointmentStatuses: {
        'default': 'default',
        waiting: 'waiting',
        active: 'active',
        closed: 'closed'
      },

      appointmentStates: { // new
        allStates: 'Status',
        unconfirmed: 'unconfirmed',
        confirmed: 'confirmed',
        all: 'Type',
        offline: 'Offline',
        online: 'Online'
      },

      patientProfile: { // new
        honorific: 'Title',
        mrs: 'Mrs',
        mr: 'Mr',
        insurance: 'Insurance',
        private: 'private',
        public: 'Public',
        selfPayer: 'Self-Payer',
      },

      specialties: {
        'Aesthetic medicine': 'Aesthetic medicine',
        'Internal medicine': 'Internal medicine',
        'Cardiology': 'Cardiology',
        'Dermatology': 'Dermatology',
        'Gastroenterology': 'Gastroenterology',
        'Immunology': 'Immunology',
        'Neurophysiology': 'Neurophysiology',
        'Ophthalmology': 'Ophthalmology',
        'Orthodontics': 'Orthodontics',
        'Psychology': 'Psychology',
        'Neurosurgery': 'Neurosurgery',
        'Endocrine neurosurgery': 'Endocrine neurosurgery',
        'Pediatric neurosurgery': 'Pediatric neurosurgery',
        'Vascular neurosurgery': 'Vascular neurosurgery',
        'Neurology': 'Neurology',
        'Orthopedics': 'Orthopedics',
        'Anesthesia': 'Anesthesia',
        'Endovascular neurosurgery': 'Endovascular neurosurgery',
        'Plastic and aesthetic surgery': 'Plastic and aesthetic surgery',
        'Otorhinolaryngology': 'Otorhinolaryngology',
      },

      services: {
        'sonography': 'sonography',
        'anamnesis': 'anamnesis',
        'clinical examination': 'clinical examination',
        'blooddrawing': 'blooddrawing',
        'clinical assessment': 'clinical assessment',
        'phone consultation': 'phone consultation',
        'long consultation': 'long consultation',
        'fmri': 'fmri',
        'initial consultation': 'initial consultation',
        'follow-up check': 'follow-up check',
        'stms stimulation therapy consultation': 'stms stimulation therapy consultation',
        'stms stimulation therapy initial consultation': 'stms stimulation therapy initial consultation',

        'private consultation': 'private consultation',
        'final discussion after surgery': 'final discussion after surgery',
        'clinical assessment spinal': 'clinical assessment spinal',
        'clinical assessment cranial': 'clinical assessment cranial',
        'new patient': 'new patient',
        'doppler sonography': 'doppler sonography',
        'mrt consultation': 'mrt consultation',
        'stimulation therapy': 'stimulation therapy',
        'botox therapy': 'botox therapy',

        'consultation': 'consultation',
        'decoding of analyses': 'decoding of analyses',

        'electroencephalography': 'electroencephalography',
        'radiology': 'radiology',

        'surcharge for dig radiography for 5010 - 5290': 'surcharge for dig. radiography for 5010 - 5290',
        'skull 2 levels': 'skull 2 levels',
        'special project (Schuller, Stenvers, Rhese, Shuntziel) per part': 'special project. (Schüller, Stenvers, Rhese, Shuntziel) per part',
        'paranasal sinuses, possibly several levels': 'paranasal sinuses, possibly several levels',
        'cervical spine 2 levels': 'cervical spine 2 levels',
        'cervical spine oblique, complementary plane': 'cervical spine oblique, complementary plane',
        'cervical spine function (analogue to cervical spine 2 levels)': 'cervical spine function (analogue to cervical spine 2 levels)',
        'thoracic spine 2 levels': 'thoracic spine 2 levels',
        'lumbar spine 2 levels': 'lumbar spine 2 levels',
        'lumbar spine oblique, complementary plane': 'lumbar spine oblique, complementary plane',
        'lumbar spine function (similar to lumbar spine 2 levels)': 'lumbar spine function (similar to lumbar spine 2 levels)',
        'thorax 1 level': 'thorax 1 level',
        'thorax 2 or more levels': 'thorax 2 or more levels',
        'abdomen 1 level': 'abdomen 1 level',
        'pelvis': 'pelvis',
        'extremities 2 levels (wrist, upper ankle)': 'extremities 2 levels (wrist, upper ankle)',
        'limb detail 1 level complementary to 5020 (Patella)': 'limb detail 1 level complementary to 5020 (Patella)',
        'extremities 2 levels (hand, hip, knee, foot, shoulder, clavicle, elbow)': 'extremities 2 levels (hand, hip, knee, foot, shoulder, clavicle, elbow)',
        'complementary level (Lauenstein, Rosenberg, Radius head)': 'complementary level (Lauenstein, Rosenberg, Radius head)',
        'full x-ray of the spine / legs': 'full x-ray of the spine / legs',
        'skeletal image 1 level': 'skeletal image 1 level',
        'detailed findings report': 'detailed findings report',
        'skull MRT': 'skull MRT',
        'functional MRT (analogous to PET)': 'functional MRT (analogous to PET)',
        'cervical spine MRT': 'cervical spine MRT',
        'thoracic spine MRT': 'thoracic spine MRT',
        'lumbar spine MRT': 'lumbar spine MRT',
        'joint MRT': 'joint MRT',
        'maximum value for 5700-5730 (and 5731, 5733)': 'maximum value for 5700-5730 (and 5731, 5733)',
        'complementary series': 'complementary series',
        'position/coil change': 'position/coil change',
        'image Analysis (MR-A, MPR)': 'image Analysis (MR-A, MPR)',
        'intravenous contrast agent by hand': 'intravenous contrast agent by hand',
        'intravenous contrast agent using high pressure': 'intravenous contrast agent using high pressure',
        'introduction of NACL': 'introduction of NACL',
        'in-depth advice': 'in-depth advice',
        'conciliar discussion': 'conciliar discussion',
        'skull CT': 'skull CT',
        'CT-A': 'CT-A',
        'cervical spine - intervertebral space- CT': 'cervical spine - intervertebral space- CT',
        'thoracic spine - intervertebral space- CT': 'thoracic spine - intervertebral space- CT',
        'lumbar spine - intervertebral space- CT': 'lumbar spine - intervertebral space- CT',
        'post-Myelo CT': 'post-Myelo CT',
        'neck/ thorax CT': 'neck/ thorax CT',
        'abdominal CT': 'abdominal CT',
        'skeleton CT': 'skeleton CT',
        'osteodensitometry CT': 'osteodensitometry CT',
        'maximum value for 5370-5374': 'maximum value for 5370-5374',
        'complementary series (KM / CT-A)': 'complementary series (e.g. KM / CT-A)',
        'image Analysis (Bones - MPR - SSD)': 'image Analysis (Bones - MPR - SSD)',
        'irradiation planning/intervention/stereotaxy': 'irradiation planning/intervention/stereotaxy',
        'intravenous contrast medium infusion': 'intravenous contrast medium infusion',
        'intravenous contrast medium high-pressure injector': 'intravenous contrast medium high-pressure injector',
        'further KM administration iv': 'further KM administration i.v.',

        'PT in combination (ice/heat-therapy)': 'PT in combination (ice/heat-therapy)',
        'neurological PT Bobath': 'neurological PT Bobath',
        'neurological PT/PNF': 'neurological PT/PNF',
        'manual therapy': 'manual therapy',
        'PT weight supported': 'PT weight supported',
        'manual lymph drainage': 'manual lymph drainage',
        'electrotherapy': 'electrotherapy',
        'movement rail': 'movement rail',
        'massage therapy in combination with heat/ice': 'massage therapy in combination with heat/ice',
        'occupational therapy': 'occupational therapy',
      }
    },

    units: {
      minute: 'minute | minutes',
      minuteShort: 'min.',
      years: 'Years',
      months: 'Months',
      weight: 'kg',
      pulse: 'h.b./min',
      breaths: 'breaths/min',
      tab: 'mg pro Tablette',
    },

    honorics: {
      'Dr.': 'Dr.',
      'Mr.': 'Mr.',
      'Mrs.': 'Mrs.',
      'Miss': 'Miss',
      'Ms.': 'Ms.'
    },

    messages: {
      notSpecified: 'Not specified',
      copyLink: 'The link was copied',
      expiresAt: 'Your invite link expires at',
      notFound: '{object} not found', // Can use any named arguments
      atLeast: 'At least {chars} characters',
      changeStatus: 'Do you really want to change status of this appointment to {status}?',
      deleteObject: 'Вы действительно хотите удалить данный {object}?',
      confirmAppointment: 'Do you really want to confirm this appointment?',
      startAppointment: 'Do you really want to start this appointment?',
      finishAppointment: 'Do you really want to finish this appointment?',
    },

    tutorials: {
      appointment: {
        addTitle: 'Add an appointment',
        addText: 'Try to add new appointment',
        addTask: 'Click on the calendar',
        detailsTitle: 'Appointment Details',
        detailsText: 'Why don\'t you try entering the appointment\'s data',
        detailsTask: 'Fill out the appointment form',
        detailsSubtask: 'Click NEXT',
        saveTitle: 'Save Appointment',
        saveText: 'Now you are ready to create the appointment',
        saveTask: 'Click SAVE',
        addedTitle: 'Appointment added',
        addedText: 'Congratulations! You have successfully added an appointment',
        addedTask: 'Click NEXT',
        rescheduleTitle: 'Reschedule appointment',
        rescheduleText: 'Patient won\'t be able to make it to his appointment. Let\'s reschedule his appointment to another time by editing it.',
        rescheduleTask: 'Click to Appointment',
        rescheduleTitle2: 'Reschedule appointment',
        rescheduleText2: 'You should edit Appointment to reschedule it',
        rescheduletTask2: 'Click on edit icon',
        rescheduleTitle3: 'Reschedule appointment',
        rescheduleText3: 'Change Appointment time',
        rescheduleTask3: 'Click NEXT',
        rescheduleTitle4: 'Reschedule appointment',
        rescheduleText4: 'Now you are ready to save new appointment\'s time',
        rescheduleTask4: 'Click SAVE',
        updatedTitle: 'Appointment updated',
        updatedTask: 'Congratulations! You have successfully updated an appointment',
        tutorialFinishTitle: 'Awesome!',
        tutorialFinishText: 'You\'ve just learnt how to create and reschedule appointments',
      },

      appointmentStatus: {
        'title': 'Appointment\'s status',
        'text': 'Try to change status of appointment',
        'task': 'Click on the appointment',
        'subtask': 'Click Yes in the confirmation window',
        'title2': 'Appointment\'s status',
        'text2': 'Congratulations! You have successfully changed status of appointment',
        'finishTitle': 'Awesome!',
        'finishText': 'You\'ve just learnt how to create and reschedule appointments',
      },

      patient: {
        'addTitle': 'Add a patient',
        'addText': 'Try to add new patient',
        'addTask': 'Click on the plus',
        'detailsTitle': 'Patient details',
        'detailsText': 'Why don\'t you try entering the patient\'s data',
        'detailsTask': 'Fill out patient form',
        'detailsSubtask': 'Click NEXT',
        'saveTitle': 'Save patient',
        'saveText': 'Now you are ready to create the patient',
        'saveTask': 'Click Save',
        'addedTitle': 'Patient added',
        'addedText': 'Congratulations! You have successfully added a patient',
        'addedTask': 'Click NEXT',
        'editTitle': 'Edit patient',
        'editText': 'Patient\'s contact information has changed. Let\'s update his profile by editing it.',
        'editTask': 'Click to edit icon',
        'editTitle2': 'Edit patient',
        'editText2': 'Change patient\'s contact data',
        'editTask2': 'Click NEXT',
        'editTitle3': 'Edit patient',
        'editText3': 'Now you are ready to save changed patient\'s data',
        'editTask3': 'Click Save',
        'updatedTitle': 'Patient updated',
        'updatedText': 'Congratulations! You have successfully updated a patient',
        'finishTitle': 'Awesome!',
        'finishText': 'You\'ve just learnt how to create and edit patients',
      },


      admin: {
        'addTitle': 'Add an administrator',
        'addText': 'Try to add new administrator',
        'addTask': 'Click on the plus',
        'detailsTitle': 'Administrator details',
        'detailsText': 'Why don\'t you try entering the administrator\'s data',
        'detailsTask': 'Fill out administrator form',
        'detailsSubtask': 'Click NEXT',
        'saveTitle': 'Save administrator',
        'saveText': 'Now you are ready to create the administrator',
        'saveTask': 'Click Save',
        'addedTitle': 'Administrator added',
        'addedText': 'Congratulations! You have successfully added a administrator',
        'addedTask': 'Click NEXT',
        'editTitle': 'Edit administrator',
        'editText': 'Administrator\'s contact information has changed. Let\'s update his profile by editing it.',
        'editTask': 'Click to edit icon',
        'editTitle2': 'Edit administrator',
        'editText2': 'Change administrator\'s contact data',
        'editTask2': 'Click NEXT',
        'editTitle3': 'Edit administrator',
        'editText3': 'Now you are ready to save changed administrator\'s data',
        'editTask3': 'Click Save',
        'updatedTitle': 'Administrator updated',
        'updatedText': 'Congratulations! You have successfully updated a administrator',
        'finishTitle': 'Awesome!',
        'finishText': 'You\'ve just learnt how to create and edit administrators',
      },

      assistant: {
        'addTitle': 'Add an assistant',
        'addText': 'Try to add new assistant',
        'addTask': 'Click on the plus',
        'detailsTitle': 'Assistant details',
        'detailsText': 'Why don\'t you try entering the assistant\'s data',
        'detailsTask': 'Fill out assistant form',
        'detailsSubtask': 'Click NEXT',
        'saveTitle': 'Save assistant',
        'saveText': 'Now you are ready to create the assistant',
        'saveTask': 'Click Save',
        'addedTitle': 'Assistant added',
        'addedText': 'Congratulations! You have successfully added a assistant',
        'addedTask': 'Click NEXT',
        'editTitle': 'Edit assistant',
        'editText': 'Assistant\'s contact information has changed. Let\'s update his profile by editing it.',
        'editTask': 'Click to edit icon',
        'editTitle2': 'Edit assistant',
        'editText2': 'Change assistant\'s contact data',
        'editTask2': 'Click NEXT',
        'editTitle3': 'Edit assistant',
        'editText3': 'Now you are ready to save changed assistant\'s data',
        'editTask3': 'Click Save',
        'updatedTitle': 'Assistant updated',
        'updatedText': 'Congratulations! You have successfully updated a assistant',
        'finishTitle': 'Awesome!',
        'finishText': 'You\'ve just learnt how to create and edit assistants',
      },

      doctor: {
        'addTitle': 'Add a doctor',
        'addText': 'Try to add new doctor',
        'addTask': 'Click on the plus',
        'detailsTitle': 'Doctor details',
        'detailsText': 'Why don\'t you try entering the doctor\'s data',
        'detailsTask': 'Fill out doctor form',
        'detailsSubtask': 'Click NEXT',
        'saveTitle': 'Save doctor',
        'saveText': 'Now you are ready to create the doctor',
        'saveTask': 'Click Save',
        'addedTitle': 'Doctor added',
        'addedText': 'Congratulations! You have successfully added a doctor',
        'addedTask': 'Click NEXT',
        'editTitle': 'Edit doctor',
        'editText': 'Doctor\'s contact information has changed. Let\'s update his profile by editing it.',
        'editTask': 'Click to edit icon',
        'editTitle2': 'Edit doctor',
        'editText2': 'Change doctor\'s contact data',
        'editTask2': 'Click NEXT',
        'editTitle3': 'Edit doctor',
        'editText3': 'Now you are ready to save changed doctor\'s data',
        'editTask3': 'Click Save',
        'updatedTitle': 'Doctor updated',
        'updatedText': 'Congratulations! You have successfully updated a doctor',
        'finishTitle': 'Awesome!',
        'finishText': 'You\'ve just learnt how to create and edit doctors',
      },

    },

    labels: { // buttons, filters, placeholders and tooltips
      export: 'Export',
      save: 'Save',
      canceled: 'canceled',
      request: 'request',
      confirmed: 'confirmed',
      'new': 'New',
      expired: 'expired',
      cancel: 'Cancel',
      stop: 'stop',
      block: 'block',
      notAvailable: 'not available',
      change: 'Change',
      itemsPerPageText: 'Items per page:',
      next: 'Next',
      avatar: 'avatar',
      invite: 'Invite',
      online: 'Online',
      link: 'Link',
      offline: 'Offline',
      search: 'Search',
      warning: 'Внимание',
      active: 'active',
      abort: 'abort',
      join: 'Join',
      all: 'All',
      close: 'Close',
      'with': 'with',
      confirm: 'Confirm',
      edit: 'Edit',
      editProfile: 'Edit Profile',
      remove: 'Remove',
      success: 'Success',
      of: 'of',
      from: 'from',
      back: 'Back',
      finish: 'Finish',
      exit: 'Exit',
      ok: 'Ok',
      yes: 'Да',
      no: 'Нет',
      type: 'Type',
      print: 'Print',
      message: 'Message',
      share: 'Share',
      tutorial: 'Tutorial',
      selectTutorial: 'Select Tutorial',
      searchPatients: 'Search Patients',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      custom: 'Custom',

      auth: {
        signInToContinue: 'Sign in to continue',
        login: 'Login',
        password: 'Password',
        signIn: 'Sign in',
      },

      appointments: {
        '_': 'APPOINTMENTS',
        addAppointment: 'Create Appointment',
        addNote: 'Add note',
        allAppointments: 'All appointments',
        editAppointment: 'Edit Appointment',
        notSpecified: 'Not specified',
        lastDiagnosis: 'last diagnosis',
        copyToClipboard: 'Copy to clipboard',
        tutorialAppointmentShort: 'Appointment',
        tutorialAppointment: 'Learn how to create and edit an appointment',
        tutorialStatusShort: 'Appointment\'s status',
        tutorialStatus: 'Learn how to change appointment\'s status',
        today: 'Today',
        visits: 'today\'s Visits',
        day: 'Day',
        week: 'Week',
        '7 days': '7 Days',
        month: 'Month',
        mPoint: 'M POINT',
        title: 'Title',
        message: 'Message',
        writeMessage: 'Write message here',
        sendEmail: 'Send Email',

        // chat and video new
        patientIsOnline: 'Patient is Online',
        isOnline: 'is Online',
        patientLastSeenADayAgo: 'patient last seen a day ago',
        lastSeen: 'last seen ',
        noMessage: 'No message',
        typeMessage: 'Type message',
        convStartedOn: 'Conversation started on',
        startAppointment: 'Start Appointment',
        finishAppointment: 'Finish Appointment',
        notes: 'Notes',
        note: 'Note',
        add: 'Add',
        waitingForPatient: 'Waiting For Patient',

      },

      patients: {
        addPatient: 'Add Patient',
        addContact: 'Add family member',
        editPatient: 'Edit Patient',
        lastDiagnosis: 'last diagnosis',
        today: 'Today',
        recent: 'Recent',
        upcoming: 'Upcoming',
        appointments: 'APPOINTMENTS',
        patientsInfo: 'PATIENT\'S INFO',
        medicalPoints: 'MEDICAL POINTS',
        patientPoints: 'PATIENT POINTS',
        //addInfo: 'Add Information',
        addInfo: 'Summary',
        appointmentWith: 'Appointment with',
        'for': 'for',
        selectFiles: 'Select Files',
        startUpload: 'Start Upload',
        stopUpload: 'Stop Upload',
      },

      doctors: {
        addDoctor: 'Add Doctor',
        editDoctor: 'Edit Doctor',
        editServices: 'Edit Treatments' // new
      },

      administrators: {
        addAdministrator: 'Add Administrator',
        editAdministrator: 'Edit Administrator',
        administrators: 'ADMINISTRATORS' // new
      },

      assistants: {
        addAssistant: 'Add Assistant',
        editAssistant: 'Edit Assistant'
      },

      services: {
        addService: 'Add Service',
        editService: 'Edit Treatment',
        editServices: 'Edit Services'
      },

      schedule: {
        editSchedule: 'Edit Schedule',
        defaultDuration: 'Time slot size',
        practiceTimings: 'Opening Times',
        slotsCustomization: 'Your Calendar\'s appointment slots will also be customised for the same duration',
        specifyDays: 'Specify the days you\'ll be practising',
        addSession: 'add session',
        addTimings: 'Add working pattern',
      },

      mPoints: {
        defaultDuration: 'Time slot size',
        addMPoint: 'Add M Point',
        mPoints: 'MEDICAL POINTS',
        editMPoint: 'Edit M Point',
      },


    },



    errors: {
      common: {
        required: '{object} is required',
        thisFieldIsRequired: 'This field is required',
        wrongEmail: 'E-mail must be valid',
        permission: 'You have not permissions for this action',
        duplicate: 'This {object} has already been added',
        noData: 'No data available',
      },

      auth: {
        wrongCredentials: 'Wrong login or password',
        emailInUse: 'Email is already in use',
        loginInUse: 'Login is already taken'
      },

      schedule: {
        doctorScheduleExceed: 'The doctor\'s schedule should not exceed the M point\'s schedule',
        wrongDay: 'Wrong day format',
        daysIntersect: 'Days should not intersect in the timings',
        timeIntersect: 'Time should not intersect in the sessions',
        shortSessionDuration: 'The session duration cannot be less than the calendar step'
      },

      service: {
        deleteServiceWithAppointment: 'You can\'t delete a service that has an appointment linked to it'
      },

      file: {
        maxSize: 'The maximum size of file - {n} Mb',
        upload: 'Upload file error',
        format: 'Wrong file format'
      }
   }
  },
  de: {

    $vuetify: {
      dataTable: {
        rowsPerPageText: 'Zeilen pro Seite1:',
        itemsPerPageText: 'Zeilen pro Seite2:',
        pageText: '{0}-{1} von {2}',
      },
      dataFooter: {
        pageText: '{0}-{1} von {2}',
      },
      dataIterator: {
        rowsPerPageText: 'Zeilen pro Seite3:',
        pageText: '{0}-{1} von {2}',
      },
    },

    menu: {
      calendar: 'Kalender',
      patients: 'Patienten',
      specialties: 'Spezialität',
      mPoints: 'M-Punkte',
      medicalPoints: 'Medizinischen Punkte',
      administrators: 'Administratoren',
      doctors: 'Ärzte',
      physicians: 'Ärzte',
      assistants: 'Assistenten',
      dictionaries: 'Wörterbücher',
      services: 'Behandlungen',
    },

    objects: {
      appointment: { // object and his properties
        _: 'Termin | Termine',
        patient: 'Patient',
        note: 'Kurz Notiz',
        newPatient: 'Neu Patient',
        reason: 'Grund',
        date: 'Datum',
        phone: 'Telefon',
        email: 'E-Mail',
        duration: 'Termindauer',
        doctor: 'Arzt',
        service: 'Behandlung',
        time: 'Zeit',
        confirmed: 'bestätigt', // new ↓ (alt+25)
        unconfirmed: 'unbestätigt',
        chat: 'Chat',
        video: 'Video',
        lastAppointment: 'Letzter Termin',
        upcomingAppointment: 'Bevorstehender Termin',
      },

      doctor:  { // object and his properties
        _: 'Arzt | Ärzte', // Pluralization. You must define the locale that have a pipe | separator, and define plurals in pipe separator (0 things | 1 thing | multiple things).  The number can be accessed within locale messages via pre-defined named arguments {count} and/or {n}.
        firstName: 'Vorname',
        lastName: 'Nachname',
        mPoint: 'M-Punkt',
        assignTreatments: 'Behandlungen',
        setSchedule: 'Zeiten bearbeiten',
        specialty: 'Fachgebiet',
        birthday: 'Geburtsdatum',
        phone: 'Telefon',
        email: 'E-Mail',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
      },

      assistant:  { // object and his properties
        _: 'Assistent | Assistenten', // Pluralization. You must define the locale that have a pipe | separator, and define plurals in pipe separator (0 things | 1 thing | multiple things).  The number can be accessed within locale messages via pre-defined named arguments {count} and/or {n}.
        firstName: 'Vorname',
        lastName: 'Nachname',
        mPoint: 'M-Punkt',
        doctors: 'Ärzte',
        birthday: 'Geburtsdatum',
        phone: 'Telefon',
        email: 'E-Mail',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
      },

      patient:  { // object and his properties
        _: 'Patient | Patienten', // Pluralization. You must define the locale that have a pipe | separator, and define plurals in pipe separator (0 things | 1 thing | multiple things).  The number can be accessed within locale messages via pre-defined named arguments {count} and/or {n}.
        firstName: 'Vorname',
        lastName: 'Nachname',
        mPoint: 'M-Punkt',
        doctors: 'Ärzte',
        relationship: 'Beziehung',
        birthday: 'Geburtsdatum',
        phone: 'Telefon',
        zip: 'Postleitzahl',
        address: 'Strassenadresse',
        city: 'Stadt',
        country: 'Land',
        email: 'E-Mail',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
        weight: 'Gewicht',
        height: 'Größe',
        bloodGroup: 'Blutgruppe',
      },

      administrator:  { // object and his properties
        _: 'Administrator | Administratoren', // Pluralization. You must define the locale that have a pipe | separator, and define plurals in pipe separator (0 things | 1 thing | multiple things).  The number can be accessed within locale messages via pre-defined named arguments {count} and/or {n}.
        firstName: 'Vorname',
        lastName: 'Nachname',
        mPoint: 'M-Punkt',
        birthday: 'Geburtsdatum',
        phone: 'Telefon',
        email: 'E-Mail',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
      },

      mPoint: {
        _: 'Medizinischer Punkt | Medizinische Punkte', // _ for full object's name
        _short: 'M-Punkt | M-Punkte',
        name: 'Name',
        shortName: 'Kurzname',
        type: 'Art',
      },

      vitalSigns: {
        _: 'Vitalzeichen | Vitalzeichen',
        weight: 'Gewicht',
        temperature: 'Temperatur',
        bloodPressure: 'Blutdruck',
        pulse: 'Puls',
        respRate: 'Atemfrequenz',
      },

      complaint: {
        _: 'Beschwerde | Beschwerden',
      },

      medication: {
        _: 'Medikament | Medikament',
      },

      diagnosis: {
        _: 'Diagnose | Diagnosen',
        summary: 'Zusammenfassung',
        description: 'Beschreibung'
      },

      note: { // new
        _: 'Notiz | Notizen',
      },

      file: {
        _: 'Datei | Dateien',
        name: 'Name',
        type: 'Art',
        size: 'Größe',
        status: 'Status',
        width: 'Breite',
        height: 'Höhe',
      },

      service: {
        _: 'Behandlung | Behandlungen',
        service: 'Behandlung',
        name: 'Name',
        online: 'Online',
        price: 'Preis',
        defaultDuration: 'Standard-Termindauer',
        duration: 'Termindauer'
      },

      specialty: {
        _: 'Fachgebiet | Fachgebiet',
        name: 'Name',
      },
      session: {
        _: 'Arbeitsplan | Arbeitspläne',
        startTime: 'Startzeit',
        endTime: 'Endzeit'
      }

    },

    tutorials: {
      appointment: {
        addTitle: 'Termin hinzufügen',
        addText: 'Versuchen Sie, einen neuen Termin zu erstellen.',
        addTask: 'Klicken Sie auf den Kalender',
        detailsTitle: 'Einzelheiten zum Termin',
        detailsText: 'Warum versuchen Sie nicht, die Daten des Termins einzugeben',
        detailsTask: 'Füllen Sie das Terminformular aus',
        detailsSubtask: 'Klicken Sie auf NEXT',
        saveTitle: 'Termin speichern',
        saveText: 'Nun sind Sie bereit, den Termin zu erstellen',
        saveTask: 'Klicken Sie auf SPEICHERN',
        addedTitle: 'Termin hinzugefügt',
        addedText: 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Termin hinzugefügt',
        addedTask: 'Klicken Sie auf NEXT',
        rescheduleTitle: 'Termin verschieben',
        rescheduleText: 'Der Patient wird es nicht zu seinem Termin schaffen. Verlegen wir seinen Termin auf einen anderen Zeitpunkt, indem wir ihn bearbeiten.',
        rescheduleTask: 'Klicken Sie auf Termin',
        rescheduleTitle2: 'Termin verschieben',
        rescheduleText2: 'Sie sollten den Termin bearbeiten, um ihn zu verschieben',
        rescheduletTask2: 'Klicken Sie auf das Bearbeiten-Symbol',
        rescheduleTitle3: 'Termin verschieben',
        rescheduleText3: 'Termin ändern Termin',
        rescheduleTask3: 'Klicken Sie auf NEXT',
        rescheduleTitle4: 'Termin verschieben',
        rescheduleText4: 'Jetzt sind Sie bereit, die Zeit für neue Termine zu sparen',
        rescheduleTask4: 'Klicken Sie auf SPEICHERN',
        updatedTitle: 'Termin aktualisiert',
        updatedTask: 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Termin aktualisiert',
        tutorialFinishTitle: 'Fantastisch!',
        tutorialFinishText: 'Sie haben gerade gelernt, wie man Termine erstellt und verschiebt'
      },

      appointmentStatus: {
        'title': 'Status des Termins',
        'text': 'Versuchen Sie, den Status des Termins zu ändern.',
        'task': 'Klicken Sie auf den Termin',
        'subtask': 'Klicken Sie im Bestätigungsfenster auf Ja',
        'title2': 'Status des Termins',
        'text2': 'Herzlichen Glückwunsch! Sie haben den Status des Termins erfolgreich geändert',
        'finishTitle': 'Fantastisch!',
        'finishText': 'Sie haben gerade gelernt, wie man Termine erstellt und verschiebt',
      },

      patient: {
        'addTitle': 'Einen Patient hinzufügen',
        'addText': 'Versuchen Sie, einen neuen Patient hinzuzufügen',
        'addTask': 'Klicken Sie auf das Plus',
        'detailsTitle': 'Patient-Details',
        'detailsText': 'Warum versuchen Sie nicht, die Daten des Patient einzugeben',
        'detailsTask': 'Patient-Formular ausfüllen',
        'detailsSubtask': 'Klicken Sie auf NEXT',
        'saveTitle': 'Patient speichern',
        'saveText': 'Nun sind Sie bereit, den Patient zu erstellen',
        'saveTask': 'Klicken Sie auf Speichern',
        'addedTitle': 'Patient hinzugefügt',
        'addedText': 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Patient hinzugefügt',
        'addedTask': 'Klicken Sie auf NEXT',
        'editTitle': 'Patient bearbeiten',
        'editText': 'Die Kontaktinformationen des Patient haben sich geändert. Lassen Sie uns sein Profil aktualisieren, indem wir es bearbeiten',
        'editTask': 'Klicken Sie zum Bearbeiten auf das Symbol',
        'editTitle2': 'Patient bearbeiten',
        'editText2': 'Kontaktdaten des Patient ändern',
        'editTask2': 'Klicken Sie auf NEXT',
        'editTitle3': 'Patient bearbeiten',
        'editText3': 'Jetzt sind Sie bereit, geänderte Daten des Patient zu speichern',
        'editTask3': 'Klicken Sie auf Speichern',
        'updatedTitle': 'Patient aktualisiert',
        'updatedText': 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Patient aktualisiert',
        'finishTitle': 'Fantastisch!',
        'finishText': 'Sie haben gerade gelernt, wie man Patienten erstellt und bearbeitet',
      },

      admin: {
        'addTitle': 'Einen Administrator erstellen',
        'addText': 'Versuchen Sie, einen neuen Administrator hinzuzufügen',
        'addTask': 'Klicken Sie auf das Plus',
        'detailsTitle': 'Administrator-Details',
        'detailsText': 'Warum versuchen Sie nicht, die Daten des Administrator einzugeben',
        'detailsTask': 'Administrator-Formular ausfüllen',
        'detailsSubtask': 'Klicken Sie auf NEXT',
        'saveTitle': 'Administrator speichern',
        'saveText': 'Nun sind Sie bereit, den Administrator zu erstellen',
        'saveTask': 'Klicken Sie auf Speichern',
        'addedTitle': 'Administrator hinzugefügt',
        'addedText': 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Administrator hinzugefügt',
        'addedTask': 'Klicken Sie auf NEXT',
        'editTitle': 'Administrator bearbeiten',
        'editText': 'Die Kontaktinformationen des Administrator haben sich geändert. Lassen Sie uns sein Profil aktualisieren, indem wir es bearbeiten',
        'editTask': 'Klicken Sie zum Bearbeiten auf das Symbol',
        'editTitle2': 'Administrator bearbeiten',
        'editText2': 'Kontaktdaten des Administrator ändern',
        'editTask2': 'Klicken Sie auf NEXT',
        'editTitle3': 'Administrator bearbeiten',
        'editText3': 'Jetzt sind Sie bereit, geänderte Daten des Administrator zu speichern',
        'editTask3': 'Klicken Sie auf Speichern',
        'updatedTitle': 'Administrator aktualisiert',
        'updatedText': 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Administrator aktualisiert',
        'finishTitle': 'Fantastisch!',
        'finishText': 'Sie haben gerade gelernt, wie man Administratoren erstellt und bearbeitet',
      },

      assistant: {
        'addTitle': 'Einen Assistenten erstellen',
        'addText': 'Versuchen Sie, einen neuen Assistent hinzuzufügen',
        'addTask': 'Klicken Sie auf das Plus',
        'detailsTitle': 'Assistent-Details',
        'detailsText': 'Warum versuchen Sie nicht, die Daten des Assistent einzugeben',
        'detailsTask': 'Assistent-Formular ausfüllen',
        'detailsSubtask': 'Klicken Sie auf NEXT',
        'saveTitle': 'Assistent speichern',
        'saveText': 'Nun sind Sie bereit, den Assistent zu erstellen',
        'saveTask': 'Klicken Sie auf Speichern',
        'addedTitle': 'Assistent hinzugefügt',
        'addedText': 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Assistent hinzugefügt',
        'addedTask': 'Klicken Sie auf NEXT',
        'editTitle': 'Assistent bearbeiten',
        'editText': 'Die Kontaktinformationen des Assistent haben sich geändert. Lassen Sie uns sein Profil aktualisieren, indem wir es bearbeiten',
        'editTask': 'Klicken Sie zum Bearbeiten auf das Symbol',
        'editTitle2': 'Assistent bearbeiten',
        'editText2': 'Kontaktdaten des Assistent ändern',
        'editTask2': 'Klicken Sie auf NEXT',
        'editTitle3': 'Assistent bearbeiten',
        'editText3': 'Jetzt sind Sie bereit, geänderte Daten des Assistent zu speichern',
        'editTask3': 'Klicken Sie auf Speichern',
        'updatedTitle': 'Assistent aktualisiert',
        'updatedText': 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Assistent aktualisiert',
        'finishTitle': 'Fantastisch!',
        'finishText': 'Sie haben gerade gelernt, wie man Assistenten erstellt und bearbeitet',
      },

      doctor: {
        'addTitle': 'Einen Arzt hinzufügen',
        'addText': 'Versuchen Sie, einen neuen Arzt hinzuzufügen',
        'addTask': 'Klicken Sie auf das Plus',
        'detailsTitle': 'Arzt-Details',
        'detailsText': 'Warum versuchen Sie nicht, die Daten des Arzt einzugeben',
        'detailsTask': 'Arzt-Formular ausfüllen',
        'detailsSubtask': 'Klicken Sie auf NEXT',
        'saveTitle': 'Arzt speichern',
        'saveText': 'Nun sind Sie bereit, den Arzt zu erstellen',
        'saveTask': 'Klicken Sie auf Speichern',
        'addedTitle': 'Arzt hinzugefügt',
        'addedText': 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Arzt hinzugefügt',
        'addedTask': 'Klicken Sie auf NEXT',
        'editTitle': 'Arzt bearbeiten',
        'editText': 'Die Kontaktinformationen des Arzt haben sich geändert. Lassen Sie uns sein Profil aktualisieren, indem wir es bearbeiten',
        'editTask': 'Klicken Sie zum Bearbeiten auf das Symbol',
        'editTitle2': 'Arzt bearbeiten',
        'editText2': 'Kontaktdaten des Arzt ändern',
        'editTask2': 'Klicken Sie auf NEXT',
        'editTitle3': 'Arzt bearbeiten',
        'editText3': 'Jetzt sind Sie bereit, geänderte Daten des Arzt zu speichern',
        'editTask3': 'Klicken Sie auf Speichern',
        'updatedTitle': 'Arzt aktualisiert',
        'updatedText': 'Herzlichen Glückwunsch! Sie haben erfolgreich einen Arzt aktualisiert',
        'finishTitle': 'Fantastisch!',
        'finishText': 'Sie haben gerade gelernt, wie man Ärzte erstellt und bearbeitet',
      },

    },

    labels: { // buttons, filters, placeholders and tooltips
      export: 'Export',
      save: 'Speichern',
      canceled: 'stornieren',
      request: 'anfrage',
      confirmed: 'bestätigt',
      'new': 'Neu',
      expired: 'abgelaufen',
      cancel: 'Abbrechen',
      stop: 'Anhalten',
      block: 'Anhalten',
      notAvailable: 'Anhalten',
      online: 'Online',
      change: 'Ändern',
      invite: 'Laden',
      avatar: 'Avatare',
      warning: 'Warnung',
      link: 'Verknüpfen',
      itemsPerPageText: 'Zeilen pro Seite:',
      edit: 'Redigieren',
      editProfile: 'Redigieren',
      remove: 'Entfernen',
      offline: 'Vor Ort',
      'with': 'mit',
      active: 'aktiv',
      join: 'Beitreten',
      abort: 'abgrebrochen',
      confirm: 'Bestätigen',
      close: 'Schließen',
      search: 'Suche',
      success: 'Erfolgreich', // new
      all: 'Alle',
      of: 'von', // new
      from: 'von',
      back: 'Zurück',
      finish: 'Beenden',
      message: 'Nachricht',
      exit: 'Verlassen',
      ok: 'Ok',
      yes: 'Ja',
      no: 'Nein',
      type: 'Typ',
      print: 'Drucken',
      share: 'Teilen',
      tutorial: 'Anleitung',
      selectTutorial: 'Anleitung auswählen',
      searchPatients: 'Patienten suchen',
      day: 'Tag',
      week: 'Woche',
      month: 'Monat',
      custom: 'Benutzerdefinierte',

      auth: {
        signInToContinue: 'Anmelden um fortzufahren',
        login: 'Anmelden',
        password: 'Passwort',
        signIn: 'Anmelden',
      },

      appointments: {
        '_': 'TERMINE',
        addAppointment: 'Termin erstellen',
        addNote: 'Notiz erstellen',
        allAppointments: 'Alle Termine',
        notSpecified: 'nicht hinterlegt',
        lastDiagnosis: 'letzte Diagnose',
        copyToClipboard: 'In Zwischenablage kopieren',
        editAppointment: 'Redigieren hinzufügen', // ?
        tutorialAppointmentShort: 'Termin',
        tutorialAppointment: 'Erfahren Sie, wie Sie einen Termin erstellen und bearbeiten',
        tutorialStatusShort: 'Status des Termins',
        tutorialStatus: 'Erfahren Sie, wie Sie den Status des Termins ändern können',
        today: 'Heute',
        visits: 'heutige Besuche',
        day: 'Tag',
        week: 'Woche',
        '7 days': '7 Tage',
        month: 'Monat',
        mPoint: 'M-PUNKT',
        title: 'Titel',
        message: 'Nachricht',
        writeMessage: 'Nachricht hier schreiben',
        sendEmail: 'E-Mail senden',

        patientIsOnline: 'Patient ist Online',
        isOnline: 'ist Online',
        patientLastSeenADayAgo: 'Patient zuletzt vor einem Tag gesehen',
        lastSeen: 'zuletzt gesehen ',
        noMessage: 'kein Nachricht',
        typeMessage: 'Nachricht eingeben',
        convStartedOn: 'Konversation begann am',
        startAppointment: 'Termin beginnen',
        finishAppointment: 'Termin beenden',
        notes: 'Notizen',
        note: 'Notiz',
        add: 'hinzufügen',
        waitingForPatient: 'Auf Patienten warten',

      },

      patients: {
        addPatient: 'Patient erstellen',
        addContact: 'Familienmitglied hinzufügen',
        editPatient: 'Patient bearbeiten',
        lastDiagnosis: 'letzte Diagnose',
        today: 'Heute',
        recent: 'Kürzlich',
        upcoming: 'Kommende',
        appointments: 'TERMINE',
        patientsInfo: 'INFO DES PATIENTEN',
        medicalPoints: 'MEDIZINISCHE PUNKTE',
        patientPoints: 'PATIENTENPUNKTE',
        // addInfo: 'Informationen hinzufügen',
        addInfo: 'Informationen',
        appointmentWith: 'Termin mit',
        'for': 'für',
        selectFiles: 'Datei auswählen',
        startUpload: 'Hochladen starten',
        stopUpload: 'Hochladen abrechen',
      },

      doctors: {
        addDoctor: 'Arzt erstellen',
        editDoctor: 'Arzt bearbeiten',
        editServices: 'Behandlungen bearbeiten'
      },

      administrators: {
        addAdministrator: 'Administrator erstellen',
        editAdministrator: 'Administrator bearbeiten',
        administrators: 'ADMINISTRATOREN'
      },

      assistants: {
        addAssistant: 'Assistenten erstellen',
        editAssistant: 'Assistenten bearbeiten'
      },

      services: {
        addService: 'Behandlung erstellen',
        editService: 'Redigieren Behandlung',
        editServices: 'Behandlungen zuordnen'
      },

      schedule: {
        editSchedule: 'Zeiten bearbeiten',
        defaultDuration: 'Zeitfenster',
        practiceTimings: 'Öffnungszeiten',
        slotsCustomization: 'Die Zeitfenster Ihres Kalenders werden ebenfalls für die gleiche Dauer angepasst.',
        specifyDays: 'Geben Sie die Tage an, an denen Sie geöffnet haben werden',
        addSession: 'Sitzung hinzufügen',
        addTimings: 'weitere Zeiten hinzufügen',
      },

      mPoints: {
        defaultDuration: 'Standard Termindauer',
        mPoints: 'MEDIZINISCHE PUNKTE', // new
        addMPoint: 'M-Punkt hinzufügen',
        editMPoint: 'M-Punkt bearbeiten',
      },
    },

    messages: {
      copyLink: 'Der Link wurde kopiert',
      notSpecified: 'Nicht angegeben',
      expiresAt: 'Ihr Einladungslink läuft ab unter',
      notFound: '{object} nicht gefunden',
      atLeast: 'Mindestens {chars} Zeichen',
      changeStatus: 'Möchten Sie wirklich den Status dieses Termins auf {status} ändern?',
      deleteObject: 'Möchten Sie wirklich diesen {object} löschen?',
      confirmAppointment: 'Möchten Sie diesen Termin wirklich bestätigen?',
      startAppointment: 'Möchten Sie wirklich diesen Termin starten?',
      finishAppointment: 'Möchten Sie wirklich diesen Termin beenden?',
    },

    honorics: {
      'Dr.': 'Dr.',
      'Mr.': 'Mr.',
      'Mrs.': 'Mrs.',
      'Miss': 'Miss',
      'Ms.': 'Ms.'
    },

    units: {
      minute: 'minute | minuten',
      minuteShort: 'min.',
      weight: 'kg',
      years: 'Jahre',
      months: 'Monate',
      pulse: 'bpm',
      breaths: 'atemzug/min',
      tab: 'mg pro Tablette',
    },

    values: {
      mPointTypes: {
        practice: 'Praxis',
        clinic: 'Krankenhaus'
      },

      reasons: {
        break: 'Pause',
        meeting: 'Meeting',
        conference: 'Konferenz',
        other: 'Andere'
      },

      userRelations: {
        husband_wife: 'Ehemann/Frau',
        son_daughter: 'Sohn/Tochter',
        brother_sister: 'Bruder/Schwester',
        grandpa_grandma: 'Opa/Oma',
        uncle_aunt: 'Onkel/Tante',
      },

      fileTypes: {
        common: 'Gemeinsam',
        personal: 'Persönlich',
        results: 'Ergebnisse',
      },

      weekdays: {
        'Monday': 'Montag',
        'Tuesday': 'Dienstag',
        'Wednesday': 'Mittwoch',
        'Thursday': 'Donnerstag',
        'Friday': 'Freitag',
        'Saturday': 'Samstag',
        'Sunday': 'Sonntag'
      },

      specialties: {
        'Aesthetic medicine': 'Ästhetische Medizin',
        'Internal medicine': 'Innere Medizin',
        'Cardiology': 'Kardiologie',
        'Dermatology': 'Dermatologie',
        'Gastroenterology': 'Gastroenterologie',
        'Immunology': 'Immunologie',
        'Neurophysiology': 'Neurophysiologie',
        'Ophthalmology': 'Augenheilkunde',
        'Orthodontics': 'Kieferorthopädie',
        'Psychology': 'Psychologie',
        'Neurosurgery': 'Neurochirurgie',
        'Endocrine neurosurgery': 'Endokrine Neurochirugie',
        'Pediatric neurosurgery': 'Pädiatrische Neurochirurgie',
        'Vascular neurosurgery': 'Vaskuläre Neurochirurgie',
        'Neurology': 'Neurologie',
        'Orthopedics': 'Orthopädie',
        'Anesthesia': 'Anästhesie',
        'Endovascular neurosurgery': 'Endovaskuläre Neurochirurgie',
        'Plastic and aesthetic surgery': 'Plastische und Ästhetische Chirurgie',
        'Otorhinolaryngology': 'Hals-Nasen-Ohrenheilkunde',
      },

      appointmentStatuses: {
        'default': 'standard',
        waiting: 'wartet',
        active: 'aktiv',
        //closed: 'abgeschlossen'
        closed: 'vollendet'
      },

      appointmentStates: { // new
        allStates: 'Status',
        unconfirmed: 'Unbestätigt',
        confirmed: 'Bestätigt',
        all: 'Art',
        offline: 'Vor Ort',
        online: 'Online'
      },

      patientProfile: {
        honorific: 'Anrede',
        mrs: 'Frau',
        mr: 'Herr',
        insurance: 'Versicherung',
        private: 'Privat',
        public: 'Gesetzlich',
        selfPayer: 'Selbstzahler',
      },

      services: {
        'sonography': 'sonographie',
        'anamnesis': 'anamnese',
        'clinical examination': 'klinische untersuchung',
        'blooddrawing': 'blutabnahme',
        'clinical assessment': 'klinische beurteilung',
        'phone consultation': 'telefon beratung',
        'long consultation': 'Lange Beratung',
        'fmri': 'fMRT',
        'initial consultation': 'Erstgespräch',
        'follow-up check': 'Nachkontrolle',
        'stms stimulation therapy consultation': 'STMS Stimmulationstheraphie',
        'stms stimulation therapy initial consultation': 'STMS Stimmulationstheraphie Erstgespräch',

        'private consultation': 'Privatgespräch',
        'final discussion after surgery': 'Abschlussgespräch nach OP',
        'clinical assessment spinal': 'Klinische Beurteilung Spinal',
        'clinical assessment cranial': 'Klinische Beurteilung Kranial',
        'new patient': 'Neu Patient',
        'doppler sonography': 'Doppler Sonographie',
        'mrt consultation': 'MRT Besprechung',
        'stimulation therapy': 'Stimulation Therapie',
        'botox therapy': 'Botox Therapie',

        'consultation': 'Beratung',
        'decoding of analyses': 'Dekodierung von Analysen',

        'electroencephalography': 'Elektroenzephalografie',
        'radiology': 'Radiologie',

        'surcharge for dig radiography for 5010 - 5290': 'Zuschlag für Dig. Radiographie für 5010 - 5290',
        'skull 2 levels': 'Schädel 2 Eb.',
        'special project (Schuller, Stenvers, Rhese, Shuntziel) per part': 'Spezialprojekt. (Schüller, Stenvers, Rhese, Shuntziel) je Teil',
        'paranasal sinuses, possibly several levels': 'NNH, ggf mehrere Ebenen',
        'cervical spine 2 levels': 'HWS 2Eb.',
        'cervical spine oblique, complementary plane': 'HWS schräg, ergänzende Ebene',
        'cervical spine function (analogue to cervical spine 2 levels)': 'HWS Funktion (analog zu HWS 2Eb.)',
        'thoracic spine 2 levels': 'BWS 2Eb.',
        'lumbar spine 2 levels': 'LWS 2Eb.',
        'lumbar spine oblique, complementary plane': 'LWS schräg , ergänzende Ebene',
        'lumbar spine function (similar to lumbar spine 2 levels)': 'LWS Funktion (analog zu LWS 2Eb.)',
        'thorax 1 level': 'Thorax 1 Eb.',
        'thorax 2 or more levels': 'Thorax 2 oder mehr Ebenen',
        'abdomen 1 level': 'Abdomen 1Eb.',
        'pelvis': 'Becken',
        'extremities 2 levels (wrist, upper ankle)': 'Extremität 2Eb. (Handgelenk, OSG)',
        'limb detail 1 level complementary to 5020 (Patella)': 'Extremität detail 1Eb. ergänzend zu 5020 (Patella)',
        'extremities 2 levels (hand, hip, knee, foot, shoulder, clavicle, elbow)': 'Extremität 2Eb. (Hand, Hüfte, Knie, Fuß, Schulter, Clavicula, Ellenbogen)',
        'complementary level (Lauenstein, Rosenberg, Radius head)': 'Ergänzende Ebene (Lauenstein, Rosenberg, Radiusköpfchen)',
        'full x-ray of the spine / legs': 'Ganzaufnahme der Wirbelsäule/ Beine',
        'skeletal image 1 level': 'Skelettaufnahme 1 Ebene',
        'detailed findings report': 'Ausführlicher Befundbericht',
        'skull MRT': 'Schädel MRT',
        'functional MRT (analogous to PET)': 'Funktionelles MRT (analog zu PET)',
        'cervical spine MRT': 'HWS MRT',
        'thoracic spine MRT': 'BWS MRT',
        'lumbar spine MRT': 'LWS MRT',
        'joint MRT': 'Gelenk MRT',
        'maximum value for 5700-5730 (and 5731, 5733)': 'Höchstwert für 5700-5730 (und 5731, 5733)',
        'complementary series': 'Ergänzende Serie',
        'position/coil change': 'Positions-/ Spulenwechsel',
        'image Analysis (MR-A, MPR)': 'Image Analysis (MR-A, MPR)',
        'intravenous contrast agent by hand': 'i.v. Kontrastmittel von Hand',
        'intravenous contrast agent using high pressure': 'i.v. Kontrastmittel mittels Hochdruck',
        'introduction of NACL': 'Einbringung von NACL',
        'in-depth advice': 'Eingehende Beratung',
        'conciliar discussion': 'Konsiliarische Erörterung',
        'skull CT': 'Schädel CT',
        'CT-A': 'CT-A',
        'cervical spine - intervertebral space- CT': 'HWS - ZWR - CT',
        'thoracic spine - intervertebral space- CT': 'BWS - ZWR - CT',
        'lumbar spine - intervertebral space- CT': 'LWS - ZWR - CT',
        'post-Myelo CT': 'Post-Myelo-CT',
        'neck/ thorax CT': 'Hals/ Thorax CT',
        'abdominal CT': 'Abdominal CT',
        'skeleton CT': 'Skelett CT',
        'osteodensitometry CT': 'Osteodensitometrie CT',
        'maximum value for 5370-5374': 'Höchstwert für 5370-5374',
        'complementary series (KM / CT-A)': 'Ergänzende Serie (z.B. KM / CT-A)',
        'image Analysis (Bones - MPR - SSD)': 'Image Analysis (Bones - MPR - SSD)',
        'irradiation planning/intervention/stereotaxy': 'Bestrahlungsplanung/Intervention/Stereotaxie',
        'intravenous contrast medium infusion': 'i.v. Kontrastmittel Infusion',
        'intravenous contrast medium high-pressure injector': 'i.v. Kontrastmittel Hochdruckinjektomat',
        'further KM administration iv': 'Weitere KM-Gabe i.v.',

        'PT in combination (ice/heat-therapy)': 'KG in Kombination (Eis/Wärmetherapie)',
        'neurological PT Bobath': 'KG-Neuro/nach Bobath',
        'neurological PT/PNF': 'KG-Neuro/nach PNF',
        'manual therapy': 'Manuelle Therapie',
        'PT weight supported': 'Geräteunterstützte KG',
        'manual lymph drainage': 'Manuelle Lymphdrainage',
        'electrotherapy': 'Elektrotherapie',
        'movement rail': 'Bewegungsschiene (Sequenztraining)',
        'massage therapy in combination with heat/ice': 'Massage in kombination Wärme/Kältetherapie',
        'occupational therapy': 'Ergotherapie',
      }
    },

    errors: {
      common: {
        required: '{object} ist notwendig',
        thisFieldIsRequired: 'Pflichtfeld',
        wrongEmail: 'E-Mail muss gültig sein',
        permission: 'Sie haben keine Berechtigungen für diese Aktion',
        duplicate: 'Dieses {object} wurde bereits hinzugefügt',
        noData: 'Keine Daten verfügbar'
      },

      auth: {
        wrongCredentials: 'Falsche Anmeldung oder falsches Passwort',
        emailInUse: 'E-Mail ist bereits im Einsatz',
        loginInUse: 'Anmeldung ist bereits erfolgt'
      },

      schedule: {
        doctorScheduleExceed: 'Der Zeitplan des Arztes sollte den Zeitplan des M-Punktes nicht überschreiten',
        wrongDay: 'Falsches Tagesformat',
        daysIntersect: 'Die Tage sollten sich in der Zeitplanung nicht überschneiden',
        timeIntersect: 'Die Zeit sollte sich in den Sitzungen nicht überschneiden',
        shortSessionDuration: 'Die Sitzungsdauer kann nicht kürzer sein als die Kalenderschrittweite'
      },

      service: {
        deleteServiceWithAppointment: 'Sie können einen Service nicht löschen, mit dem ein Termin verknüpft ist.'
      },

      file: {
        maxSize: 'Die maximale Größe der Datei - {n} Mb',
        upload: 'Fehler beim Datei-Upload',
        format: 'Falsches Dateiformat'
      }
    }
  }
}


const dateTimeFormats = {
  'en-US': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'ja-JP': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
    }
  }
}
// As seen above, you can define named datetime format (e.g. short, long, etc), and you need to use the options with ECMA-402 Intl.DateTimeFormat (http://www.ecma-international.org/ecma-402/2.0/#sec-intl-datetimeformat-constructor)
export default {
  messages: messages,
  dateTimeFormats: dateTimeFormats
}
