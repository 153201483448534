import axios from 'axios'
import TokenService from '../services/token.service'

const ApiService = {

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },

    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    async get(resource, params) {
        if (typeof params == 'undefined') {
            params = {}
        }
        let r
        try {
            let lang = localStorage.getItem('user-locale') || 'de'
            if (!params.params) {
                params.params = {}
            }
            params.params.l = lang
            r = await axios.get(resource, params)
            if (r.data && r.data.error && r.data.error == 'session expired') {
                TokenService.removeRefreshToken()
                TokenService.removeToken()
                window.location.reload(true)
            }
        } catch (e) {
            console.log('e', e)
        }
        return new Promise(resolve => {resolve(r)})
    },

    post(resource, data) {
        return axios.post(resource, data)
    },

    axios(options) {
        return axios(options)
    },

    put(resource, data) {
        return axios.put(resource, data)
    },

    delete(resource) {
        return axios.delete(resource)
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        return axios(data)
    }
}

export default ApiService
