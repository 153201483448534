<template>
  <layout-default :is-loaded="true">
    <v-layout

            wrap
    >

      <v-flex>



        <v-dialog content-class="withHeader" fullscreen hide-overlay
                  transition="dialog-bottom-transition" v-model="dialogAddWorks">
          <v-card v-if="dialogAddWorks" style="position: relative">
            <v-toolbar
                    tile
                    class="white--text"
                    color="primary"
            >
              <v-toolbar-title>Добавление работ на конкурс</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                      icon
                      dark
                      @click="dialogAddWorks = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>


            </v-toolbar>
            <v-card-text class="pt-7">
              <div class=" mx-auto" style="max-width: 1600px;">

                <div>
                  <v-form v-model="validWorksForm" @submit.prevent="addWorksConfirm" class="marginForm" ref="worksForm">

                    <!--<file-pond
                            v-if="dialogAddWorks"
                            name="files[]"
                            ref="pond"
                            type="local"
                            :credits="null"

                            :image-edit-editor-old="editor"
                            :instant-upload="true"
                            imageResizeTargetWidth="500"
                            :imageFilterColorMatrix-old="[
                                        0.299, 0.587, 0.114, 0, 0,
                                        0.299, 0.587, 0.114, 0, 0,
                                        0.299, 0.587, 0.114, 0, 0,
                                        0.000, 0.000, 0.000, 1, 0
                                        ]"
                            :allow-image-crop="true"
                            imageCropAspectRatio-none="1:1"
                            :allow-image-transform="true"
                            label-idle="Перетащите файлы сюда..."
                            v-bind:allow-multiple="true"
                            accepted-file-types="image/jpeg, image/png"
                            :server="serverOptions"
                            :files="myFiles"
                            v-model="files"
                            v-on:init="handleFilePondInit"
                    />-->

                    <file-uploader :only-update="onlyUpdate" :pdf="true" :old-files="currentContestFiles" :object="'user_contest'" :object_id="currentContestId"></file-uploader>

                    <!--<div class="mt-10 pb-10 text-right">
                      <v-btn :loading="saving" @click="addWorksConfirm()" min-width="200px" :disabled="!validWorksForm || !files.length" x-large mx-auto color="primary">Добавить</v-btn>
                    </div>-->
                  </v-form>
                </div>


              </div>

            </v-card-text>
          </v-card>
        </v-dialog>






        <div class="mt-4 mx-auto d-flex justify-center" style=" position: relative">
          <!--<avatar size="large" color="primary" class="primary" :img="getDoctorContent.avatar"
                  :name="getDoctorContent.first_name"></avatar>-->
          <avatar-uploader v-if="getUserProfile" class="" :user_name="getUserProfile.first_name" @user:updated="fetchUserProfile" :file="getUserProfile.avatar" :user_id="getUserData.id" ref="avatar"></avatar-uploader>
        </div>

        <div v-if="getUserProfile" class="mt-1 white--text" style="position: relative">
          <h3 class="">{{ getUserProfile ? getUserProfile.last_name + ' ' + getUserProfile.first_name  + ' ' + getUserProfile.patronymic : '' }}</h3>
          <div class="caption">{{ getUserProfile.city }}</div>
          <div class="">
            {{ role }} <sup v-if="getUserProfile.role_status != 'confirmed'">*</sup>
            <div class="body-2 font-italic white--text" v-if="getUserProfile.role_status == 'waiting'">* ожидает подтверждения</div>
            <div class="body-2 font-italic red--text" v-if="getUserProfile.role_status == 'cancel'">* статус не подтвержден</div>
          </div>
        </div>

        <div class="mt-4 px-0">
          <v-btn :to="{name: 'About'}" width="180px" class="fullWidthOnPhone" color="primary"  @click.stop="$router.push({name: 'Contest', params: {id: contest.contest.contest_id}})">О Галерее</v-btn>
        </div>

        <div class="mt-12">
          <v-expansion-panels v-model="section" class="mx-auto " style="max-width: 1000px;" light>
            <v-expansion-panel
            >
              <v-expansion-panel-header>
                Мои заявки
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="!waiting" class="mt-4 align-stretch">
                  <v-col style="position: relative;" class="d-flex font-weight-bold flex-column" :cols="12" v-if="!getUserProfile.contests || !getUserProfile.contests.length">
                    <div class="red--text cursive">
                      Вы не участвуете в конкурсах
                    </div>

                  </v-col>
                  <v-col v-else class="d-flex flex-column" :md="6" :lg="4" :xl="4" :cols="12" v-for="(contest, i) in getUserProfile.contests" :key="'doom-' + contest.contest_id">
                    <v-hover  >
                      <template v-slot:default="{ hover }">
                        <v-card

                                width="100%"
                                hover
                                class="mx-auto animated animate__animated animate__zoomInDown flex align-content-end d-flex flex-column"
                                rounded

                        >
<!--                          <v-img max-height="250" height="250" :src="contest.contest.cover"></v-img>-->

                          <v-card-text>

                            <h2 class="title mb-0 primary--text">
                              {{ contest.contest.name }}
                            </h2>

                            <div class="my-2 d-flex justify-space-between">
                              <span>{{ formatDate(contest.contest.request_start_at) }} - {{ formatDate(contest.contest.request_finish_at) }}</span> <span class="grey--text">заявки</span>
                            </div>
                            <div class="my-2 d-flex justify-space-between">
                              <span>{{ formatDate(contest.contest.start_at) }} - {{ formatDate(contest.contest.finish_at) }}</span> <span class="grey--text">работы</span>
                            </div>



                            <div  class="mt-5 d-flex flex-wrap justify-space-between">
                              <v-btn width="100%" class="mt-2 fullWidthOnPhone" color="primary"  @click.stop="$router.push({name: 'Contest', params: {id: contest.contest.contest_id}})">Положение</v-btn>
                              <v-btn v-if="contest.contest.start_at > new Date().toISOString().substring(0,10) "  width="100%" class="mt-2 fullWidthOnPhone" color="blue white--text"  >Прикрепить файлы <v-icon class="ml-3">mdi-clock-outline</v-icon></v-btn>
                              <v-btn v-if="contest.contest.finish_at < new Date().toISOString().substring(0,10)"  width="100%" class="mt-2 fullWidthOnPhone" @click="startAddingWorks(contest, 1)" color="blue white--text"  >Редактировать файлы </v-btn>
                              <v-btn v-else-if="contest.contest.start_at < new Date().toISOString().substring(0,10) " @click="startAddingWorks(contest)"  width="100%" class="mt-2 fullWidthOnPhone" color="blue white--text"  >Прикрепить файлы </v-btn>
                            </div>

                            <div class="title mt-4" v-if="contest.contest.is_finished || true">
                              <b>{{ showContestStatus(contest.status) }}</b>  <a v-if="contest.status != 'none'" class="ml-3" :href="'https://api.starykhgallery.ru/gallery-api/public/api/pdf/download/' + contest.contest_user_id" target="_blank" download=""><v-icon color="primary">mdi-file-certificate-outline</v-icon></a>
                            </div>

                            <!--<div class="mt-4" v-html="contest.short_description.replace(/(?:\r\n|\r|\n)/g, '<br />')">
                            </div>-->
                          </v-card-text>

                          <v-fade-transition v-if="false">
                            <v-overlay

                                    opacity="0.75"
                                    v-if="hover"
                                    absolute
                                    color="grey lighten-4"

                            >
                              <div @click="$router.push({name: 'Contest', params: {id: contest.contest.contest_id}})" style="width: 100%; height: 100%;">
                                <span  class=" black--text cursive"  style="opacity: 0.9; font-weight: 800; font-style: italic; font-size: 2vw; letter-spacing: 4px;">Просмотр <br> Конкурса</span>
                              </div>
                              <!--                                            <v-btn color="primary" style="opacity: 0.75">Просмотр</v-btn>-->

                            </v-overlay>
                          </v-fade-transition>

                          <v-spacer></v-spacer>



                          <v-card-actions class="justify-end d-flex pa-4 align-center">

                            <div class=" d-flex">
                                            <span class="caption green--text flex-nowrap">
                                                {{ filterOptions.find(item => item.value == contest.contest.type).name_single }}
                                            </span>
                            </div>





                          </v-card-actions>


                        </v-card>
                      </template>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
            >
              <v-expansion-panel-header>
                Мои выставки
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="!waiting && getUserProfile" class="align-stretch">
                  <v-col style="position: relative;" class="d-flex font-weight-bold flex-column" :cols="12" v-if="!getUserProfile.exhibitions || !getUserProfile.exhibitions.length">
                    <div class="red--text cursive">
                      У вас нет персональных выставок
                    </div>

                  </v-col>
                  <template v-else>
                    <exhibition-item :wide="false" :exhibition="exhibition" :key="'exhibition' + exhibition.exhibition_id" v-for="exhibition in getUserProfile.exhibitions"></exhibition-item>
                  </template>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>
                Мои документы
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="text-left mb-3 caption">
                  Фотографии документов, подтверждающие профессиональный статус (документы подтверждающие обучение на художественной специальности для студентов, диплом о художественном образовании, членский билет любого союза художников)
                </div>
                <v-row v-if="!waiting" class="align-stretch">
                  <v-col :cols="12" class="text-left">


                    <file-pond
                            :allow-remove="true"
                            name="files[]"
                            ref="pond"
                            type="local"
                            :credits="null"
                            :image-edit-editor-old="editor"
                            :instant-upload="false"
                            imageResizeTargetWidth="500"
                            :imageFilterColorMatrix-old="[
                                        0.299, 0.587, 0.114, 0, 0,
                                        0.299, 0.587, 0.114, 0, 0,
                                        0.299, 0.587, 0.114, 0, 0,
                                        0.000, 0.000, 0.000, 1, 0
                                        ]"
                            :allow-image-crop="true"
                            imageCropAspectRatio-none="1:1"
                            :allow-image-transform="true"
                            label-idle="Перетащите файлы сюда..."
                            :allow-multiple="true"
                            accepted-file-types="image/jpeg, image/png"
                            :server="serverOptions"
                            :files="myFiles"
                            v-model="files"
                            v-on:init="handleFilePondInit"

                    />


<!--                    <v-btn  min-width="200px" disabled  mx-auto color="primary">Добавить файлы</v-btn>-->

                  </v-col>
                  <v-col :cols="12" class="text-left">

                  </v-col>
                  <v-col style="position: relative;" class="d-flex font-weight-bold flex-column" :cols="12" v-if="!getUserProfile.documents || !getUserProfile.documents.length">
                    <div class="red--text cursive">
                      У вас нет загруженных документов
                    </div>

                  </v-col>
                  <v-col v-else>
                    <viewer @inited="inited" ref="viewer" :images="getUserProfile.documents">
                      <img  v-show="false" v-for="src in getUserProfile.documents" :src="src.file" :key="src.file">
                    </viewer>
                    <v-row>
                      <v-col class="d-flex flex-column" :md="6" :lg="4" :xl="3" :cols="12" v-for="(img, i) in getUserProfile.documents" :key="'doom-' + i">


                        <v-card

                                hover
                                class="mx-auto animated animate__animated animate__zoomInDown flex align-content-end d-flex flex-column"
                                rounded

                        >
                          <v-img @click="viewer.show().view(i)" max-height="250" height="250" :src="img.thumb"></v-img>


                        </v-card>

                      </v-col>
                    </v-row>


                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>


          </v-expansion-panels>


        </div>



<!--        <avatar class="ml-0 mb-5" img="avatar.jpg" size="medium"></avatar>-->



        <!--<div class="mb-2 ml-4" style="z-index: 5; position: relative;">
          <v-btn class="ml-0 mr-4"  @click.stop="dialog=!dialog" color="primary"  small fab>
            <v-icon

                    size="18"
                    color="white"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
        </div>-->

        <v-card max-width="500" v-if="false && getUserProfile" class="pt-0 mt-4 mx-auto" flat>
          <v-card-text class="text-left pt-0 ">
<div class="d-flex pt-4 align-center justify-space-between" >


  <div class=" white--text" style=" position: relative">

    <div style="line-height: 1" class="grey--text mt-2 overline mt-0 lighten-1" v-if="getUserProfile">{{ role }}</div>

    <!--<v-icon @click="dialog=!dialog" size="24" color="primary" class="ml-2 mt-1 float-right">mdi-pencil</v-icon>-->
  </div>

  <v-btn class="ml-4"  @click-old.stop="dialog=!dialog" color="primary"  small fab>
    <v-icon

            size="18"
            color="white"
    >
      mdi-pencil
    </v-icon>
  </v-btn>
</div>

            <v-list  two-line >

              <v-list-item @click="">
                <v-list-item-icon>
                  <v-icon color="orange">mdi-calendar</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ getUserProfile.birthday }}</v-list-item-title>
                  <v-list-item-subtitle ><span class="caption">Дата рождения</span></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider ></v-divider>
              <v-list-item @click="">
                <v-list-item-icon>
                  <v-icon color="purple">mdi-email</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ getUserProfile.email }}</v-list-item-title>
                  <v-list-item-subtitle ><span class="caption">E-Mail</span></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider ></v-divider>
              <v-list-item @click="">
                <v-list-item-icon>
                  <v-icon color="green">mdi-phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ getUserProfile.phone }}</v-list-item-title>
                  <v-list-item-subtitle ><span class="caption">Телефон</span></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider ></v-divider>
              <v-list-item @click="">
                <v-list-item-icon>
                  <v-icon color="blue">mdi-earth</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ getUserProfile.country }}</v-list-item-title>
                  <v-list-item-subtitle ><span class="caption">Страна</span></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider ></v-divider>
              <v-list-item @click="">
                <v-list-item-icon>
                  <v-icon color="red">mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ getUserProfile.city }}</v-list-item-title>
                  <v-list-item-subtitle ><span class="caption">Город</span></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
<!--              <v-divider ></v-divider>-->

            </v-list>

            <!--<div class="mt-5 mb-2">
              <v-btn @click="dialog=!dialog" class="float-right" small color="primary">
                <v-icon size="14" class="mr-1 ml-n1">mdi-pencil</v-icon> Edit
              </v-btn>
              <br>
            </div>-->

          </v-card-text>
        </v-card>
      </v-flex>

      <v-dialog v-model="dialog" max-width="500px"  class="mt-4" fullscreen  transition="dialog-bottom-transition">
        <v-card class="text-left">
          <v-system-bar color="white">
            <!--<v-spacer></v-spacer>-->
            <v-btn class="mt-8 " icon dark @click.stop="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-system-bar>

          <v-card-title class="" style="margin-top: 110px;">


            <h2 class=" mb-8" style="font-size: 28px; line-height: 1.35;">
              <span class="font-weight-bold"> Edit Profile</span>
            </h2>
            <!--<span class="headline">{{ formTitleAppointment }}</span>-->
          </v-card-title>

          <v-card-text v-if="editedProfile">
            <v-form ref="formProfile">
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :rules="nameRules" v-model="editedProfile.first_name" label="First name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedProfile.last_name" label="Last name"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">


                    <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="editedProfile.birthday"
                                label="Birthday"
                                append-icon="mdi-calendar"
                                readonly
                                v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                              ref="picker"
                              v-model="editedProfile.birthday"
                              :max="new Date().toISOString().substr(0, 10)"
                              min="1920-01-01"
                              @change="save"
                      ></v-date-picker>

                    </v-menu>
                    <!--<v-text-field  v-model="editedProfile.birthday" label="Birthday"></v-text-field>-->
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field :rules="emailRules" v-model="editedProfile.email" label="Email"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedProfile.phone" label="Phone"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-btn color="grey" width="100%" large tile class="white--text" @click.stop="dialog = false">Cancel</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn color="primary" large tile class="white--text"  width="100%"   @click="postProfile">Save</v-btn>
                  </v-col>
                </v-row>
            </v-form>

          </v-card-text>
        </v-card>
      </v-dialog>


    </v-layout>
  </layout-default>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import LayoutDefault from '@/layouts/LayoutClient.vue'
  import AvatarUploader from '@/components/users/AvatarUploader'
  import Avatar from '@/components/avatar'
  import dateTimeMixin from '@/mixins/datetime'
  import colorsMixin from '@/mixins/colors'
  import 'viewerjs/dist/viewer.css'
  import Viewer from "v-viewer/src/component.vue"
  import apiConfig from '@/static/apiConfig'
  import TokenService from '@/services/token.service'
  import ExhibitionItem from '@/components/common/exhibitions/c-list-item.vue'

  // Import FilePond styles
  import "filepond/dist/filepond.min.css";

  // Import FilePond plugins
  // Please note that you need to install these plugins separately

  // Import image preview plugin styles
  import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
  import FilePondPluginImagePreview from "filepond-plugin-image-preview";
  import FilePondPluginImageCrop from "filepond-plugin-image-crop";
  import FilePondPluginImageResize from "filepond-plugin-image-resize";
  import FilePondPluginImageTransform from "filepond-plugin-image-transform";
  import FilePondPluginImageEdit from "filepond-plugin-image-edit";
  import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
  import FilePondPluginImageFilter from 'filepond-plugin-image-filter';
  import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
  import FilePondPluginFileRename from 'filepond-plugin-file-rename';
  import vueFilePond, { setOptions } from 'vue-filepond';

  import FileUploader from '@/components/file-uploader/file-uploader.vue'


  const FilePond = vueFilePond(
          FilePondPluginFileValidateType,
          FilePondPluginFileMetadata,
          FilePondPluginImagePreview,
          FilePondPluginImageCrop,
          FilePondPluginImageResize,
          FilePondPluginFileRename,
          FilePondPluginImageTransform,
          FilePondPluginImageEdit,
          FilePondPluginImageExifOrientation,
          FilePondPluginImageFilter
  );

  setOptions({
    fileMetadataObject: {
      hello: 'world',
    },
    /*fileRenameFunction: (file) => {
      return `my_new_name${file.extension}`;
    },*/
  });

  export default {
    name: 'Profile',
    components: {
      LayoutDefault,
      FilePond,
      AvatarUploader,
      ExhibitionItem,
      Avatar,
      FileUploader,
      Viewer
    },
    mixins: [dateTimeMixin, colorsMixin],
    data: () => ({
      onlyUpdate: false,
      waiting: false,
      dialog: false,
      saving: false,
      validWorksForm: false,
      dialogAddWorks: false,
      currentContestId: false,
      files: [],
      myFiles: [],
      viewer: null,
      section: null,
      filterOptions: [
        {name: 'Все', value: ''},
        {name: 'Творческие', name_single: 'Творческий', value: 'common'},
        {name: 'Стипендии', name_single: 'Стипендиия', value: 'grant'},
        {name: 'Премии', name_single: 'Премиия', value: 'reward'},
      ],
      editor: {

        // Called by FilePond to edit the image
        // - should open your image editor
        // - receives file object and image edit instructions
        open: (file, instructions) => {
          // open editor here
          console.log(file);
        },

        // Callback set by FilePond
        // - should be called by the editor when user confirms editing
        // - should receive output object, resulting edit information
        onconfirm: (output) => {},

        // Callback set by FilePond
        // - should be called by the editor when user cancels editing
        oncancel: () => {},

        // Callback set by FilePond
        // - should be called by the editor when user closes the editor
        onclose: () => {}
      },
      menu: false,
      editedProfile: null,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ]
    }),
    computed: {
      ...mapGetters({
        getIsUserLoggedIn: 'auth/getIsUserLoggedIn',
        getUserData: 'auth/getUserData',
        getErrors: 'auth/getErrors',
        getUserProfile: 'auth/getUserProfile'
      }),
      serverOptions: function() {
        return {

          process: {
            url: apiConfig.url + '/file/save/user/' + this.getUserData.id,
            headers: {
              Authorization: `Bearer ${TokenService.getToken()}`
            },
            onload: (response) => {
              console.log('onload response', response)
              this.fetchUserProfile()
            },
            ondata: (formData) => {
              formData.append('category', 'documents');
              formData.append('file_category_id', 1);
              return formData;
            },
          },
          //fetch: null,
          //revert: null
        }
      },
      currentRouteName () {
        return this.$route.name
      },
      role() {
        if (this.getUserProfile) {
          switch (this.getUserProfile.role) {
            case 'user':
              return 'пользователь'
              break;
            case 'painter':
              return 'художник'
              break;
            case 'profi':
              return 'профессиональный художник'
              break;
            case 'profi_gallery':
              return 'художник Галереи Старых'
              break;
            case 'collector':
              return 'коллекционер'
              break;
          }
        } else {
          return '—'
        }
      }
    },
    methods: {
      ...mapActions({
        fetchUserProfile: 'auth/fetchUserProfile',
        saveProfile: 'auth/saveProfile',
        clearErrors : 'auth/clearErrors'
      }),

      showContestStatus: function(status) {
        switch (status) {
          case 'winner':
            status = 'победитель'
            break;
          case 'laureate':
            status = 'лауреат'
            break;
          case 'participant':
            status = 'участник'
            break;
          case 'none':
            status = '—'
            break;
        }
        return status
      },

      handleFilePondInit: function (filePond) {
        console.log("FilePond has initialized");

        // FilePond instance methods are available on `this.$refs.pond`
      },
      startAddingWorks(contest, onlyUpdate) {
        if (typeof onlyUpdate === 'undefined') {
          onlyUpdate = false
        }
        this.onlyUpdate = onlyUpdate
        console.log('contest', contest)
        this.currentContestId = contest.contest_id
        this.currentContestFiles = contest.files
        this.dialogAddWorks = true
      },

      inited: function (viewer) {
        this.viewer = viewer
      },
      postProfile: async function() {
        try {
          this.clearErrors()

          if (!this.$refs.formProfile.validate()) {
            return false
          }

          await this.saveProfile(
            this.editedProfile
          )
          if (this.getErrors.length > 0) {
            this.alertError = true
          } else {
            this.alertError = false
            this.dialog = false
            await this.fetchUserProfile()
          }

        } catch (e) {
          console.log('error', e)
        }
      },
      save (date) {
        this.$refs.menu.save(date)
      }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    async created () {
      if (this.$route.query.openSection && this.$route.query.openSection == 'contests') {
        this.section = 0;
      }
      await this.fetchUserProfile()
      this.editedProfile = this.getUserProfile
    }
  }
</script>
