import { cart } from '@/config/api'
import ApiService from '@/services/api.service'

const state = () => ({
    goods:  JSON.parse(localStorage.getItem('cart-goods') || '[]'),
    cart: [],
    errors: []

})

const getters = {
    getGoods: state => state.goods,
    getCart: state => state.cart,
    getErrors: state => state.errors
}

const actions = {

    async addGood({ commit }, goodId) {
        commit('ADD_GOOD', goodId)
    },

    async removeGood({ commit }, goodId) {
        commit('REMOVE_GOOD', goodId)
    },

    async fetchCart ({ commit, getters }, params) {
        const formattedParams = {
            ...params,
        }
        try {
            // commit('START_LOAD_DNT_LIST')
            const response = await ApiService.get(cart.list, {
                params: {
                    ...formattedParams
                }
            })

            commit('SET_CART', {
                payload: response.data.data,
                headers: response.headers
            })
        } catch (err) {
            commit('SET_CART', {
                payload: [],
                headers: {}
            })
            console.log(err)
            throw err
        }
    },

    async clearErrors ({commit}) {
        commit('CLEAR_ERRORS')
    }
}

const mutations = {
    CLEAR_ERRORS (state) {
        state.errors = []
    },

    ADD_ERROR (state, payload) {
        state.errors.push(payload)
    },

    START_LOAD_EXHIBITION(state) {
        state.content = null
    },

    ADD_GOOD(state, goodId) {
        state.goods.push(goodId)
        localStorage.setItem('cart-goods', JSON.stringify(state.goods))
    },

    REMOVE_GOOD(state, goodId) {
        const index = state.goods.indexOf(goodId);
        if (index > -1) {
            state.goods.splice(index, 1);
            localStorage.setItem('cart-goods', JSON.stringify(state.goods))
        }
    },

    SET_CART (state, { payload, headers }) {
        state.cart = payload
        // state.cartTotal = parseInt(headers['x-items-total'])
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
