// @vue/component

import Cropper from 'cropperjs'
import ImageCompressor from '@xkeshi/image-compressor'
import FileUpload from 'vue-upload-component'
import apiConfig from '@/static/apiConfig'
import TokenService from '@/services/token.service'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'FileUploader',

    components: {
        FileUpload
    },

    mixins: [],

    props: {
        file_category_id: {
            default: 1
        },
        category: {
            default: 'documents'
        },
        title: {
            default: 'Загруженные файлы'
        },
        pdf: {
            default: false
        },
        oneFile: {
            default: false
        },
        blockView: {
            default: false,
            type: Boolean
        },
        onlyUpdate: {
            default: false
        },
        oldFiles: {
            default: function () {return []}
        },
        object: {
            required: true
        },
        object_id: {
            required: true
        }
    },

    data () {
        return {
            materials: [
                {id: 1, name: 'Масло'},
                {id: 2, name: 'Пастель'},
                {id: 3, name: 'Маркер'},
                {id: 4, name: 'Карандаш'},
                {id: 5, name: 'Угольный карандаш'},
                {id: 6, name: 'Акварельный карандаш'},
                {id: 7, name: 'Уголь'},
                {id: 8, name: 'Акварель'},
                {id: 9, name: 'Соус'},
                {id: 10, name: 'Сангина'},
                {id: 11, name: 'Чернила'},
                {id: 12, name: 'Акрил'},
                {id: 13, name: 'Гуашь'},
                {id: 14, name: 'Темпера'},
                {id: 15, name: 'Смешанная техника'},
            ],
            materialBases: [
                {id: 1, name: 'Холст (хлопковый)'},
                {id: 2, name: 'Холст (льняной)'},
                {id: 3, name: 'Дерево'},
                {id: 4, name: 'ДСП'},
                {id: 5, name: 'Картон'},
                {id: 6, name: 'Бумага '},

            ],
            files: [],
            accept: 'image/png,image/gif,image/jpeg',
            extensions: 'gif,jpg,jpeg,png',
            minSize: 1024,
            size: 1024 * 1024 * 10,
            multiple: true,
            saving: false,
            directory: false,
            drop: true,
            validForm: true,
            dropDirectory: true,
            createDirectory: false,
            addIndex: false,
            thread: 3,
            name: 'files[]',
            postAction: apiConfig.url + '/file/save/' + this.object + '/' + this.object_id,
            putAction: apiConfig.url + '/file/save/' + this.object + '/' + this.object_id,
            headers: {
                Authorization: `Bearer ${TokenService.getToken()}`
            },
            data: {
            },
            autoCompress: 1024 * 1024,
            uploadAuto: false,
            isOption: false,
            addData: {
                show: false,
                name: '',
                type: '',
                content: '',
            },
            editFile: {
                show: false,
                name: '',
                material_id: '',
            },
            rules: {
                required: v => !!v || 'обязательное',
                min: v => v && v.length >= 6 || 'минимум 6 символов',
                email: v => /.+@.+\..+/.test(v) || 'неверный E-mail',
                passwordMatch: () => this.user.password === this.passwordConfirm || ('пароли не совпадают'),
            }
        }
    },

    computed: {
        ...mapGetters({
            getErrors: 'user/getErrors',
        }),
    },

    watch: {
        'editFile.show'(newValue, oldValue) {
            // 关闭了 自动删除 error
            if (!newValue && oldValue) {
                this.$refs.upload.update(this.editFile.id, { error: this.editFile.error || '' })
            }
            if (newValue) {
                this.$nextTick( () => {
                    if (!this.$refs.editImage) {
                        return
                    }
                    let cropper = new Cropper(this.$refs.editImage, {
                        autoCrop: false,
                    })
                    this.editFile = {
                        ...this.editFile,
                        cropper
                    }
                })
            }
        },
        'addData.show'(show) {
            if (show) {
                this.addData.name = ''
                this.addData.type = ''
                this.addData.content = ''
            }

        },

    },

    created () {
        if (this.pdf) {
            this.accept += ',application/pdf';
            this.extensions += ',pdf';
        }
        this.data.category = this.category
        this.data.file_category_id = this.file_category_id
        this.files = this.oldFiles
    },

    methods: {
        ...mapActions({
            fetchUserProfile: 'auth/fetchUserProfile',
            fetchExhibition: 'exhibitions/fetchExhibition',
            deleteFile: 'user/deleteFile',
            updateFile: 'user/updateFile',
            clearErrors : 'user/clearErrors',
        }),

        async updateUserFile(file, action) {

            try {
                if (this.oneFile  && (typeof action === 'undefined' || action !== 'sale')) {
                    return false
                }
                if (this.oneFile) {
                    const isValid = this.$refs.fileForm[0].validate()
                    console.log('this.isValid', isValid)
                    if (!isValid) {
                        return false
                    }
                }
                this.saving = true
                file = {...file.data}
                console.log('file', file)
                await this.updateFile(file)
                if (file.object == 'exhibition') {
                    await this.fetchExhibition(file.object_id)
                }
            } catch (e) {
                console.log('error', e)
            }
            this.saving = false
        },

        inputFilter(newFile, oldFile, prevent) {

            if (newFile && !oldFile) {
                // Before adding a file
                // 添加文件前
                // Filter system files or hide files
                // 过滤系统文件 和隐藏文件
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent()
                }
                // Filter php html js file
                // 过滤 php html js 文件
                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent()
                }
                // Automatic compression
                // 自动压缩
                if (newFile.file && newFile.error === "" && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
                    newFile.error = 'compressing'
                    const imageCompressor = new ImageCompressor(null, {
                        convertSize: 1024 * 1024,
                        maxWidth: 512,
                        maxHeight: 512,
                    })
                    imageCompressor.compress(newFile.file)
                        .then((file) => {
                            this.$refs.upload.update(newFile, { error: '', file, size: file.size, type: file.type })
                        })
                        .catch((err) => {
                            this.$refs.upload.update(newFile, { error: err.message || 'compress' })
                        })
                }
            }
            if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
                // Create a blob field
                // 创建 blob 字段
                newFile.blob = ''
                let URL = (window.URL || window.webkitURL)
                if (URL) {
                    newFile.blob = URL.createObjectURL(newFile.file)
                }
                // Thumbnails
                // 缩略图
                newFile.thumb = ''
                if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                    newFile.thumb = newFile.blob
                }
            }
            // image size
            // image 尺寸
            if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
                newFile.error = 'image parsing'
                let img = new Image();
                img.onload = () => {
                    this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
                }
                img.οnerrοr = (e) => {
                    this.$refs.upload.update(newFile, { error: 'parsing image size'})
                }
                img.src = newFile.blob
            }
        },
        // add, update, remove File Event
        inputFile(newFile, oldFile) {
            //this.data.width_cm = '10'


            if (newFile && oldFile) {
                // update
                if (newFile.active && !oldFile.active) {
                    // beforeSend
                    // min size
                    if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
                        this.$refs.upload.update(newFile, { error: 'size' })
                    }
                }
                if (newFile.progress !== oldFile.progress) {
                    // progress
                }
                if (newFile.error && !oldFile.error) {
                    // error
                }
                if (newFile.success && !oldFile.success) {
                    this.fetchUserProfile()
                    // success
                }
            }
            if (!newFile && oldFile) {
                // remove
                if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/upload/delete?id=' + oldFile.response.id,
                    // })
                }
            }
            // Automatically activate upload
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (this.uploadAuto && !this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        alert(message) {
            alert(message)
        },
        onEditFileShow(file) {
            this.editFile = { ...file, show: true }
            this.$refs.upload.update(file, { error: 'edit' })
        },
        onEditorFile() {
            if (!this.$refs.upload.features.html5) {
                this.alert('Your browser does not support')
                this.editFile.show = false
                return
            }
            let data = {
                name: this.editFile.name,
                error: '',
            }
            if (this.editFile.cropper) {
                let binStr = atob(this.editFile.cropper.getCroppedCanvas().toDataURL(this.editFile.type).split(',')[1])
                let arr = new Uint8Array(binStr.length)
                for (let i = 0; i < binStr.length; i++) {
                    arr[i] = binStr.charCodeAt(i)
                }
                data.file = new File([arr], data.name, { type: this.editFile.type })
                data.size = data.file.size
            }
            this.$refs.upload.update(this.editFile.id, data)
            this.editFile.error = ''
            this.editFile.show = false
        },
        // add folder
        onAddFolder() {
            if (!this.$refs.upload.features.directory) {
                this.alert('Your browser does not support')
                return
            }
            let input = document.createElement('input')
            input.style = "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;"
            input.type = 'file'
            input.setAttribute('allowdirs', true)
            input.setAttribute('directory', true)
            input.setAttribute('webkitdirectory', true)
            input.multiple = true
            document.querySelector("body").appendChild(input)
            input.click()
            input.onchange = (e) => {
                this.$refs.upload.addInputFile(input).then(function() {
                    document.querySelector("body").removeChild(input)
                })
            }
        },
        onAddData() {
            this.addData.show = false
            if (!this.$refs.upload.features.html5) {
                this.alert('Your browser does not support')
                return
            }
            console.log('this.addData', this.addData)
            let file = new window.File([this.addData.content], this.addData.name, {
                type: this.addData.type,
            })
            file.extra = {
                test: 1
            }
            this.$refs.upload.add(file)
        },
        formatSize(size) {
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size.toString() + ' B'
        },
        deleteFileConfirm : async function(file) {
            console.log('file', file)
            let ref = this.fileAppointmentId ? this.$refs.upload[0] : this.$refs.upload
            this.clearErrors()
            const res = await this.$confirm('Удалить Файл', { title: 'Внимание', color: '#f8495a', icon: '', buttonTrueText: 'Да', buttonFalseText: 'Нет' })
            if (res) {
                let id =  file.response && file.response.id || file.id
                let deleted = false
                if (file.success) {
                    this.clearErrors()
                    await this.deleteFile(id)
                    if (this.getErrors.length > 0) {
                        this.$root.$emit('error:show', this.getErrors[0])
                    } else {
                        deleted = true
                    }
                    this.fetchUserProfile()
                }
                if (file.is_link) {
                    const indx = this.files.findIndex(v => v.id == file.id);
                    if (indx >= 0) {
                        deleted = true
                        this.files.splice(indx, 1);
                    }

                }
                if (deleted) {
                    await ref.remove(file)
                }
            }
        },
    }
}
