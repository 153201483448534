<template>
    <v-list-item
            :disabled="readonly"
            class="px-7"
            :value="article.id"
    >
        <!--<v-list-item-avatar size="8" class="mr-2"  :color="getStatusColor(article.status)">
        </v-list-item-avatar>-->
        <v-list-item-content class="d-block py-4" style="overflow: visible; ">
            <v-list-item-subtitle class="author mb-0 d-flex justify-space-between font-weight-medium black--text"
            >
                <div class="grey--text">{{ article.name }}<span class="px-1">·</span>
                    <span class="grey--text">{{ article.duration }} days</span>
                    <span class=" ml-1 grey--text text--darken-1">{{ dateFormattedMomentjs(article.created_at) }}</span>
                </div>
                <div v-if="article.accepted">
                    day {{ article.user_day }}
                </div>

            </v-list-item-subtitle>
            <v-list-item-title class="mt-2 mb-1 text-wrap">{{ article.description }}</v-list-item-title>
            <v-list-item-subtitle style="overflow: visible; line-height: 1" class="text-right align-end d-flex justify-space-between actions" v-if="$slots.default">
                <span class="float-left" :class="article.category == 'program' ? 'red--text' : 'green--text'">{{
                    article.category
                    }}</span> <v-spacer></v-spacer> <slot></slot>
            </v-list-item-subtitle>
        </v-list-item-content>
        <!--<v-list-item-action class=" body-2">
            <span class="font-weight-medium">{{ timeFormattedMomentjs(article.created_at) }}</span>
            <div class="caption grey&#45;&#45;text text&#45;&#45;darken-1">{{ dateFormattedMomentjs(article.created_at) }}</div>
        </v-list-item-action>-->
        <!--<v-list-item-icon class="date align-self-center-old mt-3 ">
            <div>
                <div>
                    <span class="grey&#45;&#45;text">{{ timeFormattedMomentjs(article.created_at) }}</span>
                    <span class="caption ml-1 grey&#45;&#45;text text&#45;&#45;darken-1">{{ dateFormattedMomentjs(article.created_at) }}</span>
                </div>
            </div>

        </v-list-item-icon>-->
    </v-list-item>

</template>

<script>
    import dateTimeMixin from '@/mixins/datetime'
    import colorsMixin from '@/mixins/colors'

    export default {
        name: 'q-list-item',
        mixins: [dateTimeMixin, colorsMixin],
        props: {
            article: {
                type: Object,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {

            }
        },

        created() {

        },
    }
</script>

<style scoped>

</style>
