<template>
    <v-list two-line class="contestsList mt-7">
        <v-list-item-group
                v-if="group"
                v-model="currentContestId"
                color="indigo"
        >
            <slot />
        </v-list-item-group>
        <template v-else>
            <slot />
        </template>
    </v-list>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'q-list',
        props: {
            group: {
                type: Boolean,
                default: true,
            },
            id: {
                default: false,
            },
        },

        data () {
            return {
                currentContestId: false
            }
        },

        created() {

        },

        computed: {
            ...mapGetters({
                getContestsList: 'contests/getContestsList',
            }),
        },

        watch: {
            currentContestId(newVal) {
                const contest = this.getContestsList.find(item => item.id == newVal)

                if (contest) {
                    this.$emit('contest-selected', contest)
                }
            },
            id () {
                this.currentContestId = this.id
            }
        }
    }
</script>

<style scoped>

</style>
