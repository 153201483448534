// @vue/component
import LayoutDefault from '@/layouts/LayoutClient.vue'
import { mapActions, mapGetters } from 'vuex'
import QList from '@/components/common/exhibitions/c-list.vue'
import TasksList from '@/components/common/tasks/c-list.vue'
import QListItem from '@/components/common/exhibitions/c-list-item.vue'
import IdPopup from './id-popup.vue'
import dateTimeMixin from '@/mixins/datetime'
import colorsMixin from '@/mixins/colors'
import cssMixin from '@/mixins/css'
import DoctorProfile from '@/components/doctor/profile.vue'
import { Timer } from 'easytimer.js';
import vueFilePond from "vue-filepond";
import apiConfig from '@/static/apiConfig'

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import TokenService from '@/services/token.service'
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageFilter from 'filepond-plugin-image-filter';


import CropperEditor from 'cropperjs';

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit,
    FilePondPluginImageExifOrientation,
    FilePondPluginImageFilter
);


export default {
    name: 'Cart',

    components: {
        LayoutDefault,
        IdPopup,
        FilePond,
        DoctorProfile,
        TasksList,
        QList,
        QListItem
    },

    mixins: [dateTimeMixin, colorsMixin, cssMixin],

    props: {},

    data () {
        return {
            rooms: [],
            getOrders: [],
            myFiles: [],
            files: [],
            filter: localStorage.getItem('filterExhibitions') || 'official',
            filterOptions: [
                {name: 'Все', value: 'all'},
                {name: 'Мои', value: 'my'},
                //{name: 'Общие', value: 'common'},
            ],
            materials: [
                {id: 1, name: 'Масло'},
                {id: 2, name: 'Пастель'},
                {id: 3, name: 'Маркер'},
                {id: 4, name: 'Карандаш'},
                {id: 5, name: 'Угольный карандаш'},
                {id: 6, name: 'Акварельный карандаш'},
                {id: 7, name: 'Уголь'},
                {id: 8, name: 'Акварель'},
                {id: 9, name: 'Соус'},
                {id: 10, name: 'Сангина'},
                {id: 11, name: 'Чернила'},
                {id: 12, name: 'Акрил'},
                {id: 13, name: 'Гуашь'},
                {id: 14, name: 'Темпера'},
                {id: 15, name: 'Смешанная техника'},
            ],
            materialBases: [
                {id: 1, name: 'Холст (хлопковый)'},
                {id: 2, name: 'Холст (льняной)'},
                {id: 3, name: 'Дерево'},
                {id: 4, name: 'ДСП'},
                {id: 5, name: 'Картон'},
                {id: 6, name: 'Бумага '},

            ],
            exhibition: {},
            currentDay: 1,
            sessionClock: '',
            filterFlow: 'noPinned',
            filterSection: 'exhibitions',
            filterTabs: 0,
            errorMessage: '',
            dialogViewFile: false,
            dialogFile: false,
            dialogViewExhibition: false,
            saving: false,
            dialogExhibition: false,
            validExhibitionForm: false,
            tmpCode: null,
            waiting: false,
            loading: true,
            isCurrentExhibitionAccepted: false,
            editingProfileId: false,
            sessionTimer: false,
            roomsLoaded: false,
            sessionActive: true,
            currentExhibition: null,
            timerStopwatch: null,

            rules: {
                required: v => !!v || 'обязательное',
                min: v => v.length >= 6 || 'минимум 6 символов',
                email: v => /.+@.+\..+/.test(v) || 'E-mail введен неверно',
                passwordMatch: () => this.user.password === this.passwordConfirm || ('пароли не совпадают'),
            }
        }
    },

    computed: {
        ...mapGetters({
            getGoods: 'cart/getGoods',
            getIsUserLoggedIn: 'auth/getIsUserLoggedIn',
            getCart: 'cart/getCart',
            getErrors: 'cart/getErrors',
            getUserData: 'auth/getUserData',
            getUserProfile: 'auth/getUserProfile',
        }),

    },

    /*destroyed() {
        socket.off("connect_error");
        socket.off("private message");
    },*/

    watch: {
        async filter() {
            this.waiting = true
            localStorage.setItem('filterExhibitions', this.filter)
            await this.fetchShopsList({category: this.filter})
            await setTimeout(() => {
                this.waiting = false
            }, 200)
        }
    },

    beforeDestroy() {
        this.clearTimeout()
    },


    async created () {
        console.log('getGoods', this.getGoods)
        //this.timerStopwatch = new Timer();
        await this.fetchCart({ids: this.getGoods})

        this.loading = false
        //await this.startSession(true)

        /*this.timerStopwatch.addEventListener('secondsUpdated',  (e) => {
            this.sessionClock = this.timerStopwatch.getTimeValues().toString()
            // $('#basicUsage').html(timer.getTimeValues().toString());
        });*/

        /*socket.auth = { username: this.getUserData.id };
        socket.connect();

        socket.on("private message", ({ content, from }) => {
            console.log('content on PM', content)
            this.$refs.chat.addMessageToChat(content.chat_id, content)
        });*/

        /*await this.fetchStats({
            own: true,
            period: 'all',
            begin: null,
            end: null,
        })*/
    },

    methods: {
        ...mapActions({
            removeGood: 'cart/removeGood',
            addGood: 'cart/addGood',
            fetchCart: 'cart/fetchCart',
            clearErrors: 'cart/clearErrors',
        }),

        inCart: function (fileId) {
            return this.getGoods.indexOf(fileId) !== -1
        },

        async removeFromCart(fileId) {
            this.loading = true
            await this.removeGood(fileId)
            await this.updateCart()
            this.loading = false
        },

        async updateCart() {
            this.loading = true
            await this.fetchCart({ids: this.getGoods.map(item => item.file_id)})
            this.loading = false
        }

    }
}
