// @vue/component
import LayoutBase from '@/layouts/LayoutBase.vue'
import { mapActions, mapGetters } from 'vuex'
import dateTimeMixin from '@/mixins/datetime'
import colorsMixin from '@/mixins/colors'
import cssMixin from '@/mixins/css'
import Chart from '@/components/chart/lines'

export default {
    name: 'Dashboard',

    components: {
        LayoutBase,
        Chart
    },

    mixins: [cssMixin, dateTimeMixin],

    props: {},

    data () {
        return {
            period: 'week',
            menuPeriod: false,
            filterStats: null,
            filterUser: 'doctors',
            datasets: [],
            datePeriod: [],
            chartOptions: {
                maintainAspectRatio: false,
                fontFamily: '"Noto Sans",sans-serif',
                globals: {
                    defaultFontFamily: '"Noto Sans",sans-serif',
                },
                legend: {
                    labels: {
                        fontFamily: 'Montserrat,sans-serif',
                        padding: 25
                    }
                },
                title: {
                    display: false,
                    text: 'Stats'
                },
                tooltips: {
                    fontFamily: '"Noto Sans",sans-serif',
                    mode: 'index',
                    intersect: false
                },
                responsive: true,
                scales: {

                    xAxes: [{
                        ticks: {
                            fontFamily: '"Noto Sans",sans-serif',
                        },
                        type: 'time',
                        time: {
                            minUnit: 'day',
                            displayFormats: {
                                hour: 'MMM D hA'
                            }
                        },
                        stacked: true,
                    }],
                    yAxes: [{
                        ticks: {
                            fontFamily: '"Noto Sans",sans-serif',
                        },
                        stacked: false
                    }]
                }
            } ,
            periods: [
                'all',
                'week',
                'month',
                'custom',
            ]
        }
    },

    computed: {
        ...mapGetters({
            getStats: 'dnet/getStats',
            getChartData: 'dnet/getChartData',
        }),
        datePeriodText () {
            return this.datePeriod.join(' — ')
        },
    },

    watch: {
        async period (newPeriod) {
            await this.updateDasboard()
        },
        async datePeriod (newPeriod) {
            await this.updateDasboard()
        },
        async filterStats(category) {
            await this.updateDasboard()
        },
    },

    async created () {
        await this.updateDasboard()
    },

    methods: {
        ...mapActions({
            fetchStats: 'dnet/fetchStats',
            fetchChartData: 'dnet/fetchChartData',
        }),

        async updateDasboard() {
            await this.fetchStats({
                period: this.period,
                begin: this.datePeriod.length >= 1 ? this.datePeriod[0] : null,
                end: this.datePeriod.length == 2 ? this.datePeriod[1] : null,
            })

            await this.fetchChartData({
                period: this.period,
                category: this.filterStats,
                begin: this.datePeriod.length >= 1 ? this.datePeriod[0] : null,
                end: this.datePeriod.length == 2 ? this.datePeriod[1] : null,
            })
            this.datasets = {datasets: this.getChartData}
            console.log('this.datasets', this.datasets)
        }
    }
}
