import LayoutDefault from '@/layouts/LayoutDefault.vue'
import moment from 'moment-timezone'
import {mask} from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import cssMixin from '@/mixins/css'
import { SnotifyPosition } from 'vue-snotify'
import PerfectScrollbar from 'perfect-scrollbar'
export default {
    name: 'DoctorProfile',
    components: {
        LayoutDefault
    },
    mixins: [cssMixin],
    props: {
      id: {
          required: true
      },
      back: {
          type: Boolean,
          required: false,
          default: false,
      }
    },
    directives: {
        mask
    },
    data: () => ({
        filterTabs: 0,
        filter: 'info',
        timer: null,
        waiting: true,
        loadingTopics: false,
        showPass: false,
        showPass2: false,
        passwordConfirm: '',
        menuDate: false,
        validCode: false,
        inviter: [],
        invite: null,
        email: '',
        lastName: '',
        firstName: '',
        errorMessage: '',
        editedItem: {
            first_name: '',
            last_name: '',
            password: '',
            phone: '',
            email: ''
        },
    }),

    async created () {
        this.errorMessage = ''
        this.waiting = true;
        this.invite = this.$route.params.code
        console.log('invite', this.invite)
        await this.clearErrors()

        this.loadingTopics = true
        await this.fetchTopics()
        this.loadingTopics = false


        //let profile = await this.checkRegCode(this.invite)
        let profile = await this.fetchProfile(this.id)
        console.log('profile', profile)
        if (this.getErrors.length > 0) {
            this.$root.$emit('error:show', this.getErrors[0])
            this.errorMessage = this.getErrors[0]
        } else if (profile && profile.email) {
            //await this.postLogOut()
            this.validCode = true
            profile.password = ''
            this.editedItem = profile
            // this.inviter = profile.inviter
            // this.firstName = profile.first_name
            // this.lastName = profile.last_name
        } else {
            this.errorMessage = 'Invalid Link'
        }
        await this.fetchSpecialtiesList()
        this.waiting = false
    },

    mounted() {
        this.$nextTick(() => {
            new PerfectScrollbar('.perfect', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            });
        })
    },

    beforeDestroy () {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    },

    computed: {
        ...mapGetters({
            getDnetList: 'dnet/getDnetList',
            getProfile: 'dnet/getProfile',
            getTopicsList: 'chats/getTopicsList',
            getSpecialtiesList: 'dnet/getSpecialtiesList',
            getGenders: 'dnet/getGenders',
            getErrors: 'dnet/getErrors',
        }),

        initials() {
            if (this.getProfile) {
                return this.getProfile.last_name.substring(0, 1) + (this.getProfile.first_name.substring(0, 1))
            } else {
                return '—'
            }
        },

        computedDateFormattedMomentjs () {
            return this.editedItem.birthday ? moment(this.editedItem.birthday).locale('de').format('DD/MM/YYYY') : ''
        },

        minRules: function() {
            return [
                v => v.length == 0 || v.length >= 6 || 'Min 6 characters',
            ]
        },

        passwordRules: function() {
            return [
                () => this.editedItem.password === this.passwordConfirm || ('The password you entered don\'t match'),
            ]
        },

        requiredRules: function() {
            return [
                v => !!v || 'required',
            ]
        },
        requiredEmailRules: function() {
            return [
                v => !!v || 'required',
                v => !v || /.+@.+\..+/.test(v) || 'wrong email',
            ]
        },
        requiredUrlRules: function() {
            return [
                v => !!v || 'required',
                (v) => this.isURL(v) || "URL is not valid",
            ]
        },
    },

    methods: {
        ...mapActions({
            saveDnet: 'dnet/saveDnet',
            fetchProfile: 'dnet/fetchProfile',
            fetchSpecialtiesList: 'dnet/fetchSpecialtiesList',
            clearErrors: 'dnet/clearErrors',
            fetchTopics: 'chats/fetchTopics',
        }),

        saveDate (date) {
            this.$refs.menuDate.save(date)
        },

        isURL(str) {
            let url;

            try {
                url = new URL(str);
            } catch (_) {
                return false;
            }

            return url.protocol === "http:" || url.protocol === "https:";
        },

        save: async function() {
            try {
                this.clearErrors()

                if (!this.$refs.formDnet.validate()) {
                    return false
                }

                this.editedItem.id = this.id

                await this.saveDnet(
                    this.editedItem
                )
                if (this.getErrors.length > 0) {
                    this.$root.$emit('error:show', this.getErrors[0])
                    this.errorMessage = this.getErrors[0]
                } else {
                    this.editedItem.password = this.passwordConfirm = ''
                    this.$snotify.simple('Your profile was updated!', 'Success!',
                        {
                            toast: {
                                position: SnotifyPosition.centerTop,
                            },
                            position: SnotifyPosition.centerTop,
                            timeout: 3000,
                            backdrop: 0.2,
                            showProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false
                        })
                    await this.fetchProfile(this.id)
                    this.$emit('back')
                    /*if (this.editedItem.id) {
                        await this.fetchDnetContent(this.editedItem.id)
                    }*/
                }

            } catch (e) {
                console.log('error', e)
            }
        },

    }
}
