import { user } from '@/config/api'
import ApiService from '@/services/api.service'

const state = () => ({
  userList: [],
  cities: [],
  permissions: [],
  servicesList: [],
  countryList: [],
  userItemsList: [],
  userLatestList: [],
  fileContent: '',
  userTotal: 0,
  userLimitOnPage: 10,
  fileTypes: [
    'common',
    'personal',
    'results'
  ],
  userRelations: [
    {text: 'husband_wife', value: 'husband_wife'},
    {text: 'son_daughter', value: 'son_daughter'},
    {text: 'brother_sister', value: 'brother_sister'},
    {text: 'grandpa_grandma', value: 'grandpa_grandma'},
    {text: 'uncle_aunt', value: 'uncle_aunt'}
  ],
  insurances: [
    {
      value: 'private', text: 'Private'
    },
    {
      value: 'public', text: 'Public'
    },
    {
      value: 'self-payer', text: 'Self-Payer'
    }
  ],
  bloodGroups: [
    {
      value: 'O+', text: 'O+'
    },
    {
      value: 'O-', text: 'O-'
    },
    {
      value: 'A+', text: 'A+'
    },
    {
      value: 'A-', text: 'A-'
    },
    {
      value: 'B+', text: 'B+'
    },
    {
      value: 'B-', text: 'B-'
    },
    {
      value: 'AB+', text: 'AB+'
    },
    {
      value: 'AB-', text: 'AB-'
    }
  ],
  content: null,
  errors: [],
  itemContent: [],
  related: [],

})

const getters = {
  getUserRelations: state => state.userRelations,
  getFileContent: state => state.fileContent,
  getUserList: state => state.userList,
  getCities: state => state.cities,
  getPermissions: state => state.permissions,
  getCountryList: state => state.countryList,
  getServicesList: state => state.servicesList,
  getBloodGroups: state => state.bloodGroups,
  getInsurances: state => state.insurances,
  getFileTypes: state => state.fileTypes,
  getPopularList: state => state.popularList,
  getUserLimitOnPage: state => state.userLimitOnPage,
  getUserItemsLimitOnPage: state => state.userItemsLimitOnPage,
  getUserContent: state => state.content,
  getErrors: state => state.errors
}

const actions = {

  async fetchUserList ({ commit, getters }, params) {
    const formattedParams = {
      ...params,
      limit: params.limit || getters.getUserLimitOnPage,
      offset: (getters.getUserLimitOnPage * (parseInt(params.page) || 1)) - getters.getUserLimitOnPage
    }
    try {
      //commit('START_LOAD_PATIENT_LIST')
      const response = await ApiService.get(params.doctorId ? user.doctorList(params.doctorId) : user.list, {
        params: {
          language: 'en',
          ...formattedParams
        },
        useCache: true
      })

      commit('SET_PATIENT_LIST', {
        payload: response.data.data,
        headers: response.headers
      })
    } catch (err) {
      console.log(err)
      throw err
    }
  },

  async fetchAllList ({ commit, getters }, params) {
    const formattedParams = {
      ...params,
      limit: params.limit || getters.getUserLimitOnPage,
      offset: (getters.getUserLimitOnPage * (parseInt(params.page) || 1)) - getters.getUserLimitOnPage
    }
    try {
      const response = await ApiService.get(user.all, {
        params: {
          language: 'en',
          ...formattedParams
        },
        useCache: true
      })
      commit('SET_PATIENT_LIST', {
        payload: response.data.data,
        headers: response.headers
      })
    } catch (err) {
      throw err
    }
  },

  async fetchUserContent ({ commit }, alias) {
    try {
      const { data: { data } } = await ApiService.get(user.content(alias), {
        params: {
          language: 'en'
        }
      })
      commit('SET_PATIENT', data)
      return data
    } catch (err) {
      throw err
    }
  },

  async fetchCities ({ commit }, options) {
    try {
      const { data: { data } } = await ApiService.get(user.citiesByCountry(options.countryId), {
        params: {
          search: options.search
        }
      })
      console.log('data', data)
      commit('SET_CITIES', {payload: data})
      return data
    } catch (err) {
      throw err
    }
  },

  async clearCities ({ commit }, options) {
    commit('SET_CITIES', {payload: []})
  },

  async fetchFileContent ({ commit }, alias) {
    try {
      commit('CLEAR_FILE')
      const { data } = await ApiService.get(user.file(alias), {
        params: {
          language: 'en'
        }
      })
      commit('SET_FILE', data)
      return data
    } catch (err) {
      throw err
    }
  },

  async fetchServicesList ({ commit }) {
    try {
      const { data: { data } } = await ApiService.get(user.services)
      commit('SET_SERVICES', data)
      return data
    } catch (err) {
      throw err
    }
  },

  async fetchCountryList ({ commit }) {
    try {
      const { data: { data } } = await ApiService.get(user.countries)
      commit('SET_COUNTRIES', data)
      return data
    } catch (err) {
      throw err
    }
  },

  async fetchPermissions ({ commit }, patientId) {
    try {
      const { data: { data } } = await ApiService.get(user.permissions + '?patient_id=' + patientId)
      commit('SET_PERMISSIONS', data)
      return data
    } catch (err) {
      throw err
    }
  },

  async fetchItemContent ({ commit }, alias) {
    try {
      commit('START_LOAD_PATIENT_ITEM')
      const { data: { data } } = await ApiService.get(user.itemContent(alias), {
        params: {
          language: 'en'
        }
      })
      commit('SET_ITEM', data)
      return data
    } catch (err) {
      throw err
    }
  },

  async saveUser ({commit}, data) {
    try {
      let response
      if (data.file && typeof data.file == 'object') {
        let formData = new FormData();
        console.log('file')
        /*
            Add the form data we need to submit
        */
        formData.append('files[]', data.file, data.file.name);
        for ( let key in data ) {
          if (key != 'file') {
            formData.append(key, data[key]);
          }
        }

        /*
          Make the request to the POST /single-file URL
        */
        response = await ApiService.post( user.save,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        );
      } else {
        response = await ApiService.post(user.save, data)
      }
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
        return false
      } else {
        return response
      }
    } catch (err) {
      console.log('err', err)
      throw err
    }
  },

  async updateFile ({commit}, data) {
    try {
      const response = await ApiService.post(user.updateFile, data)
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
        return false
      } else {
        return response
      }
    } catch (err) {
      console.log('err', err)
      throw err
    }
  },

  async vote ({commit}, data) {
    try {
      const response = await ApiService.post(user.vote, data)
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
        return false
      } else {
        return response
      }
    } catch (err) {
      console.log('err', err)
      throw err
    }
  },

  async fav ({commit}, data) {
    try {
      data.action = 'fav'
      const response = await ApiService.post(user.fav, data)
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
        return false
      } else {
        return response
      }
    } catch (err) {
      console.log('err', err)
      throw err
    }
  },

  async unfav ({commit}, data) {
    try {
      data.action = 'unfav'
      const response = await ApiService.post(user.unfav, data)
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
        return false
      } else {
        return response
      }
    } catch (err) {
      console.log('err', err)
      throw err
    }
  },

	async saveVitalSigns ({commit}, data) {
		try {
			const response = await ApiService.post(user.saveVitalSigns, data)
			if (typeof response.data.error != 'undefined') {
				commit('ADD_ERROR', response.data.error)
				return false
			} else {
				return response
			}
		} catch (err) {
			console.log('err', err)
			throw err
		}
	},


  async deleteAvatar ({commit}, userId) {
    try {
      const response = await ApiService.post(user.deleteAvatar(userId))
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
        return false
      } else {
        return response
      }
    } catch (err) {
      console.log('err', err)
      throw err
    }
  },

  async deleteFile ({commit}, fileId) {
    try {
      const response = await ApiService.post(user.deleteFile, {object_id: fileId})
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
        return false
      } else {
        return response
      }
    } catch (err) {
      console.log('err', err)
      throw err
    }
  },

  async clearUserList ({commit}) {
    commit('CLEAR_PATIENTS')
  },

  async clearErrors ({commit}) {
    commit('CLEAR_ERRORS')
  },

  async clearFile ({commit}) {
    commit('CLEAR_FILE')
  }
}

const mutations = {
  CLEAR_ERRORS (state) {
    state.errors = []
  },

  CLEAR_PATIENTS (state) {
    state.userTotal = 0
    state.userList = []
  },

  CLEAR_FILE (state) {
    state.fileContent = ''
  },

  ADD_ERROR (state, payload) {
    state.errors.push(payload)
  },
  START_LOAD_PATIENT_LIST(state) {
    state.userTotal = 0
    state.userList = []
  },
  START_LOAD_PATIENT_ITEMS_LIST(state) {
    state.userItemsTotal = 0
    state.userItemsList = []
    state.content = []
  },
  START_LOAD_PATIENT_ITEM(state) {
    state.content = []
    state.itemContent = []
  },
  SET_COUNTRIES(state, payload) {
    state.countryList = payload
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload
  },
  SET_SERVICES(state, payload) {
    state.servicesList = payload
  },
  SET_PATIENT_LIST (state, { payload, headers }) {
    state.userTotal = parseInt(headers['x-items-total'])
    state.userList = payload

    /*let userListTags = []
    for (let i = 0; i < payload.length; i++) {
      userListTags = [...userListTags, ...payload[i].tags]
    }
    state.userListUniqueTags = userListTags.filter((tag,index) => {
      return index === userListTags.findIndex(obj => {
        return JSON.stringify(obj) === JSON.stringify(tag)
      })
    })*/
  },
  SET_PATIENT_ITEMS_LIST (state, { payload, headers }) {
    state.userItemsTotal = parseInt(headers['x-items-total'])
    state.userItemsList = payload.items
    state.content = payload.user
  },
  SET_PATIENT_LATEST_LIST (state, { payload, headers }) {
    state.userLatestList = payload
  },
  SET_POPULAR_LIST (state, { payload, headers }) {
    state.popularList = payload
  },
  SET_TOP_LIST (state, { payload, headers }) {
    state.topList = payload
  },
  SET_CITIES (state, { payload }) {
    state.cities = payload
  },
  SET_PATIENT (state, payload) {
    state.content = payload
  },
  SET_FILE (state, payload) {
    state.fileContent = payload
  },
  SET_ITEM (state, payload) {
    state.itemContent = payload.item
    state.content = payload.user
  },
  SET_PATIENT_RELATED (state, payload) {
    state.related = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
