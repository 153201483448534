<template>
    <layout-default :is-loaded="true">
    <v-layout
            class="fill-height"
            text-center
            wrap
    >
        <v-row
                style="position:relative;"
                align="center"
                justify="center"
        >
            <v-col
                    class=""
                    cols="12"
                    sm="8"
                    md="4"
            >
            <h1>404</h1>
            <h2 class="text-uppercase">Page not found</h2>
            </v-col>
        </v-row>

    </v-layout>
    </layout-default>
</template>

<style>

</style>

<script>
  import  LayoutDefault from '@/layouts/LayoutClient.vue'
  export default {
    components: {
      LayoutDefault
    },
    name: 'NotFound'
  }
</script>
