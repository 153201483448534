// @vue/component
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'PasswordReset',

    components: {},

    mixins: [],

    props: {},

    data () {
        return {
            state: 'init',
            email: '',
            code: '',
            sending: false,
            showPass: false,
            showPass2: false,
            password: '',
            passwordConfirm: '',
            rules: {
                required: v => !!v || 'обязательное',
                min: v => v.length >= 6 || 'минимум 6 символов',
                email: v => /.+@.+\..+/.test(v) || 'E-mail введен неверно',
                emailMatch: () => ('пароли не совпадают'),
            }
        }
    },

    computed: {
        ...mapGetters({
            getUserData: 'auth/getUserData',
            getCountryList: 'user/getCountryList',
            getErrors: 'auth/getErrors'
        }),
    },

    watch: {},

    created () {},

    methods: {
        ...mapActions({
            'postResetPasswordFormData': 'auth/postResetPasswordFormData',
            'postResetPasswordCheck': 'auth/postResetPasswordCheck',
            'clearErrors': 'auth/clearErrors',
        }),

        stateAction() {
          if (this.state == 'init') {
              this.sendCode()
          }  else {
              this.savePassword()
          }
        },

        async sendCode() {
            const isValid = this.$refs.resetForm.validate()
            if (!isValid) {
                return false
            }
            this.clearErrors()
            this.sending = true
            try {
                await this.postResetPasswordFormData({
                    email: this.email
                })
                if (!this.getErrors.length) {
                    this.state = 'code'
                }
            } catch (e) {

            }
            this.sending = false
        },

        async savePassword() {
            const isValid = this.$refs.resetForm.validate()
            if (!isValid) {
                return false
            }
            this.clearErrors()
            this.sending = true
            try {
                await this.postResetPasswordCheck({
                    email: this.email,
                    code: this.code,
                    password: this.password,
                })
                if (!this.getErrors.length) {
                    this.state = 'success'
                }
            } catch (e) {

            }
            this.sending = false
        },
    }
}
