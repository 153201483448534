<template>
  <v-app dark id="app">
    <v-dialog v-model="errorPopup" persistent max-width="400">
      <div style="position: relative;">

        <div style="font-size: 24px; line-height: 32px; font-weight: 700; text-align: center; text-transform: uppercase; letter-spacing: 0.5px; background-color: white;  padding: 16px;  padding-top: 32px;">
          <!--<span style="opacity: 0.5;">error</span>-->
          <img src="@/assets/error1.png" style="width: 45px;" alt="">
          <v-btn  style="position:absolute; right: 12px; top: 5px;" icon light @click.stop="errorPopup = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="font-size: 16px; background-color: white;  padding: 16px; padding-top: 8px;">
          <div style="margin-bottom: 32px; text-align: center; margin-top: 0px;">
            {{ errorMsg }}
          </div>
        </div>
      </div>
    </v-dialog>
    <v-navigation-drawer

            class="px-0"
            app
            clipped
            v-model="drawer"
            temporary
    >
      <v-list

flat
      >
        <v-subheader>Menu</v-subheader>

        <v-list-item-group

                v-model="group"
                active-class="primary--text grey lighten-3"
        >
          <v-list-item :to="link.to"
                       :exact="true" v-for="link in filteredLinks"
                       :key="'link-' + link.name">
            <v-list-item-title>{{ link.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
            v-if="getIsUserLoggedIn"
            dark
            clipped-left
            style="z-index: 100;"
            app
            class="mx-auto flex-nowrap"
            flat
    >
      <v-app-bar-nav-icon class="hidden-md-and-up mr-3" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <div>
<!--        <v-img width="90"  src="@/assets/logo.svg"></v-img>-->
        <v-img width="50" style="border-radius: 30px;" src="@/assets/logo.jpg"></v-img>
      </div>

      <v-spacer></v-spacer>


      <v-tabs
centered optional
              class="hidden-sm-and-down"

      >
        <v-tabs-slider :color="$route.name != 'Profile' ? null : 'white'" ></v-tabs-slider>
        <v-tab
                v-for="link in filteredLinks"
                :key="link.name"
                :to="link.to"
                :exact="false"
        >
          {{ link.name }}
        </v-tab>
      </v-tabs>

      <template v-if="!getIsUserLoggedIn ">

        <router-link style="text-decoration: none;" @click="currentTab = false" :to="`/login?redirect=${$router.history.current.path}`"><v-icon class=""   >mdi-login</v-icon></router-link>

        <!--<v-btn class="mr-3" @click="currentTab = false" icon  depressed small tile :to="`/login?redirect=${$router.history.current.path}`"><v-icon color="" >mdi-login</v-icon></v-btn>--> &nbsp;
        <!--/&nbsp; <v-btn  class="white&#45;&#45;text" text  tile to="/registration">Sign Up </v-btn>-->
      </template>






      <div class="text-no-wrap d-flex align-center" v-else>



            <!--<v-tab key="login" style="opacity: 0.8" active-class="primary&#45;&#45;text " class="px-0 mr-3 v-tab text-no-wrap" v-if="getUserData" :to="{name: 'Profile'}">{{ getUserData.name }}</v-tab>-->


        <router-link active-class="v-tab--active" class="primary--text hidden-sm-and-down px-0 mr-3 v-tab text-no-wrap" v-if="getUserProfile" :to="{name: 'Profile'}">{{ getUserProfile.first_name }} {{ getUserProfile.last_name }}</router-link>

        <router-link v-if="getUserProfile" class="mr-3" :to="{name: 'Profile'}">
          <v-avatar v-if="getUserProfile.avatar"  size="40">
            <v-img :to="{name: 'Profile'}" :src="getUserProfile.avatar" />
          </v-avatar>
          <avatar v-else size="small" color="primary" class="primary"
                  :name="getUserProfile.first_name"></avatar>
        </router-link>


        <v-icon color=""  @click="logout">mdi-logout</v-icon><!--

        --><!--<v-icon @click="resetUser" color="red" class="">mdi-close</v-icon>-->

      </div>



      <!--<v-avatar
              class="hidden-sm-and-down"
              color="grey darken-1 shrink"
              size="32"
      ></v-avatar>-->
    </v-app-bar>

    <div style="position: absolute; top: 16px; right: 16px; z-index: 10;" class="text-right" v-if="!getIsUserLoggedIn && $route.name == 'Login'">
      <router-link   :to="{name: 'About'}"> О Галерее </router-link>
    </div>

    <v-app-bar
        v-if="!getIsUserLoggedIn && $route.name != 'Login'"
        dark
        clipped-left
        style="z-index: 100;"
        app
        class="mx-auto flex-nowrap"
        flat
    >

      <div>
        <!--        <v-img width="90"  src="@/assets/logo.svg"></v-img>-->
        <v-img width="50" style="border-radius: 30px;" src="@/assets/logo.jpg"></v-img>
      </div>

      <v-spacer></v-spacer>




      <template >

        <router-link style="text-decoration: none;" @click="currentTab = false" :to="`/login`">Войти </router-link>

        <!--<v-btn class="mr-3" @click="currentTab = false" icon  depressed small tile :to="`/login?redirect=${$router.history.current.path}`"><v-icon color="" >mdi-login</v-icon></v-btn>--> &nbsp;
        <!--/&nbsp; <v-btn  class="white&#45;&#45;text" text  tile to="/registration">Sign Up </v-btn>-->
      </template>


    </v-app-bar>


    <v-main>
      <transition mode="out-in" name="slide-right">
        <router-view></router-view>
      </transition>
    </v-main>
    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import "perfect-scrollbar/css/perfect-scrollbar.css"
  import Avatar from '@/components/avatar'
  export default {
    components: {Avatar},
    data: () => ({
      errorPopup: false,
      currentTab: 'Home',
      errorMsg: '',
      drawer: false,
      group: null,
      links: [
        //{'name': 'Home', to: '/'},
        //{'name': 'Add Question', to: '/question'},
        {'name': 'Конкурсы', to: '/contests'},
        {'name': 'Выставки', to: '/exhibitions'},
        {'name': 'Новости', to: '/articles'},
        //{'name': 'Магазин', to: '/shop'},
        // {'name': 'Спец. проекты', to: '/spec'},
        //{'name': 'Прогуляться', to: '/org'},
        //{'name': '#Актуализм', to: '/actualism'},
        {'name': 'Команда', to: '/team'},
        // {'name': 'Профиль', 'auth': true, to: '/profile'},
        /*{'name': 'Patient Cab', to: {name: 'Cabinet', params: {code: '21eb95b7f01f'}}},
        {'name': 'Moderation', to: '/moderation'},
        {'name': 'Dashboard', 'admin': false, to: '/dashboard'},*/
        //{'name': 'Invite', to: '/invite/test1'},
      ],
    }),
    computed: {
      ...mapGetters({
        getIsUserLoggedIn: 'auth/getIsUserLoggedIn',
        getUserData: 'auth/getUserData',
        getUserProfile: 'auth/getUserProfile',
        isAdmin: 'auth/isAdmin',
      }),

      filteredLinks(){
        return this.links.filter (link => !link.auth || this.getIsUserLoggedIn)
      }
    },
    methods: {
      ...mapActions({
        postLogOut: 'auth/postLogOut',
        postReset: 'auth/postReset',
        fetchUserProfile: 'auth/fetchUserProfile',
      }),
      showError (msg) {
        console.log(msg)
        this.errorMsg = msg
        this.errorPopup = true;
        return true
      },
      async logout() {
        await this.postLogOut()
        //this.$router.push('/login')
        this.$router.go()
      },

      async resetUser() {
        const res = await this.$confirm('Reset this user?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
        if (res) {
          await this.postReset()
          this.$router.go()
        }
      }
    },
    async created() {

    },
    watch: {
      getIsUserLoggedIn(logged) {
        if (logged) {
          this.fetchUserProfile()
        }
      }
    },
    async mounted() {
      this.$root.$on('error:show', (msg) => {
        this.showError(msg)
      })
      if (this.getIsUserLoggedIn) {
        await this.fetchUserProfile()
      }
    }
  }
</script>

<style lang="scss">

  .cursive {
    font-family: 'Neucha', cursive !important;
  }

  .statsBlock {
    width: 100%;
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .value {
    /*font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;*/
    font-size: 80px;
    font-weight: 700;
    line-height: 1;
    color: #00d2d6;
    text-align: center;

  }
  .value--small {
    color: #00d2d6;
    font-size: 84px;
    line-height: 1;
    text-align: center;
  }
  .label {
    text-align: center;
    font-size: 16px;
    line-height: 1;
  }
  .label--small {

    font-size: 28px;
    line-height: 1;
  }
  .borders-all, .v-avatar.borders-all {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .v-avatar.empty {
    background-color: transparent;
  }

  /*@media (max-width: 1340px) {
    .value {
      font-size: 120px;

    }
    .value--small {
      font-size: 60px;

    }
    .label {
      font-size: 48px;
    }
    .label--small {
      font-size: 18px;
    }
  }*/

  html.app {
    font-size: 16px;
  }



  .darkColor {
    background-color: #444;
  }

  .list-large .v-list-item__title{
    font-size: 24px;
    /*font-family: Montserrat, Helvetica, Arial, sans-serif;*/
  }

  #app .snotify-success {
    border-left: none;
  }


  #app .snotify-icon {
    right: 0px;
  }

  #app .hoverable-icon, #app .hoverable-icon-inverse:hover {
    color: #777;
  }

  #app .hoverable-icon:hover, #app .hoverable-icon-inverse {
    color: #00d2d6;
  }



  .filterTabs.theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs.filterTabs .v-tab--active::before {
    opacity: 0 ;
  }

  #app .body-2, #app .display-2, #app .caption, #app .overline, #app .body-1 {
    font-family: 'Noto Sans', Helvetica, Arial, sans-serif !important;
  }

  .subFilter {
    line-height: 1;
    font-size: 20px;
    text-transform: uppercase;
  }

  #app button.v-size--large {
    font-size: 20px;
  }

  #app .v-list-item .v-list-item__title {
    line-height: 1.35;
  }

  .subFilterTitle {
    margin-right: 16px;
    font-size: 32px;
    font-weight: 500;
    color: black;
  }



  #app .vac-messages-container {
    min-height: 300px;
  }

  #app .vac-box-footer {
    background-color: #eceef1;
  }

  #app .theme--light.v-list-item--active:hover::before, #app .theme--light.v-list-item--active::before {
    opacity: 0;
  }

  .filterTabs {
    top: -1px;
    position: relative;
  }

  /*.shadowLeft {
    -webkit-box-shadow: 6px -5px 24px 3px rgba(34, 60, 80, 0.09) inset;
    -moz-box-shadow: 6px -5px 24px 3px rgba(34, 60, 80, 0.09) inset;
    box-shadow: 6px -5px 24px 3px rgba(34, 60, 80, 0.09) inset;
  }
  .shadowRight {
    -webkit-box-shadow: -6px -5px 24px 3px rgba(34, 60, 80, 0.09) inset;
    -moz-box-shadow: -6px -5px 24px 3px rgba(34, 60, 80, 0.09) inset;
    box-shadow: -6px -5px 24px 3px rgba(34, 60, 80, 0.09) inset;
  }*/

  .filterTabs .v-tab.v-tab--active {
    border-top: 6px solid #00d2d6;
    color: black;
  }

  .user-avatar.v-avatar {
    font-size: 100px;
    /*overflow: visible;*/
    color: #444;
  }

  .user-avatar-title {
    margin-top: 28px;
    color: #444;
    font-weight: 300;
  }

  .blueGrey {
    background-color: #eceef1 !important;
  }


  .centerToolbar {
    padding: 0px 12px;
    border-radius:0 !important;
  }

  .questionsList .v-list-item:first-child .v-list-item__title, .vac-room-header{
    font-style: italic;
    font-size: 24px;
    line-height: 1;
  }
  .questionsList .v-list-item .v-list-item__title{
   font-size: 16px;
    white-space: normal;
  }
  .questionsList .author, .questionsList .date{
   font-size: 14px;
  }

  .filterTabs .v-tab{
    /*font-family: Montserrat, Helvetica, Arial, sans-serif;*/
    border-top: 6px solid transparent;
    font-size: 20px;
    width:50%;
    line-height: 1 ;
    font-weight: 500;
  }

  .filterTabs.mobile {
    width: 100%;
  }

  .v-tabs.mobile .v-tab{
    font-size: 16px;
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: 50% !important;
  }

  .v-btn__content {
    /*font-family: Montserrat, Helvetica, Arial, sans-serif;*/
  }


#app {
  font-family: 'Noto Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

}



#app .vac-message-box {
  max-width: 100% !important;
  min-width: 100% !important;
}

.vac-message-container {
  min-width: 100% !important;
  padding: 4px 0px !important;
}

.vac-message-container-offset {

  margin-top: 0px !important;
}

#app .vac-offset-current {
  margin-left: 0% !important;
}

#app .vac-message-current {
  /*background-color: #f7f7f7 !important;*/
  background-color: #fff !important;
  box-shadow: none;
}

.vac-menu-left{
  right: 15px  !important;
}

.vac-options-me{
  background-color: #f7f7f7 !important;
}

#app .centerToolbar {
  border-bottom: 1px solid rgb(235, 238, 240) !important;
  background-color: #333;
}


.v-main {
  /*background-color: #fbfcfd !important;*/
}

#app .vac-text-started {
  font-style: normal;
}

#app .theme--light.v-btn--active::before {
  opacity: 0;
}

div.vac-card-window {
  border-radius: 0;
  border-right: 1px solid rgba(77, 80, 85, 0.1);
}

#app .vac-container-scroll {
  /*background-color: #fbfcfd;*/
  background-color: #fff;
  margin-top: 0px;
  position: relative;
}

.monotype, .monotype .v-btn__content {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.actions .v-divider--vertical {
  margin: 0px 12px;
  height: 26px;
  position: relative;
  top: 5px;
}

.rightBorderRounded {
  .vac-card-window {
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
  }

  .vac-box-footer {
    border-bottom-right-radius: 12px;

  }
  .vac-room-header.vac-app-border-b {
    border-top-right-radius: 12px;
  }
}

#app .vac-message-card {
  font-size: 16px;
  box-shadow: none;
  padding: 8px 28px;

  border-radius: 0px;
  white-space: normal;
}

#app .vac-message-box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.vac-messages-container > div > span div:last-of-type .vac-message-box{
  border-bottom: none;
}

#app .vac-text-timestamp {
  font-size: 14px;
  font-family: 'Noto Sans',sans-serif;
  font-weight: 500;
}

#app .vac-text-username {
  color: #000;
  font-weight: 500;
  font-size:  14px;
}

#app .vac-message-container {
  background-color: white;
}

  .vac-room-header.vac-app-border-b {
    position: relative !important;
    height: auto !important;
  }

 #app .vac-blur-container{
    box-shadow: none !important;
    background-color: transparent !important;
    background: none !important;
  }

#app .vac-card-window {
  box-shadow: none;

}

#app .vac-box-footer {
  padding: 28px;
}

#app .vac-room-header {
  padding: 28px 12px;

}

#app .vac-messages-container {
  padding: 0px 18px 18px;
  padding: 0px 0px 0px;
}

#app .vac-icon-textarea svg {
  margin-right: 0px !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<style>

  /* #### Generated By: http://www.cufonfonts.com #### */

  .top-navigation .v-btn.v-size--default{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
  }

  @font-face {
    font-family: 'Steelfish-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Steelfish-Regular'), url('~@/assets/fonts/steelfish.woff') format('woff');
  }


  @font-face {
    font-family: 'Steelfish-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Steelfish-Bold'), url('~@/assets/fonts/steelfish bd.woff') format('woff');
  }


  @font-face {
    font-family: 'SteelfishOutline-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('SteelfishOutline-Regular'), url('~@/assets/fonts/steelfish outline.woff') format('woff');
  }

  body .v-application.theme--dark {
    /*font-family: "Steelfish-Regular","'Noto Sans' Condensed", sans-serif;*/
    font-family: 'Noto Sans',sans-serif ;
    /*letter-spacing: 5px;*/
  }

  .v-application.theme--dark h3, body .v-application .subtitle-1 {
    /*font-family: "Steelfish-Regular","'Noto Sans' Condensed", sans-serif;*/
    font-family: 'Noto Sans',sans-serif !important;
    /*letter-spacing: 5px;*/
  }

  body .v-application .display-3, body h1 {
    /*font-family: "Steelfish-Regular","'Noto Sans' Condensed", sans-serif !important;*/
    font-family: 'Noto Sans',sans-serif ;
    letter-spacing: 5px !important;
  }

  body .v-btn, body .v-list-item {
    font-family: 'Noto Sans',sans-serif ;
    /*letter-spacing: 5px;*/
  }

  #bottomPanel {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 700px;
    height: 350px;
  }

  #app .v-data-table th {
    font-size: 0.875rem;
    /*height: 48px;*/
  }

  .leftMenu .theme--dark.v-list-item--active:hover::before, .leftMenu .theme--dark.v-list-item--active::before {
    opacity: 0;

  }

  .leftMenu .theme--dark.v-list-item--active {
    background-color: black;
    color: white;
    border-left: 4px solid #ff0014;
  }

  .leftMenu .v-list-item--link:hover {
    /*background-color: #ff0014;*/
    box-shadow: inset 256px 0 0 0 #ff0014;
  }
  .leftMenu .v-list-item--link {
    transition: all 0.3s linear;
  }

  .leftMenu .v-icon.theme--dark, .leftMenu .v-list-item--active .v-icon.theme--dark {
    color: #888;
    transition: color 0.3s linear;
  }

  .leftMenu .v-list-item--link:hover .v-icon.theme--dark, .leftMenu .v-list-item--link:hover .v-list-item--active .v-icon.theme--dark {
    color: #fff;
  }

  .leftMenu {
    background-color: #1a1a1d !important;
  }

  .gradient {
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: #0a0808;
    background: -moz-linear-gradient(top,#0a0808 0,#ff0013 100%);
    background: -webkit-linear-gradient(top,#0a0808 0,#ff0013 100%);
    background: linear-gradient(to bottom,#0a0808 0,#ff0013 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0a0808',endColorstr='#ff0013',GradientType=0);
    opacity: 0;
  }

  .gradient-wrapper:hover .gradient {
    opacity: 1;
    height: 100%;
  }

  .v-card__text {
    position: relative;
    z-index: 2;
  }

  .pageTitle {
    font-size: 72px;
    font-weight: normal;
    margin: 0;
    outline: 0;
    line-height: 1;
    text-transform: uppercase;
    font-family: "Steelfish-Regular";
    letter-spacing: 5px;
  }

  body .v-application .steelFish {
    font-family: "Steelfish-Regular" !important;
    letter-spacing: 2px;
  }



  .v-content__wrap, .v-application--wrap {
    background: transparent !important;
  }

  body pre {
    font-family: 'Noto Sans',sans-serif !important;
    white-space: normal;
  }

  body .colorGray{
    color: rgba(0,0,0,.6);
  }

  body .fontCaption {
    line-height: 1.35;
    font-size: 18px;
    font-weight: 500;
  }

  .missionContent {
    background-color: #1a1a1d;
    position: relative;
    /*min-height: 420px;*/
  }

  body .v-application .v-btn.headerBtn {
    height: 180px !important;
    width: 180px !important;
    border-style: solid;
    border: 5px solid #acc6e9 !important;
  }

  body .v-application .headline, body .v-application .title {
    line-height: 2rem;
    /*font-family: 'Noto Sans',sans-serif !important;*/
    /*font-family: 'Noto Sans',sans-serif !important;*/
  }

  body .v-application {
    font-family: 'Noto Sans',sans-serif;
    /*font-family: 'Noto Sans',sans-serif;*/
  }

  .v-dialog header {
    display: none;
  }

  .v-dialog.withHeader header {
    display: block;
    border-radius: 0px;
  }

  .v-dialog .v-card__text.body-1 {
    /*color: black !important;*/
    padding-top: 26px !important;
  }


  slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
  }

  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
  }

  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
  }

  .prizeSlot {
    max-width: 10% !important;
  }

  .respPadding {
    padding: 0px 150px;
  }


  .missionTab {
    width: 20% !important;
    padding-right: 40px;
  }


  @media only screen and (max-width: 1600px) {
    /* For mobile phones: */
    .prizeSlot {
      max-width: 12.5% !important;

    }
    .respPadding {
      padding: 0px 120px;
    }
    .missionTab {
      width: 25% !important;
      padding-right: 40px;
    }
  }

  @media only screen and (max-width: 1264px) {
    /* For mobile phones: */
    .prizeSlot {
      max-width: 16.66666% !important;
    }
    .respPadding {
      padding: 0px 100px;
    }

    .missionTab {
      width: 33.333% !important;
      padding-right: 40px;
    }

  }

  @media only screen and (max-width: 1100px) {
    /* For mobile phones: */
    .prizeSlot {
      max-width: 20% !important;
    }
    .respPadding {
      padding: 0px 80px;
    }


  }

  @media only screen and (max-width: 960px) {
    /* For mobile phones: */
    .prizeSlot {
      max-width: 25% !important;
    }
    .respPadding {
      padding: 0px 50px;
    }

    .missionTab {
      width: 50% !important;
      padding-right: 40px;
    }
  }

  .stats .v-text-field__slot {
    padding-right: 10px;
  }




  body .snotify-info {
    /*border-left: 3px solid #00d2d6;*/
    border-left: 1px solid #ddd;
  }

  .snotifyToast__buttons button {
    background-color: #00d2d6 !important;
    border-color: #00d2d6 !important;
    color: white !important;
    font-size: 12px !important;
    font-weight: 500;
    letter-spacing: .0892857143em;
    text-indent: .0892857143em;
    white-space: nowrap;
    border-radius: 4px;
    padding: 0px 16px !important;
    height: 28px;
    width: auto !important;
    line-height: 28px;
    margin: 16px;
    margin-left: auto;
    float: right;
  }

  body .snotifyToast__buttons {
    border-top: none;
  }

  body .snotifyToast__progressBar, body .snotifyToast__progressBar__percentage {
    height: 3px;
  }

  body .snotifyToast__progressBar {
    background-color: #eceef1;
  }

  body .snotifyToast__progressBar__percentage {
    background-color: #00d2d6;
  }

  body .snotifyToast__inner {
    display: flex;
    justify-content: center;
    margin: 16px;
    padding: 0px;
    max-height: 120px;
    min-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    background: #f7f7f7;
  }

  body .snotifyToast {
    background: #f7f7f7;
  }

  .snotifyToast {
    border: 1px solid #ddd;
  }

  body .snotifyToast__title {
    font-size: 16px;
    font-weight: 700;
  }

  body .snotifyToast__body {
    font-size: 14px;
  }

   .theme--dark.v-card.v-sheet > .v-card__text,
   .theme--dark.v-card.v-sheet .v-card__subtitle {
    color: rgba(255, 255, 255, 1);
  }

   .largeText {
       font-size: 24px;
   }

  @media all and (max-width: 420px) {
      .fullWidthOnPhone {
          width: 100% !important;
      }
  }

  #app .marginForm .v-text-field, #app .marginForm .v-select {
      margin-bottom: 2px;
  }

  .v-input {
      position: relative;
  }

  .v-text-field__slot {
      padding-right: 100px;
  }

  .v-text-field__details {
      position: absolute;
      right: 0px;
      top: 22px;

  }
  .withIcon .v-text-field__details {
      right: 34px;
  }

  .v-input--dense .v-text-field__details {
      top: 14px;
  }



  @media all and (min-width: 600px) {

      #app .marginForm .v-text-field, #app .marginForm .v-select {
          margin-bottom: 7px;
      }

      .v-text-field__slot {
          padding-right: 150px;
      }

  }

  @media all and (max-width: 600px) {


      .v-text-field input, #app .v-select__selections, .v-text-field__slot .v-label {
          font-size: 14px;
      }

  }





</style>

