import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n'
import Snotify from 'vue-snotify';
import "vue-snotify/styles/simple.css";
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import VuetifyConfirm from 'vuetify-confirm'
import ApiService from './services/api.service'
import TokenService from './services/token.service'
import apiConfig from './static/apiConfig'

import moment from 'moment'
import 'moment/locale/ru'  // without this line it didn't work
moment.locale('ru')

ApiService.init(apiConfig.url)

// If token exists set header
if (TokenService.getToken()) {
  ApiService.setHeader()
}

const options = {
  toast: {
    position: SnotifyPosition.centerTop,
  },
  global: {
    maxOnScreen: 1,
    maxAtPosition: 1
  }

}
Vue.use(Snotify, options)
Vue.use(VuetifyConfirm, { vuetify })

Vue.use(VueI18n)
import i18nDictonary from './static/i18n'

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages: i18nDictonary.messages // set locale messages
})

import Cleave from 'cleave.js';

Vue.directive('cleave', {
  inserted: (el, binding) => {
    el = el.querySelector('input')
    el.cleave = new Cleave(el, binding.value || {})
  },
  update: (el) => {
    el = el.querySelector('input')
    const event = new Event('input', {bubbles: true});
    setTimeout(function () {
      el.value = el.cleave.properties.result
      el.dispatchEvent(event)
    }, 100);
  },
  /*input: (el) => {
    const event = new Event('input', {bubbles: true});
    setTimeout(function () {
      el.value = el.cleave.properties.result
      el.dispatchEvent(event)
    }, 100);
  }*/
})

//console.log('vuetify', vuetify)

vuetify.lang = {
  t: (key, ...params) => i18n.t(key, params),
}

Vue.config.productionTip = false
Vue.config.devtools = false

Vue.prototype.$sale = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
