import { auth } from '@/config/api'
import axios from 'axios'
import ApiService from '@/services/api.service'
import TokenService from '@/services/token.service'

const TOKEN_HEADER_KEY = 'x-bearer-token'
const REFRESH_TOKEN_HEADER_KEY = 'x-refresh-token'
const USER_KEY = 'gallery_user_id'
const USER_NAME = 'gallery_user_name'
const USER_ROLE = 'gallery_user_role'

const state = () => ({
  userData: localStorage.getItem(USER_KEY) ? {id: localStorage.getItem(USER_KEY), name: localStorage.getItem(USER_NAME), role: localStorage.getItem(USER_ROLE)} : {},
  bearerToken: TokenService.getToken(),
  refreshToken: TokenService.getRefreshToken(),
  userProfile: null,
  errors: [],
  unread: 0,
  errorMessage: null,
  honorifics: [
    {
      value: 'Dr.',
      text: 'Dr.'
    },
    {
      value: 'Mr.',
      text: 'Mr.'
    },
    {
      value: 'Mrs.',
      text: 'Mrs.'
    },
    {
      value: 'Miss',
      text: 'Miss'
    },
    {
      value: 'Ms.',
      text: 'Ms.'
    },
    {
      value: '',
      text: 'Other'
    }
  ],
  doctorHonorifics: [
    {
      value: 'Prof.',
      text: 'Prof.'
    },
    {
      value: 'Dr.',
      text: 'Dr.'
    }
  ],
  patientHonorifics: [
    {
      value: 'Mr.',
      text: 'Mr.'
    },
    {
      value: 'Mrs.',
      text: 'Mrs.'
    },
    {
      value: 'Miss',
      text: 'Miss'
    },
    {
      value: 'Ms.',
      text: 'Ms.'
    },
    {
      value: '',
      text: 'Other'
    }
  ]
})

const getters = {
  getHonorifics: state => state.honorifics,
  getDoctorHonorifics: state => state.doctorHonorifics,
  getPatientHonorifics: state => state.patientHonorifics,
  getUnreadCount: state => state.unread,
  getUserData: state => state.userData,
  getUserProfile: state => state.userProfile,
  isAdmin: function(state) {
    return state.userProfile && (state.userProfile.role == 'admin' || state.userProfile.role == 'spec')
  },
  isMultiDoctor: function(state) {
    return state.userProfile && (state.userProfile.role == 'admin' || state.userProfile.role == 'spec' || state.userProfile.role == 'clinic-manager' || state.userProfile.role == 'assistant' || (state.userProfile.role == 'doctor' && state.userProfile.extended == 1))
  },
  isDoctor: function(state) {
    return state.userProfile && (state.userProfile.role == 'doctor')
  },
  isPatient: function(state) {
    return state.userProfile && (state.userProfile.role == 'patient')
  },
  isAssistant: function(state) {
    return state.userProfile && (state.userProfile.role == 'assistant')
  },
  isClinicManager: function(state) {
    return state.userProfile && (state.userProfile.role == 'clinic-manager')
  },
  getBearerToken: state => state.bearerToken,
  getRefreshToken: state => state.refreshToken,
  getIsUserLoggedIn: state => Boolean(state.userData.id && state.bearerToken && state.refreshToken),
  getErrorMessage: state => state.errorMessage,
  getErrors: state => state.errors
}

const actions = {
  async postLoginFormData ({ commit, dispatch }, payload) {
    try {
      dispatch('setErrorMessage', null)
      const response = await ApiService.post(auth.login, payload)

      if (typeof response.data.error != 'undefined') {
        dispatch('setErrorMessage', response.data.error)
      } else if (!response.data.data.id) {
        dispatch('setErrorMessage', 'Wrong response')
      } else {

        // TokenService.saveToken(response.data.data.token)
        // TokenService.saveRefreshToken(response.data.data.token)

        TokenService.saveToken(response.headers[TOKEN_HEADER_KEY])
        TokenService.saveRefreshToken(response.headers[REFRESH_TOKEN_HEADER_KEY])
        ApiService.setHeader()

        /*if (payload.nickname.toLowerCase() == 'a.samii-office@ini-hannover.de') {
          alert('Header-Key:' + response.headers[TOKEN_HEADER_KEY] + "\n\n" + 'Body-Key:' + response.data.data.token + "\n\n"  + 'AllHeaders:' + JSON.stringify(response.headers) + "\n\n"  + 'From Storage:' + TokenService.getToken() + "\n" );
        }
*/

        // ApiService.mount401Interceptor();

        /*dispatch('setUserData', {
          id: response.data.data.id,
          bearerToken: response.headers[TOKEN_HEADER_KEY],
          refreshToken: response.headers[REFRESH_TOKEN_HEADER_KEY],
          name: response.data.data.name
        })*/

        dispatch('setUserData', {
          id: response.data.data.id,
          // bearerToken: response.data.data.token,
          // refreshToken: response.data.data.token,
          bearerToken: response.headers[TOKEN_HEADER_KEY],
          refreshToken: response.headers[REFRESH_TOKEN_HEADER_KEY],
          name: response.data.data.name,
          role: response.data.data.role,
        })
        delete response.data.data.token
        commit('SET_USER_DATA', response.data.data)
      }
    } catch (err) {
      console.log('auth err', err)
      throw err
    }
  },

  async saveProfile ({commit}, data) {
    try {
      const response = await ApiService.post(auth.saveProfile, data)
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
        return false
      } else {
        return response
      }
    } catch (err) {
      console.log('err', err)
      throw err
    }
  },

  async postLogOut ({ commit, dispatch, getters }) {
    try {


      await ApiService.post(auth.logout, {
        token: getters.getRefreshToken
      })
      TokenService.removeToken()
      TokenService.removeRefreshToken()
      ApiService.removeHeader()
      // ApiService.unmount401Interceptor()
      dispatch('clearUserData')
    } catch (err) {
      throw err
    }
  },

  async postReset ({ commit, dispatch, getters }) {
    try {
      await ApiService.post(auth.reset)
    } catch (err) {
      throw err
    }
  },

  async fetchUserProfile ({ commit }) {
    try {
      const { data: { data } } = await ApiService.get(auth.profile)
      commit('SET_PROFILE', data)
      return data
    } catch (err) {
      throw err
    }
  },
  async fetchUnreadCount ({ commit, getters }) {
    try {
      try {
        const { data: { count } } = await ApiService.get(auth.unread)
        commit('SET_UNREAD_COUNT', count)
      } catch (e) {
        commit('SET_UNREAD_COUNT', 0)
      }
    } catch (err) {
      throw err
    }
  },
  async postRegistrationFormData ({ commit, dispatch }, payload) {
    try {
      dispatch('setErrorMessage', null)
      const response = await ApiService.post(auth.registration, payload)
      console.log('response', response)
      if (typeof response.data.error != 'undefined') {
        dispatch('setErrorMessage', response.data.error)
      } else /*if (!response.data.data.is_verified) {
        dispatch('setErrorMessage', 'Not verified')
      } else*/ {

        TokenService.saveToken(response.headers[TOKEN_HEADER_KEY])
        TokenService.saveRefreshToken(response.headers[REFRESH_TOKEN_HEADER_KEY])
        ApiService.setHeader()


        dispatch('setUserData', {
          id: response.data.data.id,
          bearerToken: response.headers[TOKEN_HEADER_KEY],
          refreshToken: response.headers[REFRESH_TOKEN_HEADER_KEY],
          name: response.data.data.name,
          role: response.data.data.role,
        })
        commit('SET_USER_DATA', response.data.data)
      }
      return response
    } catch (err) {
      console.log('err reg', err)
      throw err
    }
  },

  async postRegistrationCheck ({ commit, dispatch }, payload) {
    try {
      const data = await ApiService.post(auth.registrationCheck, payload)
      if (data.is_verified) {
        dispatch('setTokens', {
          bearerToken: data.headers[TOKEN_HEADER_KEY],
          refreshToken: data.headers[REFRESH_TOKEN_HEADER_KEY]
        })
        commit('SET_USER_DATA', data.data)
      }
    } catch (err) {
      throw err
    }
  },

  async postResetPasswordFormData ({commit}, payload) {
    try {
      const response = await ApiService.post(auth.resetPassword, payload)
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
      }
    } catch (err) {
      throw err
    }
  },

  async postResetPasswordCheck ({commit}, payload) {
    try {
      const response =  await ApiService.post(auth.resetPasswordCheck, payload)
      if (typeof response.data.error != 'undefined') {
        commit('ADD_ERROR', response.data.error)
      }
    } catch (err) {
      throw err
    }
  },

  async postRegenerateTokens ({ getters, dispatch }) {
    try {
      const response = await ApiService.post(auth.regenerateTokens, { token: getters.getRefreshToken })
      if (response) dispatch('setUserData', {
        id: this.$cookies.get('dch-user-id'),
        bearerToken: response.headers[TOKEN_HEADER_KEY],
        refreshToken: response.headers[REFRESH_TOKEN_HEADER_KEY]
      })
    } catch (err) {
      dispatch('postLogOut')
      throw err
    }
  },

  async postPasswordChange ({}, payload) {
    try {
      await ApiService.post(auth.passwordChange, payload)
    } catch (err) {
      throw err
    }
  },

  async setUnreadCount ({commit}, count) {
    commit('SET_UNREAD_COUNT', count)
  },

  async clearErrors ({commit}) {
    commit('CLEAR_ERRORS')
  },

  setUserData ({ commit, dispatch }, { bearerToken, refreshToken, id, name, role }) {
    commit('SET_USER_ID', id)
    commit('SET_USER_NAME', name)
    commit('SET_USER_ROLE', role)
    commit('SET_BEARER_TOKEN', bearerToken)
    commit('SET_REFRESH_TOKEN', refreshToken)
    // this.$cookies.set('dch-user-id', id)
    // this.$cookies.set('dch-bearer-token', bearerToken)
    // this.$cookies.set('dch-refresh-token', refreshToken)
  },

  clearUserData ({ commit }) {
    // this.$cookies.remove('dch-user-id')
    // this.$cookies.remove('dch-refresh-token')
    // this.$cookies.remove('dch-bearer-token')

    commit('SET_USER_DATA', {})
    commit('SET_BEARER_TOKEN', null)
    commit('SET_REFRESH_TOKEN', null)
  },

  setUserDataFromCookies ({ commit }) {
    // commit('SET_USER_ID', this.$cookies.get('dch-user-id'))
    // commit('SET_BEARER_TOKEN', this.$cookies.get('dch-bearer-token'))
    // commit('SET_REFRESH_TOKEN', this.$cookies.get('dch-refresh-token'))
  },

  setErrorMessage ({ commit }, payload) {
    commit('SET_ERROR_MESSAGE', payload)
  }
}

const mutations = {
  SET_USER_DATA (state, payload) {
    state.userData = payload
  },
  CLEAR_ERRORS (state) {
    state.errors = []
  },
  ADD_ERROR (state, payload) {
    state.errors.push(payload)
  },
  SET_UNREAD_COUNT (state, payload) {
    state.unread = payload
  },
  SET_PROFILE (state, payload) {
    state.userProfile = payload
  },
  SET_USER_ID (state, payload) {
    state.userData.id = payload
    localStorage.setItem(USER_KEY, payload)
  },
  SET_USER_NAME (state, payload) {
    state.userData.name = payload
    localStorage.setItem(USER_NAME, payload)
  },
  SET_USER_ROLE (state, payload) {
    state.userData.role = payload
    localStorage.setItem(USER_ROLE, payload)
  },
  SET_BEARER_TOKEN (state, payload) {
    state.bearerToken = payload
  },
  SET_REFRESH_TOKEN (state, payload) {
    state.refreshToken = payload
  },
  SET_ERROR_MESSAGE (state, payload) {
    state.errorMessage = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
