const TOKEN_KEY = 'gallery_access_token'
const REFRESH_TOKEN_KEY = 'gallery_refresh_token'

const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY) ? localStorage.getItem(TOKEN_KEY) : null
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY)
    },

    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    }

}

export default TokenService
