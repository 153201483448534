import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home'

import Profile from '../views/profile/'
import Exhibitions from '../views/exhibitions/exhibitions.vue'
import Teams from '@/views/teams/teams.vue'
import Team from '@/views/teams/id.vue'
import Contests from '../views/contests/contests.vue'
import Cart from '../views/cart/cart.vue'
import Actualism from '../views/actualism/actualism.vue'
import Articles from '../views/articles/articles.vue'
import Exhibition from '../views/exhibitions/id.vue'
import Shop from '../views/shop/shop.vue'
import About from '../views/about/about.vue'
import Contest from '../views/contests/id.vue'
import Article from '../views/articles/id.vue'
import Tasks from '../views/tasks/tasks.vue'
import Dashboard from '@/views/dashboard/dashboard.vue'
import Registration from '@/components/registration'
import NotFoundComponent from '@/views/notfound'
import VR from '@/views/vr'
import Login from '@/views/login'
// import Stats2 from '../views/invites/v2'
import store from '@/store'
import axios from 'axios'

import apiConfig from '../static/apiConfig'


const ifNotAuthenticated = async (to, from, next) => {



  if (await isWrongIP()) {
    next('/ip')
    return
  }
  if (!store.getters['auth/getIsUserLoggedIn']) {
    next()
    return
  }
  next('/')
}

const  isWrongIP = async () => {
  const r = await axios.get(`${apiConfig.url}/ips`)
  if (!r.data || !r.data.valid) {
    return true
  } else {
    return false;
  }
}

const  ifWrongIP = async (to, from, next) => {
  if (!await isWrongIP()) {
    next('/')
    return
  }
  next()
  return
}

/*const  ifWrongApi = async (to, from, next) => {
  console.log('here')
  console.log(validIPs, getIpAddress(false))
  console.log(validIPs.includes(getIpAddress(false)))
  if (validIPs.includes(await getIpAddress(false)) || validIPs.includes(await getIpAddress(true))) {
    console.log('here 2')
    next('/')
    return
  }
  next()
  return
}*/

/*async function getIpAddress (force) {
  if (force || !sessionStorage.getItem("ipAddress")) {
    const r =  await fetch('https://api64.ipify.org?format=json')
    const ip = (await r.json()).ip
    console.log('ip', ip)
    sessionStorage.setItem("ipAddress", ip.toString())
  }
  return sessionStorage.getItem("ipAddress")
}*/

const ifAuthenticated = async (to, from, next) => {
  //if (!validIPs.includes(await getIpAddress(false)) && !validIPs.includes(await getIpAddress(true))) {
  /*if (await isWrongIP()) {
    next('/ip')
    return
  }*/
  if (store.getters['auth/getIsUserLoggedIn'] || (to.name == 'Exhibition' && to.params.id == 43)) {
    next()
    return
  }
  //next({name: 'Exhibitions'})
  next({name: 'Login', query: { redirect: to.path }})
}

Vue.use(VueRouter)

const routes = [
  /*{
    path: '/invite/:code',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/chats/:code',
    name: 'Chats',
    component: Rooms
  },

  {
    path: '/cabinet/:code',
    name: 'Cabinet',
    component: Cabinet
  },

  {
    path: '/question',
    name: 'Question',
    component: Question
  },*/
  {
    path: '/ip',
    name: 'Home',
    component: Home,
    beforeEnter: ifWrongIP
  },
  /*{
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated,
  },*/
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: ifAuthenticated,
  },{
    path: '/shop/cart',
    name: 'Cart',
    component: Cart,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/team',
    name: 'Teams',
    component: Teams,
    beforeEnter: ifAuthenticated,
  },{
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/team/:id',
    name: 'Team',
    component: Team,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/actualism',
    name: 'Actualism',
    component: Actualism,
    beforeEnter: ifAuthenticated,
  },

  {
    path: '/exhibitions',
    name: 'Exhibitions',
    component: Exhibitions,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/contests/:id',
    name: 'Contest',
    props: true,
    component: Contest,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: Article,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/contests',
    name: 'Contests',
    component: Contests,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/',
    redirect: '/contests',
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/exhibitions/:id',
    name: 'Exhibition',
    component: Exhibition,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/days',
    name: 'Days',
    component: Tasks,
    beforeEnter: ifAuthenticated,
  },
  /*{
    path: '/moderation',
    name: 'Moderation',
    component: Moderation,
    beforeEnter: ifAuthenticated,
  },*/

  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/org',
    name: 'VR',
    component: VR,
  },
  { path: '*', component: NotFoundComponent }

  /*{
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: ifAuthenticated,
  },*/
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  //base: '/gallery/',
  //base: '/dnet/dist/',
  routes
})

export default router
