<template>
    <v-list two-line class="exhibitionsList mt-7">
        <v-list-item-group
                v-if="group"
                v-model="currentExhibitionId"
                color="indigo"
        >
            <slot />
        </v-list-item-group>
        <template v-else>
            <slot />
        </template>
    </v-list>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'q-list',
        props: {
            group: {
                type: Boolean,
                default: true,
            },
            id: {
                default: false,
            },
        },

        data () {
            return {
                currentExhibitionId: false
            }
        },

        created() {

        },

        computed: {
            ...mapGetters({
                getExhibitionsList: 'exhibitions/getExhibitionsList',
            }),
        },

        watch: {
            currentExhibitionId(newVal) {
                const exhibition = this.getExhibitionsList.find(item => item.id == newVal)

                if (exhibition) {
                    this.$emit('exhibition-selected', exhibition)
                }
            },
            id () {
                this.currentExhibitionId = this.id
            }
        }
    }
</script>

<style scoped>

</style>
