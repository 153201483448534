<template>
    <header class="c-header" style="z-index: 1000;">
        <div class="c-header_container">
            <div class="logo" style="z-index: 10;"><v-btn to="/" fab  dark><v-icon>mdi-chat</v-icon></v-btn></div>
            <div class="c-header_info subHeader">1 800 000 0000</div>
            <div class="menu" >
                <span @click="$emit('open-menu')" class="mr-4 hidden-sm-and-down">MENU</span>
                <v-btn v-if="open" @click="open = false; $emit('close-menu')" fab  dark><v-icon>mdi-close</v-icon></v-btn>
                <v-btn v-else @click="open = true; $emit('open-menu')" fab  dark><v-icon>mdi-menu</v-icon></v-btn>
            </div>
        </div>
    </header>
</template>
<script>

    export default {
        props: {

        },

        data: () => ({
            open: false,
        })
    }
    </script>
<style>


    .c-header_info {

        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:0 6.25rem
    }

    .c-header_info, .logo, .menu {
        transform:translateY(-100%) translateY(-2.8125rem);
        transition:transform .6s cubic-bezier(.35,.755,.42,.95);
        transition-delay:.2s;
    }

    .isLoaded .c-header_info, .isLoaded .logo, .isLoaded .menu {
        transform:translateY(0);
        transition-delay:0s
    }
    html.is-ready.has-nav-open .c-header_info {
        transform:translateY(-100%) translateY(-2.8125rem);
        transition-delay:0s
    }
    .c-header_info_button {
        box-shadow:none!important;
        transition:opacity .3s cubic-bezier(.35,.755,.42,.95),transform .3s cubic-bezier(.35,.755,.42,.95)
    }
    html.has-scrolled .c-header_info_button,
    html[data-template=survey] .c-header_info_button {
        transform:translateY(-100%);
        opacity:0
    }
    .c-header_info_button .c-button_bg {
        opacity:0;
        transition:opacity .3s cubic-bezier(.35,.755,.42,.95)
    }
    html.has-scrolled .c-header_info_button .c-button_bg {
        opacity:1
    }

    .c-header {
        position:fixed;
        top:0;
        left:0;
        right:0;
        z-index:115;
        transition:color .3s cubic-bezier(.35,.755,.42,.95)
    }
    [data-ui=light]:not(.has-nav-open) .c-header {
        color:#fff
    }
    @media (min-width:700px) {
        .c-header {
            padding-top:1.25rem
        }
    }
    @media (max-width:699px) {
        .c-header {
            padding-top:1.25rem
        }
        .c-header:before {
            content:"";
            position:absolute;
            top:0;
            height:120%;
            left:0;
            right:0;
            pointer-events:none;
            opacity:0;
            transition:opacity .3s cubic-bezier(.35,.755,.42,.95) .3s;
            background:#1c1c1c
        }
        html.has-nav-open .c-header:before {
            opacity:1;
            transition-delay:.1s
        }
    }
    .c-header_container {
        width:100%;
        display:flex;
        justify-content:space-between;
        position:relative
    }
    @media (min-width:700px) {
        .c-header_container {
            padding-left:2.5rem;
            padding-right:2.5rem
        }
    }
    @media (max-width:699px) {
        .c-header_container {
            padding-left:1.25rem;
            padding-right:1.25rem
        }
    }
</style>
