<template>
    <v-col class="d-flex flex-column" :md="6" :lg="wide ? 3 : 6" :xl="wide ? 3 : 6" :cols="12" :key="'doom-' + exhibition.exhibition_id">
        <v-hover  >
            <template v-slot:default="{ hover }">
                <v-card
                        @click="$router.push({name: 'Exhibition', params: {id: exhibition.exhibition_id}})"
                        hover
                        style="width: 100%"
                        class="mx-auto animated animate__animated animate__zoomInDown flex align-content-end d-flex flex-column"
                        rounded

                >
                    <v-img max-height="250" height="250" :src="exhibition.cover"></v-img>

                    <v-card-text>
                        <h2 class="title primary--text">
                            {{ exhibition.name }}
                        </h2>
                        <div v-html="exhibition.description.replace(/(?:\r\n|\r|\n)/g, '<br />')">
                        </div>
                    </v-card-text>

                    <v-fade-transition v-if="false">
                        <v-overlay
                                opacity="0.75"
                                v-if="hover"
                                absolute
                                color="grey lighten-4"

                        >
                            <div @click="$router.push({name: 'Exhibition', params: {id: exhibition.exhibition_id}})">
                                <span  class=" black--text cursive"  style="opacity: 0.9; font-weight: 800; font-style: italic; font-size: 2vw; letter-spacing: 4px;">Просмотр <br> Выставки</span>
                            </div>
                            <!--                                            <v-btn color="primary" style="opacity: 0.75">Просмотр</v-btn>-->

                        </v-overlay>
                    </v-fade-transition>




                </v-card>
            </template>
        </v-hover>
    </v-col>

</template>

<script>
    import dateTimeMixin from '@/mixins/datetime'
    import colorsMixin from '@/mixins/colors'

    export default {
        name: 'q-list-item',
        mixins: [dateTimeMixin, colorsMixin],
        props: {
            exhibition: {
                type: Object,
                required: true,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            wide: {
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {

            }
        },

        created() {

        },
    }
</script>

<style scoped>

</style>
