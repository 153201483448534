<template>
    <layout-default :is-loaded="true">

        <v-layout style="max-width: 100vw;" class="align-center justify-center" fill-height>
          <v-flex class="" style="width: 400px; max-width: 100%; flex: 0 1 auto;">
              <!--<v-card tile class="elevation-12">-->
              <div >
<!--                  <v-img width="300" max-width="100%" class="mx-auto mb-4" src="@/assets/logo.svg"></v-img>-->
                  <v-img :width="$vuetify.breakpoint.mdAndUp ? 150 : 100" style="border-radius: 75px;" max-width="100%" class="mx-auto mb-4" src="@/assets/logo.jpg"></v-img>
                  <!--<h1 class="headline text-center">Sign in to continue</h1>-->
                 <!-- <v-toolbar
                          color="teal"
                          dark
                          flat
                  >
                      <v-toolbar-title class="text-left " style="width:100%;">Sign in to continue</v-toolbar-title>
                      <v-spacer />
                  </v-toolbar>-->
                  <div class="text-center white--text mt-7 mb-4"  v-if="resetPassword">
                     <span style="cursor: pointer;" @click="resetPassword = false"><v-icon small >mdi-arrow-left</v-icon> Вернуться</span>
                  </div>
                  <password-reset @exit="resetPassword=false" v-if="resetPassword"></password-reset>
                  <v-form v-if="!resetPassword" @submit.prevent="logIn" class="px-0 marginForm" ref="loginForm" :lazy-validation="true">
                      <v-card-text class="px-0 mb-0">

                              <v-text-field
                                      outlined
                                      v-cleave="{phone: true}"
                                      label="Телефон"
                                      solo
                                      flat
                                      name="phone"
                                      validate-on-blur
                                      :rules="[rules.required]"
                                      type="text"
                                      v-model="username"
                              />

                              <v-text-field
                                      validate-on-blur
                                      outlined
                                      class="withIcon"
                                      solo
                                      flat
                                      v-model="password"
                                      :rules="[rules.required, rules.min]"
                                      :type="showPass ? 'text' : 'password'"
                                      id="password"
                                      label="Пароль"

                                      name="password"
                                      hint="минимум 6 символов"

                              >
                                  <template v-slot:append>
                                      <v-icon size="16" @click="showPass = !showPass">
                                          {{ showPass ? 'mdi-eye' : 'mdi-eye-off' }}
                                      </v-icon>
                                  </template>
                              </v-text-field>

                          <a href="#" @click.prevent="resetPassword = true" class="mt-1 body-2 float-right mx-1">забыл пароль</a>
                          <v-checkbox  class=" mb-0 mt-0" v-model="rememberMe">
                              <template v-slot:label>
                                  <div class="">
                                     Запомнить меня
                                  </div>
                              </template>
                          </v-checkbox>

                          <div class="error--text body-2 mb-4" v-if="errorMessage">
                              {{ errorMessage }}
                          </div>


                      </v-card-text>

                      <!--<v-alert
                              v-if="errorMessage"
                              dense
                              outlined
                              dismissible
                              type="error"
                              class="subtitle-2"
                      >
                          {{ errorMessage }}
                      </v-alert>-->
                      <div v-if="false" class=" caption grey--text mb-2 mt-0">
                          Нажимая кнопку «<span class="secondary--text">Войти</span>», вы принимаете <router-link class="mx-1 secondary--text" to="/">Пользовательское соглашение</router-link> и <router-link class="mx-1 secondary--text" to="/">Политику конфиденциальности</router-link>.
                      </div>
                      <v-card-actions class="px-0">
                          <v-spacer />
                          <v-btn type="submit" style="width: 100%;"  large  class="white--text"  color="primary">Войти</v-btn>
                      </v-card-actions>

                      <div class="body-2 mt-4 grey--text text-center">
                          Нет аккаунта? <router-link  class="secondary--text mx-1" to="/registration">Регистрация</router-link>
                      </div>

                  </v-form>
              </div>
          </v-flex>
        </v-layout>
    </layout-default>
</template>

<style scoped>
  label {
    cursor: pointer;
  }



</style>

<script>
  // import {AUTH_REQUEST, AUTH_SUCCESS} from 'actions/auth'
  // import {USER_REQUEST} from 'actions/user'
  // import { ValidationProvider, ValidationObserver } from 'vee-validate'
  // import Cookies from 'js-cookie'
  import axios from 'axios'
  import {mask} from 'vue-the-mask'
  import Cleave from 'cleave.js';
  import PasswordReset from '@/components/password-reset/password-reset'
  require("cleave.js/dist/addons/cleave-phone.i18n.js");
  import { mapActions, mapGetters } from 'vuex'
  import  LayoutDefault from '@/layouts/LayoutBase.vue'
export default {
    components: {
      LayoutDefault,
      PasswordReset
    },
    directives: {
        cleave: {

            inserted: (el, binding) => {
                el = el.querySelector('input')
                el.cleave = new Cleave(el, binding.value || {})
            },
            update: (el) => {
                el = el.querySelector('input')
                const event = new Event('input', {bubbles: true});
                setTimeout(function () {
                    el.value = el.cleave.properties.result
                    el.dispatchEvent(event)
                }, 100);
            },

            /*inserted: (el, binding) => {
                el.cleave = new Cleave(el, binding.value || {})
            },
            update: (el) => {
                const event = new Event('input', {bubbles: true});
                setTimeout(function () {
                    el.value = el.cleave.properties.result
                    el.dispatchEvent(event)
                }, 100);
            }*/
        }
    },
    name: 'login',
    data: function () {
      return {
        showPass: false,
        isSubmitButtonLoading: false,
        username: '',
        password: '',
        errorMessage: '',
        rememberMe: true,
        resetPassword: false,
        rules: {
          required: v => !!v || 'обязательное',
          min: v => v.length >= 6 || 'минимум 6 символов',
          email: v => /.+@.+\..+/.test(v) || 'E-mail введен неверно',
          emailMatch: () => ('пароли не совпадают'),
        }
      }
    },
    computed: {
        ...mapGetters({
          getUserData: 'auth/getUserData',
          getErrorMessage: 'auth/getErrorMessage'
        })
    },
    methods: {
      ...mapActions({
        postLoginFormData: 'auth/postLoginFormData'
      }),
        logIn: async function () {

          const isValid = this.$refs.loginForm.validate()
          if (!isValid) {
            return false
          }
          this.isSubmitButtonLoading = true
          try {
            this.errorMessage = ''
            const loginFieldNameInPayload = this.username.match(/[^@]+@[^\.]+\..+/g) ? 'email' : 'phone'
            const payload = {
              [loginFieldNameInPayload]: this.username,
              password: this.password
            }
            await this.postLoginFormData(payload)
            if (this.getUserData.is_verified) {
              this.$router.push(this.$router.history.current.query.redirect || { name: 'Contests'});
            }
            if (this.getErrorMessage != '') {
              this.errorMessage = this.getErrorMessage
            }

          } catch (err) {
            console.log('onFormSubmit err', err);
            this.formErrorMessagesFromApi = err.response.data.error
          }
          this.isSubmitButtonLoading = false
        }
    },
    mounted: function () {
      /*this.$store.dispatch(AUTH_REQUEST, {rememberMe: true}).then(() => {
        this.$store.dispatch(USER_REQUEST).then(() => {
          return this.$router.push('/')
        })
      })*/
    },
  }
</script>
