<template>
    <v-list two-line class="teamsList mt-7">
        <v-list-item-group
                v-if="group"
                v-model="currentTeamId"
                color="indigo"
        >
            <slot />
        </v-list-item-group>
        <template v-else>
            <slot />
        </template>
    </v-list>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'q-list',
        props: {
            group: {
                type: Boolean,
                default: true,
            },
            id: {
                default: false,
            },
        },

        data () {
            return {
                currentTeamId: false
            }
        },

        created() {

        },

        computed: {
            ...mapGetters({
                getTeamsList: 'teams/getTeamsList',
            }),
        },

        watch: {
            currentTeamId(newVal) {
                const team = this.getTeamsList.find(item => item.id == newVal)

                if (team) {
                    this.$emit('team-selected', team)
                }
            },
            id () {
                this.currentTeamId = this.id
            }
        }
    }
</script>

<style scoped>

</style>
