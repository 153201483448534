// @vue/component
import LayoutDefault from '@/layouts/LayoutClient.vue'
import { mapActions, mapGetters } from 'vuex'
import QList from '@/components/common/teams/c-list.vue'
import TasksList from '@/components/common/tasks/c-list.vue'
import QListItem from '@/components/common/teams/c-list-item.vue'
import dateTimeMixin from '@/mixins/datetime'
import colorsMixin from '@/mixins/colors'
import cssMixin from '@/mixins/css'
import DoctorProfile from '@/components/doctor/profile.vue'
import { Timer } from 'easytimer.js';
import vueFilePond from "vue-filepond";
import apiConfig from '@/static/apiConfig'
import 'viewerjs/dist/viewer.css'
import Viewer from "v-viewer/src/component.vue"

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import TokenService from '@/services/token.service'
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageFilter from 'filepond-plugin-image-filter';


import CropperEditor from 'cropperjs';

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit,
    FilePondPluginImageExifOrientation,
    FilePondPluginImageFilter
);


export default {
    name: 'Team',

    components: {
        LayoutDefault,
        FilePond,
        DoctorProfile,
        TasksList,
        QList,
        QListItem,
        Viewer
    },

    mixins: [dateTimeMixin, colorsMixin, cssMixin],

    props: {},

    data () {
        return {
            rooms: [],
            myFiles: [],
            files: [],
            filter: 'private',
            filterOptions: [
                {name: 'Персональные', value: 'personal'},
                {name: 'Общие', value: 'common'},
                {name: 'Официальные', value: 'official'},
            ],
            team: {},
            currentDay: 1,
            sessionClock: '',
            filterFlow: 'noPinned',
            filterSection: 'teams',
            filterTabs: 0,
            errorMessage: '',
            saving: false,
            dialogTeam: false,
            validTeamForm: false,
            viewer: null,
            tmpCode: null,
            waiting: false,
            loading: true,
            isCurrentTeamAccepted: false,
            editingProfileId: false,
            sessionTimer: false,
            roomsLoaded: false,
            sessionActive: true,
            currentTeamId: null,
            currentTeam: null,
            timerStopwatch: null,
            editor: {

                // Called by FilePond to edit the image
                // - should open your image editor
                // - receives file object and image edit instructions
                open: (file, instructions) => {
                    // open editor here
                    console.log(file);
                },

                // Callback set by FilePond
                // - should be called by the editor when user confirms editing
                // - should receive output object, resulting edit information
                onconfirm: (output) => {},

                // Callback set by FilePond
                // - should be called by the editor when user cancels editing
                oncancel: () => {},

                // Callback set by FilePond
                // - should be called by the editor when user closes the editor
                onclose: () => {}
            },
            rules: {
                required: v => !!v || 'обязательное',
                min: v => v.length >= 6 || 'минимум 6 символов',
                email: v => /.+@.+\..+/.test(v) || 'E-mail введен неверно',
                passwordMatch: () => this.user.password === this.passwordConfirm || ('пароли не совпадают'),
            }
        }
    },

    computed: {
        ...mapGetters({
            getChatsList: 'chats/getChatsList',
            getTeam: 'teams/getTeam',
            getIsUserLoggedIn: 'auth/getIsUserLoggedIn',
            getUserTasks: 'teams/getUserTasks',
            getErrors: 'teams/getErrors',
            getStats: 'dnet/getStats',
            getUserData: 'auth/getUserData',
            getUserProfile: 'auth/getUserProfile',
        }),

        serverOptions: function() {
            return {
                process: {
                    url: apiConfig.url + '/file/save/team/0/' + this.tmpCode,
                    headers: {
                        Authorization: `Bearer ${TokenService.getToken()}`
                    }
                },
                //fetch: null,
                //revert: null
            }
        },

        imgs: function() {
          if (this.getTeam && this.getTeam.files && this.getTeam.files.length) {
              return this.getTeam.files
          } else {
              return []
          }
        },




        currentUserId: function() {
            return this.patientId ? this.patientId : (this.getUserData ? this.getUserData.id + '' : '')
        },
        initials() {
            if (this.getUserData) {
                let names = this.getUserData.name.split(' ')
                return names[0].substring(0, 1) + (names.length > 1 ? names[1].substring(0, 1) : '')
            } else {
                return '—'
            }
        },

    },

    /*destroyed() {
        socket.off("connect_error");
        socket.off("private message");
    },*/

    watch: {
        async filter() {
            this.waiting = true
            await this.fetchTeam({type: this.filter})
            await setTimeout(() => {
                this.waiting = false
            }, 200)
        }
    },

    beforeDestroy() {
        this.clearTimeout()
    },


    async created () {
        //this.timerStopwatch = new Timer();
        console.log('this.$route.params.id', this.$route.params.id)
        try {
            await this.fetchTeam(this.$route.params.id)
        } catch(e) {
            console.error(e)
            this.loading = false
            this.waiting = false
        }
        this.loading = false
        //await this.startSession(true)

        /*this.timerStopwatch.addEventListener('secondsUpdated',  (e) => {
            this.sessionClock = this.timerStopwatch.getTimeValues().toString()
            // $('#basicUsage').html(timer.getTimeValues().toString());
        });*/

        /*socket.auth = { username: this.getUserData.id };
        socket.connect();

        socket.on("private message", ({ content, from }) => {
            console.log('content on PM', content)
            this.$refs.chat.addMessageToChat(content.chat_id, content)
        });*/

        /*await this.fetchStats({
            own: true,
            period: 'all',
            begin: null,
            end: null,
        })*/
    },

    methods: {
        ...mapActions({
            fetchChatsList: 'chats/fetchChatsList',
            fetchTeam: 'teams/fetchTeam',
            deleteChat: 'chats/deleteChat',
            clearErrors: 'teams/clearErrors',
            saveTeam: 'teams/saveTeam',

            acceptTeam: 'teams/acceptTeam',
            closeChat: 'chats/closeChat',
            refuseChat: 'chats/refuseChat',
        }),

        inited: function (viewer) {
            this.viewer = viewer
        },

        saveTeamConfirm: async function() {
            const res = await this.$confirm('Сохранить выставку?', { title: 'Внимание', icon: '', color: '#f8495a', buttonTrueText: 'Да', buttonFalseText: 'Нет' })
            if (res) {
                this.saving = true
                try {
                    this.team.tmp_code = this.tmpCode
                    this.team.action = 'create_team'
                    await this.saveTeam(this.team)
                    if (this.getErrors.length > 0) {
                        this.$root.$emit('error:show', this.getErrors[0])
                    } else {
                        await this.fetchTeam(this.$route.params.id)
                        this.dialogTeam = false
                    }
                    this.saving = false
                } catch(e) {
                    console.error(e)
                    this.saving = false
                }


            }
        },

        addToFav: function(img) {
            img.fav = true
        },

        removeFromFav: function(img) {
            img.fav = false
        },

        createTeam: function() {
            this.tmpCode = 'xxxxxxxxxx'.replace(/[x]/g, function(c) {
                return (Math.random() * 16 | 0).toString(16)
            })
            this.dialogTeam = true
            this.team = {}
        },

        handleFilePondInit: function () {
            console.log("FilePond has initialized");

            // FilePond instance methods are available on `this.$refs.pond`
        },
        makeTeam: function () {
            console.log("makeTeam");


        },

        async messageBroadcast(content) {
            console.log('messageBroadcast', content)
            socket.emit("private message", {
                content,
                to: content.recipientId,
            });
        },

        async nextDayAndUpdate() {
            await this.nextDay()
            this.$router.go()
        },

        async onAnswered() {
            let room = this.rooms.find(r => r.id == this.currentChatId)
            if (room && room.status == 'preparing' || room.status == 'closed') {
                await this.loadRooms()
            }
        },

        async onTeamSelected(data) {
            console.log('datadata', data)
            this.currentTeamId = data.id;
            this.currentTeam = data;
            this.isCurrentTeamAccepted = data.accepted
        },

        async startSession(check) {
            let session = await this.checkSession(check)
            if (session.found) {
                this.sessionActive = session
                this.clearTimeout()
                this.timerStopwatch.start({
                    startValues: {
                        seconds: session.duration
                    }
                })
                this.sessionTimeout = setTimeout(() => {
                    this.startSession(true)
                }, session.left * 1000 + 1000)
            } else {
                this.clearTimeout()
                this.sessionActive = false
                this.sessionClock = ''
                this.timerStopwatch.reset()
                this.timerStopwatch.stop()
            }
        },

        async finishSessionConfirm() {
            await this.finishSession()
            this.clearTimeout()
            this.sessionActive = false
            this.sessionClock = ''
            this.timerStopwatch.reset()
            this.timerStopwatch.stop()
            await this.fetchStats({
                own: true,
                period: 'all',
                begin: null,
                end: null,
            })
        },

        clearTimeout() {
            if (this.sessionTimer) {
                clearTimeout(this.sessionTimer)
                this.sessionTimer = null
            }
        },

        async loadRooms() {
            await this.fetchChatsList({code: this.$route.params.code})
            const rooms = []
            for (let i = 0; i < this.getChatsList.length; i++) {
                rooms.push(this.getChatsList[i])
            }
            this.rooms = rooms
            this.roomsLoaded = true
        },

        deleteChatConfirm: async function(chatId) {
            const res = await this.$confirm('Delete this team?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
            if (res) {
                await this.deleteChat(chatId)
                await this.loadRooms()

            }
        },

        closeChatConfirm: async function(chatId) {
            const res = await this.$confirm('Close this team?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
            if (res) {
                await this.closeChat({chat_id: chatId})
                await this.loadRooms()

            }
        },

        acceptTeamConfirm: async function(teamId) {
            const res = await this.$confirm('Accept this team?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
            if (res) {
                await this.clearErrors()
                await this.acceptTeam({team_id: teamId})
                if (this.getErrors.length > 0) {
                    this.$root.$emit('error:show', this.getErrors[0])
                } else {
                    await this.fetchTeam(this.$route.params.id)
                }

            }
        },

        refuseChatConfirm: async function(chatId) {
            const res = await this.$confirm('Refuse this team?', { title: 'Warning', icon: '', color: '#f8495a', buttonTrueText: 'Yes', buttonFalseText: 'No' })
            if (res) {
                await this.refuseChat(chatId)

                if (this.getErrors.length > 0) {
                    this.$root.$emit('error:show', this.getErrors[0])
                } else {
                    await this.loadRooms()
                }





            }
        },

    }
}
