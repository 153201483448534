<template>
  <layout-default :is-loaded="true" :hide-sides="false">
    <v-layout>
      <v-flex class="px-0 text-left">
        <v-row class="mt-0">
          <v-col cols="12" md="8" lg="6">
            <v-card color="transparent" outlined>
              <v-card-title class="text-h4 mb-2" @click="primary = !primary">О Галерее</v-card-title>
              <v-card-text>
                <p>Галерея Старых - авторский меценатский проект Александра Старых. <br>
                  Основная цель - мотивирование молодых профессиональных художников на создание реалистичных полотен, отражающих текущую российскую действительность.</p>

                <p>Деятельность проекта построена в форме проведения конкурсов - создание творческих полотен по заранее определённым тематикам. Как и форма программ, так и их география очень обширны.</p>

                <p>Проект существует с 2018 года. За это время реализованы:</p>
                <ul>
                  <li>конкурс среди петербургских художников с отчётной выставкой «НОЛЬ'18»;</li>
                  <li>всероссийский конкурс с итоговой выставкой «51 Россия»;</li>
                  <li>учреждены широкая стипендиальная программа для студентов художественных вузов Санкт-Петербурга и Москвы и программа грантов - премия «Лучший выпускник художественного вуза»;</li>
                  <li>пленэрная программа;</li>
                  <li>открыты мастерские для художников-выпускников Института им. И.Е. Репина (до 2023 года);</li>
                  <li>осуществлены конкурсы «О чём твоя Россия?», «Необычный 2020» и «70 оттенков эпохи Путина».</li>
                </ul>
<br>
                <p>Спецпроект галереи - экспериментальное художественное течение и регулярный конкурс <a style="color: black;" href="https://vk.com/wall-156956459_13" target="_blank">#актуализм</a>. В рамках которого и собирается коллекция проекта:</p>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="mt-4">
          <v-img contain max-height="1500px" max-width="auto" src="/img/sketch.png"></v-img>
        </div>
      </v-flex>
    </v-layout>
  </layout-default>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutClient.vue'
export default {
  name: 'AboutGallery',
  components: {
    LayoutDefault
  },
  data () {
    return {
      primary: false
    }
  }
  // Add any necessary component logic here
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>